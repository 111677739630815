import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { TableCell, TableRow, TextareaAutosize } from '@mui/material';

export const useStyles = makeStyles(({ theme }) => ({
  cardContainer: {
    borderRadius: '0px !important',
    backgroundColor: '#151515 !important',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important',
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#05EE3B',
    },
    secondary: {
      main: '#060606',
    },
    gray: {
      main: '#151515',
    }
  },
  components: {
    // button -----------
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          textTransform: 'capitalize',
          fontSize: '14px',
          fontWeight: '400',
          boxShadow: 'unset',
          lineHeight: '1.8',
          padding: '6px 16px'
        },
        sizeSmall: {
          padding: '4px 10px'
        }
      },
    },
    // button -----------
    // input ------------
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '0px',
            border: '1px solid #454545',
            input: {
              fontSize: '14px',
              fontWeight: '400',
              padding: '10px 15px',
              color: '#fff',
            },
          },
          '& .MuiInputBase-root:hover': {
            border: '1px solid #454545 !important',
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#fff',
            fontSize: '12px',
            fontWeight: '300'
          },
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: '1px solid #454545 !important',
          borderRadius: '0px !important'
        },
      }
    },
    // input ------------
    // select ------------
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '10px 15px',
          color: '#fff',
          borderRadius: '0px',
          // border: '1px solid #454545 !important',
          '& .MuiInputBase-root:hover': {
            // border: '1px solid #454545 !important',
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#fff',
          },
        }
      }
    },
    // select ------------

    // modal ------------
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#151515',
          margin: '16px',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: {
          borderTop: '1px solid transparent',
          borderBottom: '1px solid transparent',
          padding: '16px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 14px'
        }
      }
    },
    // modal ------------
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#454545 !important',
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: '#151515',
          color: '#fff',
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0',
          marginTop: '5px',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: {
          borderColor: '#333',
          margin: '5px 0 !important'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#333',
          margin: '5px 0 !important'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#292929',
          background: '#151515',
          color: '#fff',
          fontWeight: '300',
          whiteSpace: 'nowrap',
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'red !important',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: '#616161'
        },
        label: {
          color: '#fff'
        }
      }
    }
  },
});

export const MyComponent = styled('div')({
  borderRadius: '0px',
  background: '#151515',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
});

// table -----------
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#0073bd00",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#0073bd00",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: '#151515 !important',
  color: "#fff !important",
  fontWeight: '500 !important',
  borderBottom: '1px solid #292929 !important'
}));

// table -----------

export const getAttendnceStatus = (value) => {
  const status = {
    false: {
      title: "Absent",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "Present ",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};