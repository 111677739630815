import React, { useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button, Modal } from '../../Component/Commons'
import workerimage from '../../assets/image/worker.jpg'
import { useTranslation } from "react-i18next";

const ViewRoi = ({ handleClose, modalOpen }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: ""
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("Add_Region_of_interest_pooling_(ROI)")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
      <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img src={workerimage} style={{ width: "350px", objectFit: 'contain', height: 'auto' }} />
          <Typography>{t("Camera")}-1</Typography>
        </Stack>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("CameraID")}</Grid>
                <Grid item xs={7}>{t("CameraID")} 1</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("FrameNumber")}</Grid>
                <Grid item xs={7}>{t("FrameNumber")} 1</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("X1")}</Grid>
                <Grid item xs={7}>12</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Y1")}</Grid>
                <Grid item xs={7}>12</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Width")}</Grid>
                <Grid item xs={7}>12</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Height")}</Grid>
                <Grid item xs={7}>12</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("ColourCode")}</Grid>
                <Grid item xs={7}>#00FF00</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("CameraEvent")}</Grid>
                <Grid item xs={7}>{t("Helmet")}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName='Edit' size='small' onClick={() =>
          setIsModalOpen({
            open: true,
            currentComponent: "addEditRoi",
            isEdit: true
          })
        } />
        <Button buttonName={t("Delete")} color="secondary" size='small' style={{ color: '#fff', borderColor: '#2c2c2c' }} variant='outlined'
          onClick={() =>
            setIsModalOpen({
              open: true,
              currentComponent: "delete",
              isEdit: false,
              deleteHead: `${t("DeleteRoi")}`,
              deletePara: `{("Are_You_Sure_Want_to_Delete_this_Roi?")}`
            })
          }
        />
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } />
    </>
  )
}

export default ViewRoi