import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CloseIcon } from '../../Helper/Icons'
import { Button } from '../Commons'
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const Logout = ({ handleClose }) => {
  const { t } = useTranslation();
    const navigate = useNavigate();
    const cookies = new Cookies();
    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {t("Logout")}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Box width={{ sm: '400px' }}>
                    <Typography>{t("Do_you_want_to_logout_from_this_Web?")} </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName={t("No")} onClick={handleClose} />
                <Button buttonName={t("Yes")} onClick={()=>[cookies.remove('USERTOKEN'),navigate(`/`)]} />
            </DialogActions>
        </div>
    )
}

export default Logout