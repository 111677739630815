import React, { useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Box, Stack, Chip, Typography, Switch } from '@mui/material'
import { Button, Input, Modal } from '../../../Component/Commons'
// import { Pagination, Search } from '../../../Component/Commons/Table'
import { useStyles } from '../../../Helper/Theme'
import { useNavigate } from 'react-router-dom'
import { RiSearchLine } from '../../../Helper/Icons'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
const Registration = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        deletePara: '', deleteHead: '', isEdit: false
    });

    return (
        <>
            <Stack flexDirection={{ xs: 'column-reverse', sm: 'row' }} alignItems={{ xs: 'stretch', sm: 'center' }} justifyContent={'space-between'} gap={{ xs: 1, sm: 2 }} mb={{ xs: 1, sm: 2 }}>
                <Box>
                    <Input
                        hiddenLabel
                        placeholder="Search..."
                        iconInput={<RiSearchLine style={{ color: "#292929" }} />}
                        style={{
                            width: { xs: "100%", sm: "300px" },
                            "& .MuiInputBase-root": {
                                border: "1px solid #151515 !important",
                                input: {
                                    background: "#151515 !important",
                                    padding: "8px 15px",
                                },
                            },
                        }}
                    />
                </Box>
                <Stack flexDirection={'row'} gap={1} alignItems={'end'} justifyContent={'end'}>
                    {/* <DateRange /> */}
                    <Button buttonName='Add Person' onClick={() => setIsModalOpen({ open: true, currentComponent: "addPerson", isEdit: false })} />
                </Stack>
            </Stack>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow className='MuiReportTable-root'>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Gender</TableCell>
                            <TableCell>Contact Number</TableCell>
                            <TableCell>Email ID</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[1, 1, 1, 1].map((data, i) => {
                            return (
                                <TableRow key={i} className='MuiReportTable-root' onClick={() => navigate('/face-reg/registration', { state: { isView: true } })}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>Anshuman</TableCell>
                                    <TableCell>Male</TableCell>
                                    <TableCell>8112321390</TableCell>
                                    <TableCell>Ansh@mail.com</TableCell>
                                    <TableCell onClick={(e) => e.stopPropagation()}> <Switch defaultChecked color="primary" /></TableCell>
                                    <TableCell onClick={(e) => e.stopPropagation()}>
                                        <Stack flexDirection={'row'} gap={1} justifyContent={'flex-end'}>
                                            <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "addPerson", isEdit: true })} />
                                            <DeleteIcon
                                                onClick={() => setIsModalOpen({
                                                    open: true, currentComponent: "delete", deleteHead: 'Delete Person',
                                                    deletePara: 'Are You Sure Want to Delete this Person?'
                                                })} />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        {/* <TableRow>
                            <TableCell sx={{ color: "silver", textAlign: "center", paddingTop: "90px", borderBottom: "none", fontSize: "30px", }} colSpan="6">
                                No records to display
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Pagination /> */}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", deletePara: '', deleteHead: '' })
                } />
        </>
    )
}

export default Registration