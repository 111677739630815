import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { ForgotPasswordImage, Logo } from '../../Helper/Constant'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '../../Component/Commons'

const ForgotPassword = () => {
  const navigate = useNavigate()

  return (
    <Box height={'100vh'}>
      <Grid container height={'100%'} sx={{ overflow: 'hidden' }}>
        <Grid item xs={12} md={6} margin={'auto'} sx={{ overflow: 'auto', height: '100%' }}>
          <Box padding={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box>
              <img src={Logo} alt='logo' />
            </Box>
            <Box sx={{ width: '100%', margin: 'auto', maxWidth: '350px' }}>
              <Stack gap={3}>
                <Box>
                  <Typography fontSize={'28px'} fontWeight={'400'}>Forgot password</Typography>
                  <Typography fontSize={'16px'} fontWeight={'400'}>Please enter the email address associated
                    with your account below. We will send you a link to reset your password</Typography>
                </Box>
                <Stack gap={2}>
                  <Input placeholder='Email' type='email'/>
                </Stack>
                <Box>
                  <Button buttonName='Submit' fullWidth onClick={() => navigate('/')} />
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0} md={6} sx={{ position: 'relative', height: '100%' }}>
          <img src={ForgotPasswordImage} alt='ForgotPasswordImage' />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ForgotPassword