import { DELETE, POST, get, post, put } from "../web.request";

export const loginHandler = (payload) => {
  return POST(`${process.env.REACT_APP_API_URL}/user_login`, payload);
};

export const registerHandler = (payload) => {
  return post(`${process.env.REACT_APP_API_URL}/user_register`, payload);
};

export const getUsers = (text, page) => {
  return get(
    `${process.env.REACT_APP_API_URL}/user_register?search=${text}&page=${page}`
  );
};

export const deleteUserById = (id) => {
  return DELETE(`${process.env.REACT_APP_API_URL}/user_delete/${id}`);
};

export const updateUserByAdmin = (id, payload) => {
  return put(
    `${process.env.REACT_APP_API_URL}/admin_update_user/${id}`,
    payload
  );
};

// WITH OUT PAGINATION USER LIST
export const getUserList = () => {
  return get(`${process.env.REACT_APP_API_URL}/user_list`);
};

//
export const userByIds = (orgId, locationId, areaId, cameraId) => {
  return get(
    `${process.env.REACT_APP_API_URL}/organization_location_area_id_wise_user_list?organization_id=${orgId}&location_id=${locationId}&area_id=${areaId}&camera_id=${cameraId}`
  );
};
