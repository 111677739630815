import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CounterCard from "./CounterCard";
import AlertCard from "../alert/AlertCard";
import { Event } from "../event";
import { Camera } from "../camera";
import CommonChart from "../../Component/Commons/CommonChart";
import { Link } from "react-router-dom";
import AnalyticsChart from "./AnalyticsChart";
import {
  analyticsDataHandler,
  reportChartHandler,
  topFourDataHandler,
} from "../../service/Dashboard.service";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Dashboard = () => {
  const title = "Dashboard -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  const [reportChartData, setReportChartData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [topFourEventData, setTopFourEventData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    reportApiHandler();
    analyticsDataApiHandler();
    topFourDataApiHandler();
  }, []);

  const topFourDataApiHandler = async () => {
    const { count, data, message, success, totalPage } =
      await topFourDataHandler();
    if (success) {
      setTopFourEventData(data);
    } else {
      setTopFourEventData([]);
    }
  };

  const reportApiHandler = async () => {
    const { count, data, message, success, totalPage } =
      await reportChartHandler();
    if (success) {
      const chartData = data.date_wise_counts.map((item) => [
        item.date,
        item.total,
      ]);
      chartData.unshift(["Category", "Total"]);
      setReportChartData(chartData);
    } else {
      setReportChartData(data.date_wise_counts);
    }
  };

  const analyticsDataApiHandler = async () => {
    const { count, data, message, success, totalPage } =
      await analyticsDataHandler();
    if (success) {
      setAnalyticsData(data);
    } else {
      setAnalyticsData([]);
    }
  };
 
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Box>
        <Stack gap={2}>
          <CounterCard />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
                <Typography mb={1}>{t("Report")}</Typography>
                {reportChartData && reportChartData.length > 0 ? (
                  <CommonChart
                    chartType="LineChart"
                    height="calc(100% - 20px)"
                    data={reportChartData ? reportChartData : []}
                  />
                ) : (
                  <h1> {t("DataNotFound")}</h1>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={5}>
            <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={2}
              >
                <Typography>Event</Typography>
                {/* <Typography fontSize={"12px"}>
                  <Link to={"/event"}>view all</Link>
                </Typography> 
              </Stack>
              <Event dashboard={"dashboard"}/>
            </Box>
          </Grid> */}

            <Grid item xs={12} md={7}>
              <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
                <Typography mb={2}>{t("Analytics")}</Typography>
                <Box>
                  <Stack
                    gap={{ xs: 1, sm: 2 }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Stack
                      flexDirection={"row"}
                      gap={{ xs: 1, sm: 2 }}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <AnalyticsChart
                        progressValue={
                          analyticsData?.Gloves ? analyticsData?.Gloves : 0
                        }
                        name={t("NoGlovesDetection")}
                        color="#41FF8D"
                      />
                      <AnalyticsChart
                        progressValue={
                          analyticsData?.Jacket ? analyticsData?.Jacket : 0
                        }
                        name={t("NoJacketsDetection")}
                        color="#8FB9FF"
                      />
                      {/* <AnalyticsChart
                      progressValue={analyticsData?.glasses_percentage ? analyticsData?.glasses_percentage : 0}
                      name={t("NoGlassesDetection")}
                      color="#FFE870"
                    /> */}
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      gap={{ xs: 1, sm: 2 }}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <AnalyticsChart
                        progressValue={
                          analyticsData?.Shoes ? analyticsData?.Shoes : 0
                        }
                        name={t("NoShoesDetection")}
                        color="#FF6C4B"
                      />
                      <AnalyticsChart
                        progressValue={
                          analyticsData.Helmet ? analyticsData.Helmet : 0
                        }
                        name={t("NoHelmetDetection")}
                        color="#5DD4CD"
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ background: "#151515", p: 2 }}>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography>{t("Cameras")}</Typography>
                </Stack>
                <Camera />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>{t("TopAlerts")}</Typography>
            </Grid>
            {topFourEventData &&
              topFourEventData.map((res) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <AlertCard resData={res} />
                  </Grid>
                );
              })}
          </Grid>
        </Stack>
      </Box>
    </HelmetProvider>
  );
};

export default Dashboard;
