import { DELETE, get, post, put } from "../web.request";

export const addEventApiHandler = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/create_camera_event`, payload)
};

export const updateEventApiHandler = (payload, id) => {
    return put(`${process.env.REACT_APP_API_URL}/update_name_camera_event/${id}`, payload);
}

export const deleteEventById = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/camera_event_delete/${id}`);
}

export const getEventDropDown = (query) => {
    return get(`${process.env.REACT_APP_API_URL}/area_list?${query}`);
}

export const getOrganizationdropdown = (search) => {
    return get(`${process.env.REACT_APP_API_URL}/organization_list?search=${search}`);
}

export const getEventDropDownCamera = (query) => {
    return get(`${process.env.REACT_APP_API_URL}/camera_list?${query}`);
}

export const getEventDetails = (id) => {
    return get(`${process.env.REACT_APP_API_URL}/camera_event_details/${id}`);
}
export const getEventList = (query) => {
    return get(`${process.env.REACT_APP_API_URL}/event_list`);
}


