import React from 'react'
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
    const navigate = useNavigate();
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>404 - Not Found</h2>
      <h3 style={{cursor:"pointer"}} onClick={()=>navigate(`/`)}>HOME </h3>
      <p>Sorry, the page you are looking for might be in another dimension!</p>
    </div>
  )
}

export default PageNotFound;
