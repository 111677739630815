import React, { useState } from 'react';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarList } from '../../Helper/Helper';
import { MyComponent } from '../../Helper/Theme';
import { CgMenuLeft, FaUserCircle, IoNotificationsOutline, IoSettingsOutline, BiSolidLock, HiOutlineLogout } from '../../Helper/Icons';
import { profile_image } from '../../Helper/Constant';
import { Modal } from '../Commons';
import { FaUser } from 'react-icons/fa';
import LanguageSelect from '../../Langauge';
import { useTranslation } from "react-i18next";

const Header = ({ handleDrawerToggleWeb, handleDrawerToggle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setProfile(null);
  };

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <MyComponent
              sx={{ cursor: 'pointer' }}

            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggleWeb}
                sx={{ p: '8px', m: '0' }}
              >
                <CgMenuLeft />
              </IconButton>
            </MyComponent>

          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MyComponent sx={{ cursor: 'pointer' }} >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ p: '8px', m: '0' }}
              >
                <CgMenuLeft />
              </IconButton>
            </MyComponent>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box paddingX={2} paddingY={1} onClick={handleClose}>
                <Grid container spacing={3} paddingBottom={1} alignItems='center' justifyContent='space-between'>
                  <Grid item fontSize={16} fontWeight={600} sx={{ color: 'var(--primary)' }}>{t("Notification")}</Grid>
                  <Grid item fontSize={14} fontWeight={500} sx={{ color: 'var(--primary)' }}>{t("Markallasread")}</Grid>
                </Grid>
                {
                  [1, 2, 3].map(() => {
                    return (
                      <Box container spacing={3} padding={2} marginTop={2} sx={{ cursor: 'pointer' }}>
                        <Grid container justifyContent='space-between' alignItems='center' flexWrap={'nowrap'}>
                          <Grid item>
                            <Grid container fontSize={14}>
                              <Grid item xs={12}>New client added </Grid>
                              <Grid item xs={12}> on May 23, 2023 at 6:00 pm</Grid>
                            </Grid>
                          </Grid>
                          <Grid item><FaUser style={{ color: '#fff' }} /></Grid>
                        </Grid>
                      </Box>
                    );
                  })
                }
              </Box>
            </Menu>
          </Box>
          <Box>
            {SidebarList.map((data, index) => {
              return (
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'2px'}>
                  {location.pathname === data.path &&
                    <>
                      <Box width={'40px'} height={'40px'} display={'flex'} padding={'10px'} sx={{ background: '#000', borderRadius: '50%' }}>
                        <img src={data.icon} />
                      </Box>
                      <Typography fontSize={'18px'} fontWeight={'500'}>{`${t(data.name)}`}</Typography>
                    </>
                  }
                </Stack>
              );
            })}

            {location.pathname === '/alert/details' ?
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                <Box width={'40px'} height={'40px'} display={'flex'} padding={'10px'} sx={{ background: '#000', borderRadius: '50%' }}>
                  {/* <img src={Alert} />  */}
                </Box>
                <Typography fontSize={'20px'} fontWeight={'500'}>{t("Alert")}</Typography>
              </Stack>
              : ''}
          </Box>
        </Stack>
        <Box >
          <Stack flexDirection={'row'} gap={1}>
            <LanguageSelect />

            <MyComponent
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('/settings')}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ p: '8px', m: '0' }}
              >
                <IoSettingsOutline style={{ padding: '2px' }} />
              </IconButton>
            </MyComponent>
            <MyComponent
              sx={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ p: '8px', m: '0' }}
              >
                <IoNotificationsOutline style={{ padding: '2px' }} />
              </IconButton>
            </MyComponent>
            <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={handleClickProfile}>
              <img src={profile_image} alt='profile_image' width={'40px'} height={'40px'} />
            </Box>
          </Stack>
        </Box>
        <Menu
          anchorEl={profile}
          open={Boolean(profile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => { navigate('/settings') }} onMouseUp={handleCloseProfile}>
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <FaUserCircle style={{ fontSize: '18px' }} />
              <div style={{ display: 'flex' }}>
                {t("ProfileSettings")}
              </div>
            </Stack>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => setIsModalOpen({ open: true, currentComponent: "changePassword", })}
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <BiSolidLock style={{ fontSize: '18px' }} />
              <div style={{ display: 'flex' }}>
                {t("ChangePassword")}
              </div>
            </Stack>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => setIsModalOpen({ open: true, currentComponent: "logout", })}
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <HiOutlineLogout style={{ fontSize: '18px' }} />
              <div style={{ display: 'flex' }}>
                {t("Logout")}
              </div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        } />
    </>
  )
}

export default Header