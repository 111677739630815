import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { useFormik } from "formik";
import { addEventValidation } from "../../Helper/validation";
import { addEventIv } from "../../Helper/intialValues";
import { addEventApiHandler, getOrganizationdropdown, getEventDropDown, getEventDropDownCamera, updateEventApiHandler } from "../../service/Event.service";
import { errorNotification, successNotification } from "../../Helper/Notification";
import CommonAutocomplete from "../../Component/Commons/AutoComplete";
import { locationByOrgId } from "../../service/location.service";
import { useTranslation } from "react-i18next";

const AddEvent = ({ handleClose, modalOpen, eventListDataForSelectBox, closeFlag }) => {
  const { t } = useTranslation();
  const eventsList = [
    { name: "Helmet ", value: "Helmet" },
    // { name: "Glasses", value: "Glasses" },
    { name: "Gloves", value: "Gloves" },
    { name: "Jackets", value: "Jackets" },
    { name: "Shoes", value: "Shoes" },
  ];
  const [selectEvent, setSelectEvent] = useState([]);
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  // const [dropdownData, setDropdownData] = useState([{
  //   organization: [],
  //   location: [],
  //   area: [],
  //   camera: []
  // }]);
  const [organization, setOrganization] = useState([]);
  const [location, setLocation] = useState([]);
  const [area, setArea] = useState([]);
  const [camera, setCamera] = useState([]);

  const eventHandler = (event) => {
    console.log("evenddsdsdsdst", event.target.value);
    setSelectEvent(event.target.value);
    // formik.setFieldValue('camera_event', event.target.value);
  };

  const getEventDetails = () => {
    const response = modalOpen?.data?.totalData;
    formik.setValues({
      ...formik.values,
      location: response.location_name,
      camera: response.camera_name,
      area: response.area_name
    });
  };

  useEffect(() => {
    if (modalOpen?.isEdit) {
      getEventDetails();
    }
  }, []);



  const submitHandler = async () => {
    const payload = {
      "organization_id": formik.values.organizationId,
      "location_id": formik.values.locationId,
      "area_id": formik.values.areaId,
      "camera_id": formik.values.cameraId,
      "event_names": formik.values.camera_event && formik.values.camera_event.length > 0 ? formik.values.camera_event : ""
    }

    const { data, message, success } = modalOpen?.isEdit ? await updateEventApiHandler(payload, modalOpen?.data?.totalData?.id) : await addEventApiHandler(payload);
    if (success) {
      successNotification(message);
      handleClose();
      closeFlag();
    } else {
      errorNotification(message);
      closeFlag();
    }
  };
  const formik = useFormik({
    initialValues: addEventIv,
    validationSchema: addEventValidation,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    getOrganisationList();
  }, []);

  const getOrganisationList = async () => {
    const { data, message, success } = await getOrganizationdropdown("");
    if (success) {

      //data in delete organization_user 
      let datas = data && data.length > 0 && data?.map((res) => {
        return {
          label: res.org_name,
          id: res.id
        }
      })
      console.log("datasorganization", data, datas)

      setOrganization(datas);

    } else {
      setOrganization([]);
    }
  }
  console.log("organization", organization)

  const dropdownDatanew = async (orgId, search) => {
    const { count, data, message, success, totalPage } = await locationByOrgId(orgId, search);
    if (success) {
      let datas = data.map((res) => {
        return {
          label: res.loc_name,
          id: res.id
        }
      })
      setLocation(datas);
    } else {
      setLocation([]);
    }
  };

  useEffect(() => {
    if (formik.values.organizationValue != "") {
      dropdownDatanew(formik.values.organizationId, "");
    }
  }, [formik.values.organizationValue]);


  useEffect(() => {
    console.log("LOCATION_VALUE", formik.values.locationId);
    if (formik.values.locationId != "") {
      getDropDownData(`organization_id=${formik.values.organizationId}&location_id=${formik.values.locationId}`, 2);
    }
  }, [formik.values.locationId]);

  useEffect(() => {
    if (formik.values.areaValue != "") {
      getDropDownDataCamera(`organization_id=${formik.values.organizationId}&location_id=${formik.values.locationId}&area_id=${formik.values.areaId}`, 3);
    }
  }, [formik.values.areaValue]);

  useEffect(() => {
    if (modalOpen?.isEdit) {
      formik.setFieldValue('organizationId', modalOpen?.data?.totalData?.organization);
      formik.setFieldValue('organizationValue', { label: modalOpen?.data?.totalData?.organization_name, id: modalOpen?.data?.totalData?.organization });
      formik.setFieldValue('locationId', modalOpen?.data?.totalData?.location);
      formik.setFieldValue('locationValue', { label: modalOpen?.data?.totalData?.location_name, id: modalOpen?.data?.totalData?.location });
      formik.setFieldValue('areaId', modalOpen?.data?.totalData?.area);
      formik.setFieldValue('areaValue', { label: modalOpen?.data?.totalData?.area_name, id: modalOpen?.data?.totalData?.area });
      formik.setFieldValue('cameraId', modalOpen?.data?.totalData?.camera);
      formik.setFieldValue('cameraValue', { label: modalOpen?.data?.totalData?.camera_name, id: modalOpen?.data?.totalData?.camera });
      setSelectEvent(modalOpen?.data?.totalData?.camera_event);
      dropdownDatanew(modalOpen?.data?.totalData?.organization, "");
      getDropDownData(`organization_id=${modalOpen?.data?.totalData?.organization}&location_id=${modalOpen?.data?.totalData?.location}`, 2);
      getDropDownDataCamera(`organization_id=${modalOpen?.data?.totalData?.organization}&location_id=${modalOpen?.data?.totalData?.location}&area_id=${modalOpen?.data?.totalData?.area}`, 3);
      formik.setFieldValue('camera_event', modalOpen?.data?.totalData?.camera_event);

    }
  }, [modalOpen]);




  const getDropDownData = async (query, type) => {
    const { data, message, success } = await getEventDropDown(query);
    if (success) {
      if (type === 1) {
        let datas = data.map((res) => {
          return {
            label: res.loc_name,
            id: res.id
          }
        })
        setLocation(datas);
      } else if (type === 2) {
        let datas = data.map((res) => {
          return {
            label: res.area_name,
            id: res.id
          }
        })
        setArea(datas);

      }

      else {
        setOrganization([])
        setArea([])
        setLocation([])
        setCamera([])
      }
    } else {
      if (type === 1) {
        setLocation([]);

      } else if (type === 2) {
        setArea([]);
      }

      else {
        setOrganization([])
        setArea([])
        setLocation([])
        setCamera([])
      }
    }
  }
  console.log("eventListDataForSelectBox", eventListDataForSelectBox)


  const getDropDownDataCamera = async (query, type) => {
    const { data, message, success } = await getEventDropDownCamera(query);
    if (success) {
      let datas = data.map((res) => {
        return {
          label: res.camera_name,
          id: res.id
        }
      })
      setCamera(datas);
    } else {
      setCamera([]);
    }
  }




  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? `${t("Edit")}` : `${t("Add")}`} {t("Event")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>

          <Grid item xs={12}>
            <CommonAutocomplete
              data={organization ? organization : []}
              labelinput={t("SelectOrganization")}
              onChange={(e, val) => {
                formik.setFieldValue('organizationId', val && val?.id ? val.id : '')
                formik.setFieldValue('organizationValue', val ? val : '')
                formik.setFieldValue('locationId', '')
                formik.setFieldValue('locationValue', '')
                formik.setFieldValue('areaId', '')
                formik.setFieldValue('areaValue', '')
                formik.setFieldValue('cameraId', '')
                formik.setFieldValue('cameraValue', '')

              }}
              name='organizationId'
              name1='organizationValue'
              value={formik.values.organizationValue ? formik.values.organizationValue : ''}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>

            <CommonAutocomplete
              data={location && location !== undefined ? location : []}
              labelinput={t("SelectLocation")}
              onChange={(e, val) => {
                formik.setFieldValue('locationId', val && val?.id ? val.id : '')
                formik.setFieldValue('locationValue', val ? val : '')
                formik.setFieldValue('areaId', '')
                formik.setFieldValue('areaValue', '')
                formik.setFieldValue('cameraId', '')
                formik.setFieldValue('cameraValue', '')
              }}
              name='locationId'
              name1='locationValue'
              value={formik.values.locationValue ? formik.values.locationValue : ''}
              formik={formik}
            />


          </Grid>
          <Grid item xs={12}>
            <CommonAutocomplete
              data={area && area !== undefined ? area : []}
              labelinput={t("SelectArea")}
              onChange={(e, val) => {
                formik.setFieldValue('areaId', val && val?.id ? val.id : '')
                formik.setFieldValue('areaValue', val ? val : '')
                formik.setFieldValue('cameraId', '')
                formik.setFieldValue('cameraValue', '')
              }}
              name='areaId'
              name1='areaValue'
              value={formik.values.areaValue ? formik.values.areaValue : ''}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>



            <CommonAutocomplete
              data={camera ? camera : []}
              labelinput={t("SelectCamera")}
              onChange={(e, val) => {
                formik.setFieldValue('cameraId', val && val?.id ? val.id : '')
                formik.setFieldValue('cameraValue', val ? val : '')
              }}
              name='cameraId'
              name1='cameraValue'
              value={formik.values.cameraValue ? formik.values.cameraValue : ''}
              formik={formik}
            />

          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"14px"} fontWeight={600} marginBottom={"6px"}>
              {t("Event")}
            </Typography>
            <Select
              value={selectEvent ? selectEvent : []}
              onChange={eventHandler}
              displayEmpty
              multiple
              fullWidth
            >
              {eventsList && eventsList.map((res) => {
                return (
                  <MenuItem value={res.value}>{res.name}</MenuItem>
                )
              })}
            </Select>
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.camera_event}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <Input placeholder="Location" formik={formik} name="location" errorDisplay={errorDisplayFlag} />
          </Grid>
          <Grid item xs={12}>
            <Input placeholder="Area" formik={formik} name="area" errorDisplay={errorDisplayFlag} />
          </Grid>
          <Grid item xs={12}>
            <Input placeholder="Camera" formik={formik} name="camera" errorDisplay={errorDisplayFlag} />
          </Grid> */}
        </Grid>
      </DialogContent >
      <DialogActions>
        <Button
          buttonName={modalOpen.isEdit ? "Save" : "Add"}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
          size="small"
        />
        <Button
          buttonName={t("Cancel")}
          color="secondary"
          style={{ color: "#fff", borderColor: "#2c2c2c" }}
          size="small"
          variant="outlined"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default AddEvent;
