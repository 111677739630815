import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Input } from "../../Component/Commons";
import { CloseIcon } from "../../Helper/Icons";
import { useFormik } from "formik";
import { addOrganizationIv } from "../../Helper/intialValues";
import { addOrganizationValidation } from "../../Helper/validation";
import { getUserList } from "../../service/User.service";
import { createOrg, editOrgHandler } from "../../service/Organization.service";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { useTranslation } from "react-i18next";

function AddOrganization({ handleClose, modalOpen, closeFlag }) {
  const { t } = useTranslation();
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [loading, setLoading] = useState(false);

  const response = modalOpen?.data?.totalData;

  useEffect(() => {
    if (modalOpen.isEdit) {
      getOrgListHandler();
    }
  }, []);

  const getOrgListHandler = () => {
    const userIDS = response.organization_user && response.organization_user.length > 0 && response.organization_user.map((res) => res.id);
    formik.setValues({
      ...formik.values,
      user_ids: userIDS,
      org_name: response.org_name,
      org_address: response.org_address,
      org_city: response.org_city,
      org_state: response.org_state,
      org_country: response.org_country,
      org_zipcode: response.org_zipcode,
      org_phone: response.org_phone,
      org_email: response.org_email,
    });
    setSelectUser(userIDS);
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = modalOpen.isEdit ? await editOrgHandler(response.id, formik.values) : await createOrg(formik.values);
      if (success) {
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (error) {
      errorNotification(error);
    } finally {
      setLoading(false);
      handleClose();
      closeFlag();
    }
  };

  const formik = useFormik({
    initialValues: addOrganizationIv,
    validationSchema: addOrganizationValidation,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    getUserHandler();
  }, []);

  const getUserHandler = async () => {
    const { count, data, message, success, totalPage } = await getUserList();
    if (success) {
      setUserList(data);
    } else {
      setUserList([]);
    }
  };

  const userHandler = (event) => {
    setSelectUser(event.target.value);
    formik.setFieldValue("user_ids", event.target.value);
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? `${t("Edit")}` : `${t("Add")}`} {t("Organization")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Typography fontSize={"14px"} fontWeight={600} marginBottom={"6px"}>
              {t("User")}
            </Typography>
            <Select
              value={selectUser}
              onChange={userHandler}
              multiple
              displayEmpty
              fullWidth
            >
              {userList &&
                userList.map((response) => {
                  return (
                    <MenuItem value={response.id}>
                      {" "}
                      {response.first_name} {response.last_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationName")}
              name="org_name"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationAddress")}
              name="org_address"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationCity")}
              name="org_city"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationState")}
              name="org_state"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationCountry")}
              name="org_country"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationZipcode")}
              name="org_zipcode"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationPhone")}
              name="org_phone"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("OrganizationEmail")}
              name="org_email"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={modalOpen.isEdit ? `${t("Save")}` : `${t("Add")}`}
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
          size="small"
        />
        <Button
          buttonName={t("Cancel")}
          color="secondary"
          style={{ color: "#fff", borderColor: "#2c2c2c" }}
          size="small"
          variant="outlined"
          onClick={() => [handleClose(), closeFlag()]}
        />
      </DialogActions>
    </>
  );
}

export default AddOrganization;
