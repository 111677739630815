import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
    alertBlack,
    cameraBlack,
    eventBlack,
    locationBlack,
} from "../../../Helper/Constant";

const counterData = [
    {
        img: alertBlack,
        imgbg: "#FF6C4B",
        name: "Registed Face",
        count: "145 +",
        per: "+ 10.6",
    },
    // {
    //     img: eventBlack,
    //     imgbg: "#FFE870",
    //     name: "Today Attendance",
    //     count: "145 +",
    //     per: "+ 10.6",
    // },
    {
        img: cameraBlack,
        imgbg: "#8FB9FF",
        name: "Total Camera",
        count: "145 +",
        per: "+ 10.6",
    },
    {
        img: locationBlack,
        imgbg: "#41FF8D",
        name: "Total Location",
        count: "145 +",
        per: "+ 10.6",
    },
];

const CounterCard = () => {


    return (
        <Box>
            <Grid container spacing={2}>
                {counterData.map((data, i) => {
                    return (
                        <Grid key={i} item xs={6} md={4}>
                            <Box
                                sx={{
                                    background: "#151515",
                                    p: { xs: "20px 10px", sm: "30px 15px" },
                                    position: "relative",
                                }}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    gap={2}
                                >
                                    <Box
                                        sx={{
                                            background: data.imgbg,
                                            width: { xs: "40px", sm: "50px" },
                                            height: { xs: "40px", sm: "50px" },
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={data.img} alt="" width={28} />
                                    </Box>
                                    <Stack>
                                        <Typography
                                            fontSize={{ xs: "20px", sm: "25px" }}
                                            fontWeight={"600"}
                                            lineHeight={{ xs: "27px", sm: "32px" }}
                                        >
                                            {data.count}
                                        </Typography>
                                        <Typography
                                            fontSize={{ xs: "12px", sm: "15px" }}
                                            lineHeight={{ xs: "18px", sm: "22px" }}
                                        >
                                            {data.name}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                    )
                })}
                {/* <Grid item xs={6} md={3}>
                    <Box
                        sx={{
                            background: "#151515",
                            p: { xs: "20px 10px", sm: "30px 15px" },
                            position: "relative",
                        }}
                    >
                        <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={"10px"}
                        >
                            <Box
                                sx={{
                                    background: "#FFE870",
                                    width: { xs: "40px", sm: "50px" },
                                    height: { xs: "40px", sm: "50px" },
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img src={eventBlack} alt="" width={28} />
                            </Box>
                            <Stack>
                                <Typography
                                    fontSize={{ xs: "20px", sm: "25px" }}
                                    fontWeight={"600"}
                                    lineHeight={{ xs: "27px", sm: "32px" }}
                                >
                                    {dashboarApiData.event_count ? dashboarApiData.event_count : "0"}
                                </Typography>
                                <Typography
                                    fontSize={{ xs: "12px", sm: "15px" }}
                                    lineHeight={{ xs: "18px", sm: "22px" }}
                                >
                                    {"Total Event"}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Box
                        sx={{
                            background: "#151515",
                            p: { xs: "20px 10px", sm: "30px 15px" },
                            position: "relative",
                        }}
                    >
                        <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={"10px"}
                        >
                            <Box
                                sx={{
                                    background: "#FF6C4B",
                                    width: { xs: "40px", sm: "50px" },
                                    height: { xs: "40px", sm: "50px" },
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img src={cameraBlack} alt="" width={28} />
                            </Box>
                            <Stack>
                                <Typography
                                    fontSize={{ xs: "20px", sm: "25px" }}
                                    fontWeight={"600"}
                                    lineHeight={{ xs: "27px", sm: "32px" }}
                                >
                                    {dashboarApiData.camera_count ? dashboarApiData.camera_count : 0}
                                </Typography>
                                <Typography
                                    fontSize={{ xs: "12px", sm: "15px" }}
                                    lineHeight={{ xs: "18px", sm: "22px" }}
                                >
                                    {"Total Camera"}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Box
                        sx={{
                            background: "#151515",
                            p: { xs: "20px 10px", sm: "30px 15px" },
                            position: "relative",
                        }}
                    >
                        <Stack
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={"10px"}
                        >
                            <Box
                                sx={{
                                    background: "#FF6C4B",
                                    width: { xs: "40px", sm: "50px" },
                                    height: { xs: "40px", sm: "50px" },
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img src={locationBlack} alt="" width={28} />
                            </Box>
                            <Stack>
                                <Typography
                                    fontSize={{ xs: "20px", sm: "25px" }}
                                    fontWeight={"600"}
                                    lineHeight={{ xs: "27px", sm: "32px" }}
                                >
                                    {dashboarApiData.location_count ? dashboarApiData.location_count : 0}
                                </Typography>
                                <Typography
                                    fontSize={{ xs: "12px", sm: "15px" }}
                                    lineHeight={{ xs: "18px", sm: "22px" }}
                                >
                                    {"Total Location"}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid> */}
            </Grid>
        </Box>
    );
};

export default CounterCard;
