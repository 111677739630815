import React from 'react'
import Chart from 'react-google-charts'

const CommonChart = (props) => {
    const { data, chartType, height, title } = props

    const options = {
        title: title,
        // curveType: "function",   
        legend: 'none',
        backgroundColor: '#151515', // Dark background color
        titleTextStyle: { color: '#e3e3e3' },
        hAxis: {
            textStyle: { color: '#e3e3e3',fontSize: 12 },
            titleTextStyle: { color: '#e3e3e3' },
            gridlines: { color: '#333' }
        },
        vAxis: {
            minValue: 0,
            // baseline: 0, // Add this line
            viewWindow: {
                min: 0,
                // max: 50
            },
            textStyle: { color: '#e3e3e3',fontSize: 12 },
            titleTextStyle: { color: '#e3e3e3' },
            gridlines: { color: '#333' }
        },
        chartArea: { width: '90%' },

    }
    return (
            <Chart
                width={'100%'}
                height={'310px'}
                loader={<div>Loading Chart</div>}
                chartType={chartType}
                data={data}
                options={options}
            />
    )
}

export default CommonChart
