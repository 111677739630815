import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  getAttendnceStatus,
  useStyles,
} from "../../../Helper/Theme";
import { Button, Input, Modal } from "../../../Component/Commons";
import { VscSettings, RiSearchLine } from "../../../Helper/Icons";
import { reportView } from "../../../Helper/Constant";

const Attendance = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  var status = getAttendnceStatus(true);
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search for Attendance"
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Name:
            </Typography>
            <Input placeholder="Enter Name" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Email:
            </Typography>
            <Input placeholder="Search  Email" type="text" />
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Start Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              End Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Check in:
            </Typography>
            <Input type="time" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Check out:
            </Typography>
            <Input type="time" />
          </Grid>
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Child</StyledTableCell>
              <StyledTableCell>Guardian</StyledTableCell>
              <StyledTableCell>Educator</StyledTableCell>
              <StyledTableCell>Unknown</StyledTableCell>
              <StyledTableCell>Other</StyledTableCell>
              <StyledTableCell>Banned</StyledTableCell>
              <StyledTableCell>Entry</StyledTableCell>
              <StyledTableCell>Exit</StyledTableCell>
              <StyledTableCell>Total</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 1, 1, 1].map((data, i) => {
              return (
                <StyledTableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <img src={reportView} width={50} alt="reportView" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    15-01-2024
                  </TableCell>
                  <TableCell component="th" scope="row">
                    John Wick
                  </TableCell>
                  <TableCell component="th" scope="row">
                    johnwick@gmail.com
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Category 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    child 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Guardian 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Educator 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Unknown 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Other 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Banned 1
                  </TableCell>

                  <TableCell component="th" scope="row">
                    12:05:57
                  </TableCell>
                  <TableCell component="th" scope="row">
                    07:55:38
                  </TableCell>
                  <TableCell component="th" scope="row">
                    18
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Chip label={status?.title} sx={status?.styles} />
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      ></Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
    </>
  );
};

export default Attendance;
