import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { GrFormAdd, VscSettings, RiSearchLine } from "../../Helper/Icons";
import { CameraView } from "../../Helper/Constant";
import { cameraDataHandler } from "../../service/Dashboard.service";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Camera = () => {
  const { t } = useTranslation();
  const [cameraListData, setCameraListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [locationId, setLocation] = useState("");
  const [area, setArea] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    cameraApiHandler(searchText, currentPage, locationId, area, startDate, endDate);
  }, []);

  const deleteHandler = (id) => {
    console.log("id", id);
    setIsModalOpen({
      open: true,
      currentComponent: "delete",
      isEdit: false,
      data: {
        head: `${t("RemoveCamera")}`,
        message: `${t("Do_you_want_to_Remove_this_Camera?")}`,
        id: id,
        name: "CAMERA",
      },
    });
  };

  const cameraApiHandler = async (searchText, currentPage, locationId, area, startDate, endDate) => {
    const { count, data, message, success, totalPage } =
      await cameraDataHandler(searchText, currentPage, locationId, area, startDate, endDate);
    if (success) {
      setCameraListData(data);
    } else {
      setCameraListData([]);
    }
  };

  const searchHandler = (event) => {
    cameraApiHandler(event.target.value, 1, locationId, area, startDate, endDate);
  };

  const refreshHandler = () => {
    cameraApiHandler(searchText, currentPage, locationId, area, startDate, endDate);
  };

  const filterHandler = () => {
    cameraApiHandler(searchText, currentPage, locationId, area, startDate, endDate);
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const title = "Camera -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {location.pathname === "/dashboard" ? (
        ""
      ) : (
        <Stack
          direction="row"
          className="table_search_head"
          justifyContent="space-between"
          width="100%"
          sx={{
            marginBottom: { xs: "12px" },
            flexDirection: { xs: "column-reverse", sm: "row" },
            gap: "8px",
            alignItems: { xs: "end", sm: "center" },
          }}
        >
          <Box className="search_field">
            <Input
              hiddenLabel
              placeholder={t("SearchforCamera")}
              onChange={(event) => searchHandler(event)}
              iconInput={<RiSearchLine style={{ color: "#292929" }} />}
              style={{
                width: { xs: "100%", sm: "300px" },
                "& .MuiInputBase-root": {
                  border: "1px solid #151515 !important",
                  input: {
                    background: "#151515 !important",
                    padding: "8px 15px",
                  },
                },
              }}
            />
          </Box>
          <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
            <Button
              buttonName={t("AddCamera")}
              startIcon={<GrFormAdd />}
              onClick={() =>
                setIsModalOpen({
                  open: true,
                  currentComponent: "addEditCamera",
                  isEdit: false,
                })
              }
            />
            <Button
              buttonName={
                <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
              }
              style={{ minWidth: "25px", padding: "6px 13px" }}
              onClick={handleFilterClick}
            />
          </Stack>
        </Stack>
      )}
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Location")}:
            </Typography>
            <Input
              placeholder={t("EnterLocation")}
              type="text"
              value={locationId}
              onChange={(e) => setLocation(e.target.value)}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Area")}:
            </Typography>
            <Input
              placeholder={t("EnterArea")}
              type="text"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />
          </Grid>
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} onClick={filterHandler} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("CameraName")}</StyledTableCell>
              <StyledTableCell>{t("Location")}</StyledTableCell>
              <StyledTableCell>{t("Area")}</StyledTableCell>
              <StyledTableCell>{t("Time&Date")}</StyledTableCell>
              <StyledTableCell>{t("RTSPLink")}</StyledTableCell>
              <StyledTableCell>{t("Action")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cameraListData && cameraListData.length > 0 ? (
              cameraListData.map((data, i) => {
                return (
                  <StyledTableRow
                    key={i}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewCamera",
                        data: {
                          totalData: data,
                        },
                      })
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <img src={CameraView} alt="CameraView" width={30} />{" "}
                        {data.camera_name ? data.camera_name : '-'}
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.loc_name ? data.loc_name : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.area_name ? data.area_name : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(data.created_at).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.rtsp_url ? data.rtsp_url : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(data.id);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("Norecordstodisplay")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {location.pathname === "/dashboard" ? (
        ""
      ) : (
        <Stack
          sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
          flexDirection="row"
          justifyContent="space-between"
        >
        </Stack>
      )}
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        setIsModalOpen={setIsModalOpen}
        closeFlag={() => refreshHandler()}
      />
    </HelmetProvider>
  );
};

export default Camera;