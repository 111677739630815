import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { Button, Popover, List, ListItem, ListSubheader } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },
  pt: { label: "Portuguese", dir: "ltr", active: false },
};


const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "pt";

  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [menuAnchor, selected]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)} style={{ color: "black", background: '#05EE3B', padding: '8px 15px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
        {languageMap[selected]?.label}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div>
          <List>
            {/* <ListSubheader>{t("select_language")}</ListSubheader> */}
            {Object.keys(languageMap)?.map((item) => (
              <ListItem

                key={item}
                onClick={() => {

                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                  // localStorage.setItem("i18nextLng", item.toString());
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
