import React, { useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../Helper/Icons'
import { Button, Input } from '../../../Component/Commons'

const AddPerson = ({ handleClose, modalOpen }) => {
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Add'} Person
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Input placeholder='Name' />
                    </Grid>
                    <Grid item xs={12}>
                        <Input placeholder='Gender' />
                    </Grid>
                    <Grid item xs={12}>
                        <Input placeholder='Contact Number' />
                    </Grid>
                    <Grid item xs={12}>
                        <Input placeholder='Email' />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={modalOpen.isEdit ? 'Save' : 'Add'} onClick={handleClose} />
                <Button buttonName='Cancel' color="secondary" style={{ color: '#fff', borderColor: '#2c2c2c' }} variant='outlined' onClick={handleClose} />
            </DialogActions>

        </>
    )
}

export default AddPerson