import React from "react";
import {Navigate,Outlet} from "react-router-dom";
import Cookies from "universal-cookie";
const useAuth = () =>{
  const cookies = new Cookies();
  const token = cookies.get('USERTOKEN');
  if(token) {
    const user = { loggedIn : true};
    return user && user.loggedIn;
  }else{
    const user = { loggedIn : false};
    return user && user.loggedIn;
  }
};

const PrivateRoute = () =>{
  const isAuth = useAuth();
  return isAuth ? <Outlet/> : <Navigate to="/"/>
};


export default PrivateRoute;