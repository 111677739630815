import { Box, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../Helper/Icons";
import { CameraView } from "../../Helper/Constant";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Mode } from "@mui/icons-material";
import { getanprAlertByID } from "../../service/Lpr.service";

function ViewLpr1({ handleClose, modalOpen, closeFlag }) {

  const response = modalOpen?.data?.totalData;
  const [alertData, setAlertData] = useState({})
  const { t } = useTranslation();

  const getAlertDetails = async (id) => {
    const { count, data, message, success, totalPage } = await getanprAlertByID(id);
    if (success) {
      setAlertData(data)
    } else {
      setAlertData({});
      handleClose();
    }
  }

  useEffect(() => {
    if (modalOpen && modalOpen.id) {
      getAlertDetails(modalOpen.id);
    }
  }, [])

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("DetailsofAlerts")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon onClick={() => {
          handleClose();
          console.log("handleClose", handleClose);
        }} />
      </IconButton >
      <DialogContent dividers>
        <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img src={alertData.frame} style={{ width: "350px", objectFit: 'contain', height: 'auto' }} />
          <Typography>{alertData.camera_name ? alertData.camera_name : '-'}</Typography>
        </Stack>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("CameraName")}</Grid>
                <Grid item xs={7}>{alertData.camera_name ? alertData.camera_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("AlertMessage")}</Grid>
                <Grid item xs={7}>{alertData.alert_message ? alertData.alert_message : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("EventType")}</Grid>
                <Grid item xs={7}>{alertData.camera_events_name ? alertData.camera_events_name : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("LocationName")}</Grid>
                <Grid item xs={7}>{alertData.location_name ? alertData.location_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("OrganizationName")}</Grid>
                <Grid item xs={7}>{alertData.organization_name ? alertData.organization_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Date&Time")} </Grid>
                <Grid item xs={7}>{moment(alertData.created_at).format("DD-MM-YYYY HH:mm:ss")}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
}

export default ViewLpr1;