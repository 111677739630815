import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import { useNavigate } from "react-router-dom";
import { GrFormAdd, VscSettings, RiSearchLine } from "../../Helper/Icons";
import { getAreaHandler } from "../../service/area.service";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Area = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [areaId, setAreaId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    areaListhandler(areaId, locationId, orgId, startDate, endDate, searchText);
  }, []);

  const deleteHandler = (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "delete",
      isEdit: false,
      data: {
        head: `${t("Remove Area")}`,
        message: `${t("Do_you_want_to_Remove_this_Area")}`,
        id: id,
        name: "AREA",
      },
    });
  };

  const closeHandler = () => {
    areaListhandler(areaId, locationId, orgId, startDate, endDate, searchText);
  };

  const areaListhandler = async (
    areaId,
    locationId,
    orgId,
    startDate,
    endDate,
    search
  ) => {
    try {
      setLoading(true);
      const { count, data, message, success, totalPage } = await getAreaHandler(
        areaId,
        locationId,
        orgId,
        startDate,
        endDate,
        search
      );
      if (success) {
        setAreaList(data);
      } else {
        setAreaList(data);
      }
    } catch (error) {
      setAreaList([]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const searchHandler = (event) => {
    const inputText = event.target.value;
    setSearchText(inputText);
    areaListhandler(areaId, locationId, orgId, startDate, endDate, inputText);
  };

  const handleFilterChange = (field, value) => {
    switch (field) {
      case "orgId":
        setOrgId(value);
        break;
      case "locationId":
        setLocationId(value);
        break;
      case "areaId":
        setAreaId(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      default:
        break;
    }
  };

  const filterHandler = () => {
    areaListhandler(areaId, locationId, orgId, startDate, endDate, searchText);
  };

  const title = "Area -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("Search_for_Area")}
            onChange={(event) => searchHandler(event)}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          <Button
            buttonName={t("Add_Area")}
            startIcon={<GrFormAdd />}
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "addEditArea",
                isEdit: false,
              })
            }
          />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("OrganizationName")}:
            </Typography>
            <Input
              placeholder={t("EnterOrganizationName")}
              type="text"
              value={orgId}
              onChange={(e) => handleFilterChange("orgId", e.target.value)}
            />
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Location")}:
            </Typography>
            <Input
              placeholder={t("EnterLocation")}
              type="text"
              value={locationId}
              onChange={(e) => handleFilterChange("locationId", e.target.value)}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Area")}:
            </Typography>
            <Input
              placeholder={t("EnterArea")}
              type="text"
              value={areaId}
              onChange={(e) => handleFilterChange("areaId", e.target.value)}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("StartDate")}:
            </Typography>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => handleFilterChange("startDate", e.target.value)}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("EndDate")}:
            </Typography>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => handleFilterChange("endDate", e.target.value)}
            />
          </Grid>

          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} onClick={filterHandler} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("OrganizationName")}</StyledTableCell>
              <StyledTableCell>{t("LocationName")}</StyledTableCell>
              <StyledTableCell>{t("AreaName")}</StyledTableCell>
              <StyledTableCell>{t("Address")}</StyledTableCell>
              <StyledTableCell>{t("Time&Date")}</StyledTableCell>
              <StyledTableCell> {t("Action")} </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areaList && areaList.length > 0 ? (
              areaList.map((res, i) => {
                return (
                  <StyledTableRow
                    key={i}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewArea",
                        data: {
                          totalData: res,
                        },
                      })
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {res.org_name ? res.org_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.loc_name ? res.loc_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.area_name ? res.area_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.area_address ? res.area_address : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.created_at
                        ? moment(res.created_at).format("DD-MM-YYYY HH:mm:ss")
                        : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(res.id);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("Norecordstodisplay")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {/* <div>
          Showing{" "}
          1{" "}
          of 1 Results
        </div> */}
        {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={() => closeHandler()}
      />
    </HelmetProvider>
  );
};

export default Area;