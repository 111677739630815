import { DELETE, get, post, put } from "../web.request";

export const createAreaHandler = (payload) =>{
    return post(`${process.env.REACT_APP_API_URL}/create_area`,payload);
};  

export const updateAreaHandler = (id,payload) =>{
return put(`${process.env.REACT_APP_API_URL}/update_area/${id}`,payload);
};


export const deleteAreaHandler = (id) =>{
    return DELETE(`${process.env.REACT_APP_API_URL}/delete_area/${id}`)
};


export const getAreaHandler = (areaId,locationId,orgId,startDate,endDate,search) =>{
    return get(`${process.env.REACT_APP_API_URL}/create_area?area_id=${areaId}&location_id=${locationId}&organization_id=${orgId}&start_date=${startDate}&end_date=${endDate}&search=${search}`)
};

// Get orgList By orgId and locationId

export const getAreaById = (orgId,locId,search) =>{
    return get(`${process.env.REACT_APP_API_URL}/area_list?organization_id=${orgId}&location_id=${locId}&search=${search}`)
}