import { Box, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../Helper/Icons";
import { CameraView } from "../../Helper/Constant";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getanprAlertByID } from "../../service/Lpr.service"

function ViewLpr({ selectedLpr, handleClose }) {

  const [alertData, setAlertData] = useState({})

  const { t } = useTranslation();

  const getAlertDetails = async () => {
    const { count, data, message, success, totalPage } = await getanprAlertByID(selectedLpr);
    if (success) {
      setAlertData(data)
    } else {
      setAlertData({});
      handleClose();
    }
  }

  useEffect(() => {
    getAlertDetails();
  }, [])

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("DetailsofAlerts")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon onClick={handleClose} />
      </IconButton >
      <DialogContent dividers>
        <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img src={alertData?.alert_obj?.frame} style={{ width: "350px", objectFit: 'contain', height: 'auto' }} />
          <Typography>{alertData?.alert_obj?.camera_name || '-'}</Typography>
        </Stack>

        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("CameraName")}</Grid>
                <Grid item xs={7}>{alertData?.alert_obj?.camera_name ? alertData?.alert_obj?.camera_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("AlertMessage")}</Grid>
                <Grid item xs={7}>{alertData?.alert_obj?.alert_message ? alertData?.alert_obj.alert_message : "-"}</Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("LICENSE_PLATE")}</Grid>
                <Grid item xs={7}>{alertData?.lpr_number ? alertData?.lpr_number : "-"}
                  <img src={alertData?.lnp_frame} style={{ width: "100px", objectFit: 'contain', height: '50px' }} />

                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  {t("LICENSE_PLATE")}
                </Grid>
                <Grid item xs={7} container alignItems="center">
                  <Typography variant="body1">
                    {alertData?.lpr_number ? alertData?.lpr_number : "-"}
                  </Typography>
                  {alertData?.lnp_frame && (
                    <img
                      src={alertData.lnp_frame}
                      alt="License Plate Frame"
                      style={{
                        width: "100px",
                        height: "50px",
                        objectFit: "contain",
                        marginLeft: "16px", // Adds some space between the text and image
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("EventType")}</Grid>
                <Grid item xs={7}>{alertData?.alert_obj?.camera_events_name ? alertData?.alert_obj?.camera_events_name : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("LocationName")}</Grid>
                <Grid item xs={7}>{alertData?.alert_obj?.location_name ? alertData?.alert_obj?.location_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("OrganizationName")}</Grid>
                <Grid item xs={7}>{alertData?.alert_obj?.organization_name ? alertData?.alert_obj?.organization_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Date&Time")} </Grid>
                <Grid item xs={7}>{moment(alertData?.created_at).format("DD-MM-YYYY HH:mm:ss")}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
}

export default ViewLpr;
