import React, { useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button, Modal } from '../../Component/Commons'
import { useTranslation } from "react-i18next";

const ViewOrganization = ({ handleClose, modalOpen, closeFlag }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: ""
  });
  const response = modalOpen.data.totalData;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("DetailsOfOrganization")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("OrganizationName")}</Grid>
                <Grid item xs={7}>{response?.org_name ? response?.org_name : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("UserName")}</Grid>
                <Grid item xs={7}>
                  {response.organization_user && response.organization_user.length > 0 && response.organization_user.map((res) => {
                    return (
                      <> {res.first_name} {res.last_name}</>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Address")}</Grid>
                <Grid item xs={7}>{response?.org_address ? response?.org_address : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Email")}</Grid>
                <Grid item xs={7}>{response?.org_email ? response?.org_email : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Phone")}</Grid>
                <Grid item xs={7}>{response?.org_phone ? response?.org_phone : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("City")}</Grid>
                <Grid item xs={7}>{response?.org_city ? response?.org_city : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("State")}</Grid>
                <Grid item xs={7}>{response?.org_state ? response?.org_state : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Country")}</Grid>
                <Grid item xs={7}>{response?.org_country ? response?.org_country : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Zipcode")}</Grid>
                <Grid item xs={7}>{response?.org_zipcode ? response?.org_zipcode : "-"}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t("Edit")} size='small' onClick={() =>
          setIsModalOpen({
            open: true,
            currentComponent: "addOrganization",
            data: {
              totalData: response,
            },
            isEdit: true
          })
        } />
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={() => [closeFlag(), handleClose()]}
      />
    </>
  )
}


export default ViewOrganization
