import {
  dashboard,
  dashboardActive,
  event,
  eventActive,
  camera,
  cameraActive,
  alert,
  alertActive,
  report,
  reportActive,
  location1,
  locationActive,
  roi,
  roiActive,
  area,
  areaActive,
  help,
  help_active,
  queue_icon,
  queue_active,
  queue,
  demographicanalysis,
  demographicanalysis_active,
  safety,
  safety_active,
  Interaction,
  Interaction_active,
  analytics,
  analytics_active,
  heatmap,
  heatmap_active,
  FireDetections,
  FireDetections_active,
  FallDetections,
  FallDetections_active,
  DangerDetections,
  DangerDetections_active,
  IntrusionDetections,
  IntrusionDetections_active,
  visitor,
  visitor_active,
} from "./Constant";

import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { IoAnalyticsOutline, IoSettingsOutline } from "react-icons/io5";
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { LuCodesandbox } from "react-icons/lu";
import { useTranslation } from "react-i18next";


export const getStatus = (value) => {

  const status = {
    false: {
      title: "InActive",
      styles: {
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
      },
    },
    true: {
      title: "Active",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
    },
  };
  return status[value];
};

export const SidebarList = [
  {
    name: "Dashboard",
    icon: dashboard,
    activeIcon: dashboardActive,
    path: "/dashboard",
  },
  {
    name: "Alert",
    icon: alert,
    activeIcon: alertActive,
    path: "/alert",
  },
  {
    name: "Report",
    icon: report,
    activeIcon: reportActive,
    path: "/report",
  },
  {
    name: "Visitor",
    icon: visitor,
    activeIcon: visitor_active,
    path: "/visitor",
  },
  {
    name: "Fire Detections",
    icon: FireDetections,
    activeIcon: FireDetections_active,
    path: "/fire-detections",
  },
  {
    name: "Fall Detections",
    icon: FallDetections,
    activeIcon: FallDetections_active,
    path: "/fall-detections",
  },
  {
    name: "Danger Detections",
    icon: DangerDetections,
    activeIcon: DangerDetections_active,
    path: "/danger-detections",
  },
  {
    name: "Intrusion Detections",
    icon: IntrusionDetections,
    activeIcon: IntrusionDetections_active,
    path: "/intrusion-detections",
  },
  // {
  //   name: "Help & Support",
  //   icon: help,
  //   activeIcon: help_active,
  //   path: "/help-support",
  // },

  {
    name: "Configuration",
    iconr: <IoSettingsOutline style={{ color: "#fff", fontSize: "18px" }} />,
    activeIconr: (
      <IoSettingsOutline style={{ color: "#fff", fontSize: "18px" }} />
    ),
    // path: "/userManagement",
    role: ["super-admin"],
    configsubmenus: [
      {
        name: "Camera",
        icon: camera,
        activeIcon: cameraActive,
        path: "/camera",
      },
      {
        name: "Location",
        icon: location1,
        activeIcon: locationActive,
        path: "/location",
      },
      {
        name: "ROI",
        icon: roi,
        activeIcon: roiActive,
        path: "/roi",
      },
      {
        name: "Area",
        icon: area,
        activeIcon: areaActive,
        path: "/area",
      },
      {
        name: "User Management",
        icon: help,
        activeIcon: help_active,
        path: "/user-management",
      },
      {
        name: "Event",
        icon: event,
        activeIcon: eventActive,
        path: "/event",
      },
      {
        name: "Organization",
        icon: help,
        activeIcon: help_active,
        path: "/organization",
      },
    ],
  },
  {
    name: "Face Reg",
    iconr: <FaRegFaceSmileBeam style={{ color: "#fff", fontSize: "18px" }} />,
    activeIconr: (
      <FaRegFaceSmileBeam style={{ color: "#fff", fontSize: "18px" }} />
    ),
    role: ["super-admin"],
    facesubmenus: [
      {
        name: "Dashboard",
        icon: dashboard,
        activeIcon: dashboardActive,
        path: "/face-reg/dashboard",
      },
      {
        name: "Registration",
        icon: help,
        activeIcon: help_active,
        path: "/face-reg/registration",
      },
      {
        name: "Attendance",
        icon: event,
        activeIcon: eventActive,
        path: "/face-reg/attendance",
      },
      {
        name: "Reports",
        icon: report,
        activeIcon: reportActive,
        path: "/face-reg/reports",
      },
    ],
  },
  {
    name: "Retail Features",
    iconr: <IoSettingsOutline style={{ color: "#fff", fontSize: "18px" }} />,
    activeIconr: (
      <IoSettingsOutline style={{ color: "#fff", fontSize: "18px" }} />
    ),
    role: ["super-admin"],
    retailfeatures: [
      {
        name: "Dashboard",
        icon: dashboard,
        activeIcon: dashboardActive,
        path: "/retailfeatures/dashboard",
      },
      {
        name: "Traffic Analysis",
        icon: analytics,
        activeIcon: analytics_active,
        path: "/retailfeatures/trafficanalysis",
      },
      {
        name: "Heat Mapping",
        icon: heatmap,
        activeIcon: heatmap_active,
        path: "/retailfeatures/heatmapping",
      },
      {
        name: "Queue Management",
        icon: queue,
        activeIcon: queue_active,
        path: "/retailfeatures/queuemanagement",
      },
      {
        name: "Demographic Analysis",
        icon: demographicanalysis,
        activeIcon: demographicanalysis_active,
        path: "/retailfeatures/demographicanalysis",
      },
      // {
      //   name: "Interaction Analysis",
      //   icon: Interaction,
      //   activeIcon: Interaction_active,
      //   path: "/retailfeatures/interactionanalysis",
      // },
      {
        name: "Safety and Compliance",
        icon: safety,
        activeIcon: safety_active,
        path: "/retailfeatures/safety&compliance",
      },
    ],
  },
];

export const roleList = [
  { name: "Manager", value: "Manager" },
  { name: "Executive", value: "Executive" },
];

export const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
