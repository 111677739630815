import React, { useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button,  Modal } from '../../Component/Commons'
import moment from 'moment'
import { useTranslation } from "react-i18next";

const ViewArea = ({ handleClose, modalOpen,closeFlag }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: ""
  });
  const { t } = useTranslation();

  const response = modalOpen?.data?.totalData;
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
      {t("DetailsofArea")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("OrganizationName")}</Grid>
                <Grid item xs={7}>{response.org_name ? response.org_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Location")}</Grid>
                <Grid item xs={7}>{response.loc_name ? response.loc_name : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("AreaName")}</Grid>
                <Grid item xs={7}>{response.area_name ? response.area_name : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Address")}</Grid>
                <Grid item xs={7}>{response.area_address ? response.area_address : "-" }</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Time&Date")}</Grid>
                <Grid item xs={7}>{response.created_at ? moment(response.created_at).format("DD-MM-YYYY HH:mm:ss") : '-'}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName='Edit' size='small' onClick={() =>
            setIsModalOpen({
              open: true,
              currentComponent: "addEditArea",
              isEdit: true,
              data: {
                totalData: response,
              },
            })
          } />
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } 
        closeFlag={()=>closeFlag()}
        />
    </>
  )
}

export default ViewArea