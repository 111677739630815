import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Menu,
  MenuItem,
  Typography,
  Grid,
  Collapse,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../Helper/Theme";
import { Button, Input, Modal } from "../../../Component/Commons";
import {
  VscSettings,
  RiSearchLine,
  BsFiletypeCsv,
  BsFiletypeExe,
} from "../../../Helper/Icons";
import { reportView } from "../../../Helper/Constant";

const Report = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [exportFile, setExportFile] = useState(null);
  const handleClickProfile = (event) => {
    setExportFile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setExportFile(null);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search for Report"
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button buttonName="Export" onClick={handleClickProfile} />
          <Button
            buttonName={
              <VscSettings
                style={{ fontSize: "19px", margin: "4px 0" }}
                onClick={handleFilterClick}
              />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
          />
        </Stack>
        <Menu
          anchorEl={exportFile}
          open={Boolean(exportFile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeCsv style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>csv</div>
            </Stack>
          </MenuItem>
          <MenuItem
            value="excel"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeExe style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>excel</div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
        {/* filter */}
        <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
                <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Name:
            </Typography>
            <Input placeholder="Enter Name" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Email:
            </Typography>
            <Input placeholder="Search  Email" type="text" />
          </Grid>
    
       
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Start Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              End Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Check in:
            </Typography>
            <Input type="time" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Check out:
            </Typography>
            <Input type="time" />
          </Grid>
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Check in</StyledTableCell>
              <StyledTableCell>Check out</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 1, 1, 1].map((data, i) => {
              return (
                <StyledTableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <img src={reportView} width={50} alt="reportView" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    15-01-2024
                  </TableCell>
                  <TableCell component="th" scope="row">
                    John Wick
                  </TableCell>
                  <TableCell component="th" scope="row">
                    johnwick@gmail.com
                  </TableCell>
                  <TableCell component="th" scope="row">
                    12:05:57
                  </TableCell>
                  <TableCell component="th" scope="row">
                    07:55:38
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
    </>
  );
};

export default Report;
