import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { getOrgDataHandler } from "../../service/Organization.service";
import { event } from "../../Helper/Constant";
import { userByIds } from "../../service/User.service";
import { useFormik } from "formik";
import { addLocationIv } from "../../Helper/intialValues";
import { locationValidation } from "../../Helper/validation";
import { createLocation, updateLocation } from "../../service/location.service";
import { errorNotification, successNotification } from "../../Helper/Notification";
import { useTranslation } from "react-i18next";

const AddLocation = ({ handleClose, modalOpen, closeFlag }) => {
  const { t } = useTranslation();
  const [orgName, setOrgName] = useState([]);
  const [selectOrgName, setSelectOrgName] = useState([]);
  const [orgInputValue, setOrgInputValue] = useState("");

  //
  const [userName, setUserName] = useState([]);
  const [selectUserName, setSelectUserName] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");

  //
  const [orgId, setOrgId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [areaId, setareaId] = useState("");
  const [cameraId, setCameraId] = useState("");
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getOrgListHandler();
  }, []);

  const response = modalOpen?.data?.totalData;


  useEffect(() => {
    if (modalOpen.isEdit) {
      getDetails(modalOpen?.data?.totalData);
    }
  }, []);

  const getDetails = (data) => {
    const userId =
      data.location_user_details &&
      data.location_user_details.map((response) => {
        return response.id
      });

    const userValue = data.location_user_details &&
      data.location_user_details.map((response) => {
        const fullName = `${response.first_name} ${response.last_name}`
        return {
          label: fullName,
          id: response.id
        }
      });
    setSelectUserName(userValue);
    setSelectOrgName(data.org_name)
    formik.setValues({
      ...formik.values,
      org_id: data.organization_id,
      loc_name: data.loc_name,
      loc_address: data.loc_address,
      longitude: data.longitude,
      latitude: data.latitude,
      user_ids: userId,
    });
  };



  const userDataHandler = async (orgId, locationId, areaId, cameraId) => {
    const { count, data, message, success, totalPage } = await userByIds(
      orgId,
      locationId,
      areaId,
      cameraId
    );
    if (success) {
      const userData =
        data &&
        data.map((res) => {
          const fullName = `${res.first_name} ${res.last_name}`;
          return {
            label: fullName,
            id: res.id,
          };
        });
      setUserName(userData);
    } else {
      setUserName([]);
    }
  };

  const getOrgListHandler = async () => {
    const { count, data, message, success, totalPage } =
      await getOrgDataHandler("")
    if (success) {
      const orgData =
        data &&
        data.map((res) => {
          return {
            label: res.org_name,
            id: res.id,
          };
        });
      setOrgName(orgData);
    } else {
      setOrgName([]);
    }
  };

  const orgSelectHandler = () => { };

  const userSelectHandler = () => { };

  const submitHandler = async () => {
    try {
      setLoading(true)
      const { data, message, success } = modalOpen.isEdit ? await updateLocation(response.id, formik.values) : await createLocation({
        ...formik.values,
        longitude: parseFloat(formik.values.longitude),
        latitude: parseFloat(formik.values.latitude)
      });
      if (success) {
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (error) {
      errorNotification(error);
    } finally {
      setLoading(false);
      handleClose();
      closeFlag();
    }
  };

  const formik = useFormik({
    initialValues: addLocationIv,
    validationSchema: locationValidation,
    onSubmit: submitHandler,
  });


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? `${t("Edit")}` : `${t("Add")}`} {t("Location")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={selectOrgName}
              options={orgName}
              onChange={(event, value) => {
                formik.setFieldValue("org_id", value?.id);
                userDataHandler(value?.id, locationId, areaId, cameraId);
                // when Data Change
                setSelectUserName([]);
                formik.setFieldValue("user_ids", []);
                // when Data Change End
              }}
              inputValue={orgInputValue}
              onInputChange={(event, newInputValue) => {
                setOrgInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("OrganizationList")}
                  onChange={(event) => orgSelectHandler(event.target.value)}
                />
              )}
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.org_id}</FormHelperText>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Input placeholder="Organization Name" />
          </Grid> */}
          <Grid item xs={12}>
            <Input
              placeholder={t("LocationName")}
              name="loc_name"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("LocationAddress")}
              name="loc_address"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("Longitude")}
              errorDisplay={errorDisplayFlag}
              formik={formik}
              name="longitude"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("Latitude")}
              errorDisplay={errorDisplayFlag}
              formik={formik}
              name="latitude"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={userName}
              value={selectUserName || []}
              getOptionLabel={(option) => (option ? option.label : "")}
              onChange={(event, value) => {
                const selectedUserIds = value.map((value) => value.id);
                setSelectUserName(value);
                formik.setFieldValue("user_ids", selectedUserIds);
              }}
              inputValue={userInputValue}
              onInputChange={(event, newInputValue) => {
                setUserInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("UserList")}
                  onChange={(event) => userSelectHandler(event.target.value)}
                />
              )}
              multiple
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.user_ids}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={modalOpen.isEdit ? `${t("Save")}` : `${t("Add")}`}
          size="small"
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
        />
        <Button
          buttonName={t("Cancel")}
          color="secondary"
          size="small"
          style={{ color: "#fff", borderColor: "#2c2c2c" }}
          variant="outlined"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default AddLocation;
