import { Box, CircularProgress, Stack, Typography, circularProgressClasses } from '@mui/material'
import React from 'react'

const AnalyticsChart = ({ progressValue, name, color }) => {
    return (
        <Stack alignItems={'center'} gap={1}>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: 'fit-content' }}>
                <Box sx={{ position: "relative", height: '100px' }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: '#212121'
                        }}
                        size={100}
                        thickness={10}
                        value={100}
                    />
                    <CircularProgress
                        variant="determinate"
                        disableShrink
                        sx={{
                            color: color,
                            position: "absolute",
                            left: 0,
                            borderRadius: '50%',
                            // [`& .${circularProgressClasses.circle}`]: {
                            //     // strokeLinecap: "round"
                            // }
                        }}
                        size={100}
                        thickness={10}
                        value={progressValue}
                    />
                </Box>
                <Typography color="white" fontSize={'18px'} sx={{ position: 'absolute', left: '0', right: '0', textAlign: 'center' }}>
                    {`${Math.round(progressValue)}%`}
                </Typography>
            </div>
            <Typography color="white" fontSize={'13px'} fontWeight={'400'} sx={{ textAlign: 'center' }}>
                {name}
            </Typography>
        </Stack>
    )
}

export default AnalyticsChart