import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Collapse,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  VscSettings,
  RiSearchLine,
  GoDotFill,
  BsFiletypeCsv,
  BsFiletypeExe,
} from "../../../Helper/Icons";
import Customercountchart from "./CustomerCountChart";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../Helper/Theme";
import HeatmapChart from "./Heatmap";
import { Button, Input, Modal } from "../../../Component/Commons";

const HeatMapping = () => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [exportFile, setExportFile] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const handleCloseProfile = () => {
    setExportFile(null);
  };
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const handleClickProfile = (event) => {
    setExportFile(event.currentTarget);
  };
  const trafficarealocationdata = [
    {
      img: "https://images.unsplash.com/photo-1677508261025-b68bbaf78796?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Entrance",
    },
    {
      img: "https://media.istockphoto.com/id/1223522724/photo/stay-sane-stay-well.webp?s=170667a&w=0&k=20&c=2Vw4r2iRvAFAu9iIAldYlRP5NaduxzNgFu98ds4xSWQ=",
      title: "Men's Clothing",
    },
    {
      img: "https://images.unsplash.com/photo-1676375542709-9d5010dc86eb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fHw%3D",
      title: "Women's Clothing",
    },
    {
      img: "https://images.unsplash.com/photo-1679966395114-671e12f3a54d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHx8",
      title: "Checkout",
    },
  ];
  const trafficdata = [
    { location: "Entrance", entrycount: 23, exitcount: 12 },
    { location: "Men's Clothing", entrycount: 30, exitcount: 23 },
    { location: "Women's Clothing", entrycount: 22, exitcount: 15 },
    { location: "Checkout", entrycount: 30, exitcount: 20 },
  ];
  return (
    <>
      <Stack container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
            <div style={{ alignItems: "center" }}>
              <Typography mb={1}>High-Traffic Area Identification</Typography>
              <Stack
                direction="row"
                className="table_search_head"
                justifyContent="flex-end"
                width="100%"
                sx={{
                  marginBottom: { xs: "12px" },
                  marginTop: { xs: "-38px" },
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  gap: "8px",
                  alignItems: { xs: "end", sm: "center" },
                }}
              >
                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                  <Button buttonName="Export" onClick={handleClickProfile} />
                </Stack>
              </Stack>
            </div>
            <Customercountchart />
            <div style={{ marginTop: "20px" }}>
              <span>High Traffic Area</span>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  // justifyContent: "space-around",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {trafficarealocationdata.map((location, index) => (
                  <Card
                    key={index}
                    sx={{
                      backgroundColor: "black",
                      padding: "10px",
                      minWidth: 250,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <img
                        component="img"
                        height="50px"
                        width="50px"
                        src={location.img}
                        alt="green iguana"
                        style={{ borderRadius: "25px" }}
                      />

                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "300",
                          color: "white",
                        }}
                      >
                        {location.title}
                      </span>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <span>Today's Top location</span>
              <div style={{ marginTop: "20px" }}>
                <Stack
                  direction="row"
                  className="table_search_head"
                  justifyContent="space-between"
                  width="100%"
                  sx={{
                    marginBottom: { xs: "12px" },
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    gap: "8px",
                    alignItems: { xs: "end", sm: "center" },
                  }}
                >
                  <Box className="search_field">
                    <Input
                      hiddenLabel
                      placeholder="Search for Location"
                      iconInput={<RiSearchLine style={{ color: "#292929" }} />}
                      style={{
                        width: { xs: "100%", sm: "300px" },
                        "& .MuiInputBase-root": {
                          border: "1px solid #151515 !important",
                          input: {
                            background: "#151515 !important",
                            padding: "8px 15px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <Button buttonName="Export" onClick={handleClickProfile} />
                    <Button
                      buttonName={
                        <VscSettings
                          style={{ fontSize: "19px", margin: "4px 0" }}
                        />
                      }
                      style={{ minWidth: "25px", padding: "6px 13px" }}
                      onClick={handleFilterClick}
                    />
                  </Stack>
                  <Menu
                    anchorEl={exportFile}
                    open={Boolean(exportFile)}
                    onClose={handleCloseProfile}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      value="csv"
                      onClick={() =>
                        setIsModalOpen({
                          open: true,
                          currentComponent: "exportSuccess",
                        })
                      }
                      onMouseUp={handleCloseProfile}
                    >
                      <Stack
                        flexDirection={"row"}
                        gap={"10px"}
                        alignItems={"center"}
                      >
                        <BsFiletypeCsv style={{ fontSize: "18px" }} />
                        <div style={{ display: "flex" }}>csv</div>
                      </Stack>
                    </MenuItem>
                    <MenuItem
                      value="excel"
                      onClick={() =>
                        setIsModalOpen({
                          open: true,
                          currentComponent: "exportSuccess",
                        })
                      }
                      onMouseUp={handleCloseProfile}
                    >
                      <Stack
                        flexDirection={"row"}
                        gap={"10px"}
                        alignItems={"center"}
                      >
                        <BsFiletypeExe style={{ fontSize: "18px" }} />
                        <div style={{ display: "flex" }}>excel</div>
                      </Stack>
                    </MenuItem>
                  </Menu>
                </Stack>
                {/* filter */}
                <Collapse in={showFilter}>
                  <Stack
                    mb={"12px"}
                    sx={{ background: "#151515" }}
                    flexDirection={"row "}
                    gap={2}
                    flexWrap={"wrap"}
                    justifyContent={"flex-end"}
                    p={1}
                    alignItems={"center"}
                  >
                    <Grid className="filterinput">
                      <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                        Location:
                      </Typography>
                      <Input placeholder="Search  Location" type="text" />
                    </Grid>
                    <Grid className="filterinput">
                      <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                        Total Entry:
                      </Typography>
                      <Input placeholder="Enter Total Entry" type="text" />
                    </Grid>
                    <Grid className="filterinput">
                      <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                        Total Exit:
                      </Typography>
                      <Input placeholder="Enter Total Exit" type="text" />
                    </Grid>

                    <Box sx={{ mx: 1 }}>
                      <VscSettings
                        style={{ fontSize: "25px", cursor: "pointer" }}
                      />
                    </Box>
                  </Stack>
                </Collapse>
                <TableContainer
                  component={Paper}
                  className={classes.cardContainer}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Location</StyledTableCell>
                        <StyledTableCell>Entry Count</StyledTableCell>
                        <StyledTableCell>Exit Count</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trafficdata.map((row, index) => (
                        <StyledTableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.location}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.entrycount}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.exitcount}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Box>
        </Grid>
      </Stack>
      <div style={{ marginTop: "20px" }}>
        <HeatmapChart />
      </div>
    </>
  );
};

export default HeatMapping;
