import React from "react";
import Chart from "react-google-charts";

export const data = [
  [
    "Entry",
    "Visiter",
  ],
  ["10:00-AM", 50,],
  ["11:00-AM", 40,],
  ["12:00-PM", 60,],
  ["13:00-PM", 10],
  ["14:00-PM", 20],
  ["15:00-PM", 22],
  ["16:00-PM", 10],
  ["17:00-PM", 55],


];

export const options = {

  backgroundColor: {
    fill: '#151515',  
  },
  hAxis: {
    textStyle: { color: '#FFFFFF' }  
  },
  vAxis: {
    textStyle: { color: '#FFFFFF' }  
  },
  legend: {
    textStyle: { color: '#FFFFFF' }  
  },
  chartArea: { width: '90%', height: '80%' },
  legend: 'none',

};

const LineChartofVisiter = () => {
  return (
    <div>
      <Chart
        chartType="LineChart"
        height="400px"
        width="100%"
        data={data}
        options={options}
      />
    </div>
  );
};

export default LineChartofVisiter;
