import * as Yup from "yup";

export const loginValidation = Yup.object({
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  password: Yup.string().trim().required("Password is required"),
});

export const addUserValidation = Yup.object({
  first_name: Yup.string().trim().required("FirstName is required"),
  last_name: Yup.string().trim().required("LastName is required"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
      "Password must have minimun 8 characters and maximum 50 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  group: Yup.string().required("Role is required"),
});

export const updateUserValidation = Yup.object({
  first_name: Yup.string().trim().required("FirstName is required"),
  last_name: Yup.string().trim().required("LastName is required"),
  group: Yup.string().required("Role is required"),
});

export const updateUserProfileValidation = Yup.object({
  first_name: Yup.string().trim().required("FirstName is required"),
  last_name: Yup.string().trim().required("LastName is required"),
  email: Yup.string().required("Email is required"),
});

export const updateAdminPass = Yup.object({
  old_password: Yup.string()
    .required("Old Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
      "Password must have minimun 8 characters and maximum 50 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  new_password: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
      "Password must have minimun 8 characters and maximum 50 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  confirm_pass: Yup.string()
    .required("Confiem new password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
      "Password must have minimun 8 characters and maximum 50 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
});

export const changePasswordValidation = Yup.object({
  old_password: Yup.string().required("oldPassword is required"),
  new_password: Yup.string()
    .required("new Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
      "Password must have minimun 8 characters and maximum 50 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  new_confirmPassword: Yup.string()
    .required("confrim Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
      "Password must have minimun 8 characters and maximum 50 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
});

export const addEventValidation = Yup.object({
  locationId: Yup.string().trim().required("Location selection is required"),
  areaId: Yup.string().trim().required("Area selection is required"),
  cameraId: Yup.string().trim().required("Camera selection is required"),
  camera_event: Yup.array().min(1, "At least one event is required"),
  organizationId: Yup.string()
    .trim()
    .required("Organization selection is required"),
});

export const addOrganizationValidation = Yup.object({
  user_ids: Yup.array().min(1, "At least one user is required"),
  org_name: Yup.string().trim().required("Organization name is required"),
  org_address: Yup.string().trim().required("Organization address is required"),
  org_city: Yup.string().trim().required("Organization city is required"),
  org_state: Yup.string().trim().required("Organization state is required"),
  org_country: Yup.string().trim().required("Organization country is required"),
  org_zipcode: Yup.string().trim().required("Organization zipcode is required"),
  org_phone: Yup.string()
    .trim()
    .matches(/^\d{10}$/, "Organization phone must be a 10-digit number")
    .test(
      "is-number",
      "Organization phone must be a numeric value",
      (value) => {
        const numericValue = Number(value);
        return !isNaN(numericValue);
      }
    )
    .required("Organization phone is required"),
  org_email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
});

export const locationValidation = Yup.object({
  org_id: Yup.string().trim().required("organization is required"),
  loc_name: Yup.string().trim().required("Location Name is required"),
  loc_address: Yup.string().trim().required("Address is required"),
  longitude: Yup.string().trim().required("longitude is required"),
  latitude: Yup.string().trim().required("latitude is required"),
  user_ids: Yup.array().min(1, "At least one User is required"),
});

export const areaValidation = Yup.object({
  org_id: Yup.string().trim().required("organization is required"),
  loc_id: Yup.string().trim().required("location is required"),
  area_name: Yup.string().trim().required("Area Name is required"),
  area_address: Yup.string().trim().required("Address is required"),
  user_ids: Yup.array().min(1, "At least one User is required"),
});

export const cameraValidation = Yup.object({
  organization_id: Yup.string().trim().required("Organization is required"),
  location_id: Yup.string().trim().required("Location is required"),
  area_id: Yup.string().trim().required("Area is required"),
  camera_name: Yup.string().trim().required("Camera Name is required"),
  // fps: Yup.string()
  //   .matches(/^[0-9]+$/, "Only numbers are acceptable")
  //   .trim()
  //   .required("Fps is required"),
  // ip_address: Yup.string().trim().required("Ip Address is required"),
  rtsp_url: Yup.string().trim().required("Rtsp Url is required"),
  // frame_numbers: Yup.string()
  //   .matches(/^[0-9]+$/, "Only numbers are acceptable")
  //   .trim()
  //   .required("Frame number is required"),
  // port: Yup.string()
  //   .matches(/^[0-9]+$/, "Only numbers are acceptable")
  //   .trim()
  //   .required("Port number is required"),
  user_ids: Yup.array().min(1, "At least one User is required"),
});

export const addRoiValidation = Yup.object({
  camera_id: Yup.string().trim().required("Camera is required"),
  // event_id: Yup.array().min(1, "At least one event is required"),
  roi_name: Yup.string().trim().required("Roi Name is required"),
  event: Yup.array().min(1, "At least one event is required"),
  // roi_points: Yup.array().min(1, "At least one roi point is required"),
});
