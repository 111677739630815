// App.js
import React from 'react';
import { Chart } from 'react-google-charts';

const data = [
  ['Gender', 'Count'],
  ['Male', 60],
  ['Female', 40],
];

const options = {
  pieHole: 0.4,
  backgroundColor: {
    fill: "#151515",
  },
  hAxis: {
    textStyle: { color: "#FFFFFF" },
  },
  vAxis: {
    textStyle: { color: "#FFFFFF" },
  },
  legend: {
    enabled: true,
    labels: {
      colors: '#FFF',
    },
  },
  legend: "none",
};

function DashboardPiechart() {
  return (
    <div style={{
        color: "black",
        width: "100%",
        overflow: "hidden",
      }}>
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        height={"300px"}
      />
    </div>
  );
}

export default DashboardPiechart;
