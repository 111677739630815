import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Popover,
  Typography,
  Grid,
  Collapse,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../Helper/Theme";
import { Button, Input } from "../../../Component/Commons";
import { VscSettings, RiSearchLine } from "../../../Helper/Icons";
import { reportView } from "../../../Helper/Constant";
import worker from "../../../assets/image/worker.jpg";

const Aleart = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const [anchorElLinked, setAnchorElLinked] = useState(null);
  const [selectedLinked, setSelectedLinked] = useState(null);

  const handlePopoverOpenLinked = (event, linkedItem) => {
    setAnchorElLinked(event.currentTarget);
    setSelectedLinked(linkedItem);
  };

  const handlePopoverCloseLinked = () => {
    setAnchorElLinked(null);
    setSelectedLinked(null);
  };

  const openLinked = Boolean(anchorElLinked);

  const data = [
    {
      user: "Alex",
      image: reportView,
      date: "10/05/2024",
      entry: "12:48:09",
      exit: "-",
      linked: [
        { name: "LeoLarsd", image: worker },
        { name: "Alex", image: reportView },
        { name: "Sofia arebi", image: worker },
      ],
      comment: "not exit",
    },
  ];

  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search for Alert"
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              User:
            </Typography>
            <Input placeholder="Enter User" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Date:
            </Typography>
            <Input  type="date" />
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Entry:
            </Typography>
            <Input type="time" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Exit:
            </Typography>
            <Input type="time" />
          </Grid>
         
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>User</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Entry</StyledTableCell>
              <StyledTableCell>Exit</StyledTableCell>
              <StyledTableCell>Linked</StyledTableCell>
              <StyledTableCell>Comment</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <StyledTableRow
                key={rowIndex}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <StyledTableCell>{row.user}</StyledTableCell>
                <StyledTableCell>
                  <img src={row.image} alt="User" style={{ width: "50px" }} />
                </StyledTableCell>
                <StyledTableCell>{row.date}</StyledTableCell>
                <StyledTableCell>{row.entry}</StyledTableCell>
                <StyledTableCell>{row.exit}</StyledTableCell>
                <StyledTableCell
                  aria-owns={
                    openLinked ? "mouse-over-popover-linked" : undefined
                  }
                  aria-haspopup="true"
                  onMouseLeave={handlePopoverCloseLinked}
                  className="tabletext"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "150px",
                    }}
                  >
                    {row.linked instanceof Array &&
                      row.linked.map((linked, index) => (
                        <div
                          key={index}
                          onMouseEnter={(event) =>
                            handlePopoverOpenLinked(event, index)
                          }
                        >
                          <Chip label={linked.name} />
                          <Popover
                            id="mouse-over-popover-linked"
                            sx={{ pointerEvents: "none" }}
                            anchorEl={anchorElLinked}
                            open={openLinked && selectedLinked === index}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverCloseLinked}
                            disableRestoreFocus
                          >
                            <Box sx={{ p: 1 }}>
                              <img
                                src={linked.image}
                                width={250}
                                alt={`Linked Detail for ${linked.name}`}
                              />
                            </Box>
                          </Popover>
                        </div>
                      ))}
                  </div>
                </StyledTableCell>
                <StyledTableCell>{row.comment}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Aleart;
