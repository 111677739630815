import { Box, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../Helper/Icons";
import { CameraView } from "../../Helper/Constant";
import moment from "moment";
import { useTranslation } from "react-i18next";
import worker from '../../assets/image/worker.jpg'

function Model_PPE_KIT({ selectedAlert, handleClose }) {

  const [alertData, setAlertData] = useState({})

  const { t } = useTranslation();



  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("Details Of PPE Kit")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon onClick={handleClose} />
      </IconButton >
      <DialogContent dividers>
        <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img src={worker} style={{ width: "350px", objectFit: 'contain', height: 'auto' }} />
          <Typography>{"Camera 1"|| '-'}</Typography>
        </Stack>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("ID")}</Grid>
                <Grid item xs={7}>"0001"</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Location Name")}</Grid>
                <Grid item xs={7}>First Floor</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Event Type")}</Grid>
                <Grid item xs={7}>Shoes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("OrganizationName")}</Grid>
                <Grid item xs={7}>VN Constructions</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Date&Time")} </Grid>
                <Grid item xs={7}>23-08-2024 11:29:58</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
}

export default Model_PPE_KIT;
