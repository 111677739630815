import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Collapse,
  Typography,
  Grid,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import { useNavigate } from "react-router-dom";
import { GrFormAdd, VscSettings, RiSearchLine } from "../../Helper/Icons";
import { getLocationList } from "../../service/location.service";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";


const Location = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [locationId, setLocationId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchText, setSearchText] = useState("");

  const [locationData, setLocationData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  useEffect(() => {
    getAllLocationDetails(locationId, orgId, startDate, endDate, searchText);
  }, []);


  const closeFlagHandler = () => {
    getAllLocationDetails(locationId, orgId, startDate, endDate, searchText);
  };

  const getAllLocationDetails = async (
    locationId,
    orgId,
    startDate,
    endDate,
    searchText
  ) => {
    const { count, data, message, success, totalPage } = await getLocationList(
      locationId,
      orgId,
      startDate,
      endDate,
      searchText
    );
    if (success) {
      setLocationData(data);
    } else {
      setLocationData([]);
    }
  };


  const deleteHandler = (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "delete",
      isEdit: false,
      data: {
        head: `${t("RemoveLocation")}`,
        message: `${t("Do_you_want_to_Remove_this_Location_?")}`,
        id: id,
        name: "LOCATIONS",
      },
    });
  };
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const searchHandler = (event) => {
    const inputText = event.target.value;
    setSearchText(inputText);
    getAllLocationDetails(locationId, orgId, startDate, endDate, searchText);
  };
  const title = "Location -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("Searchforlocation")}
            onChange={(event) => searchHandler(event)}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          <Button
            buttonName={t("AddLocation")}
            startIcon={<GrFormAdd />}
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "addEditLocation",
                isEdit: false,
              })
            }
          />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}

          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("OrganizationName")}
            </Typography>
            <Input placeholder={t("EnterOrganizationName")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Location")}:
            </Typography>
            <Input placeholder={t("EnterLocation")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Address")}:
            </Typography>
            <Input placeholder={t("EnterAddress")} type="text" />
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("StartDate")}:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("EndDate")}:
            </Typography>
            <Input type="date" />
          </Grid>
          {/* <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Time & Date:
            </Typography>
            <Input  type="datetime-local" style={{ color: 'white' }}/>
          </Grid> */}

          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("OrganizationName")}</StyledTableCell>
              <StyledTableCell>{t("LocationName")}</StyledTableCell>
              <StyledTableCell>{t("Address")}</StyledTableCell>
              <StyledTableCell>{t("Time&Date")}</StyledTableCell>
              <StyledTableCell>{t("Action")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationData && locationData.length > 0 ? (
              locationData.map((res, i) => {
                return (
                  <StyledTableRow
                    key={i}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewLocation",
                        data: {
                          totalData: res
                        }
                      })
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {res.org_name ? res.org_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.loc_name ? res.loc_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.loc_address ? res.loc_address : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(res.created_at).format("DD-MM-YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(res.id)
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("Norecordstodisplay")}
                </TableCell>
              </TableRow>
            )}
            {/* {[1, 1, 1, 1].map((data, i) => {
              return (
                <StyledTableRow key={i}
                  onClick={() =>
                    setIsModalOpen({
                      open: true,
                      currentComponent: "viewLocation",
                    })
                  }
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell component="th" scope="row">
                    Codify Technologies
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Ahmadabad, India
                  </TableCell>
                  <TableCell component="th" scope="row">
                    B-10, Sukan Residency, Chandkheda,<br /> Sabarmati, Ahmedabad
                  </TableCell>
                  <TableCell component="th" scope="row">
                    28th Aug at 12:30 PM
                  </TableCell>
                </StyledTableRow>
              )
            })} */}
            {/* <TableRow>
              <TableCell
                sx={{
                  color: "silver",
                  textAlign: "center",
                  paddingTop: "90px",
                  borderBottom: "none",
                  fontSize: "30px",
                }}
                colSpan="6"
              >
                No records to display
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {/* <div>
          Showing{" "}
          1{" "}
          of 1 Results
        </div> */}
        {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={() => closeFlagHandler()}
      />
    </HelmetProvider>
  );
};

export default Location;