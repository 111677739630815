import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { useFormik } from "formik";
import { addAreaIv } from "../../Helper/intialValues";
import { areaValidation } from "../../Helper/validation";
import { getOrgDataHandler } from "../../service/Organization.service";
import { locationByOrgId } from "../../service/location.service";
import { userByIds } from "../../service/User.service";
import {
  createAreaHandler,
  updateAreaHandler,
} from "../../service/area.service";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { useTranslation } from "react-i18next";

const AddArea = ({ handleClose, modalOpen, closeFlag }) => {
  const { t } = useTranslation();
  const [orgName, setOrgName] = useState([]);
  const [selectOrgName, setSelectOrgName] = useState([]);
  const [orgInputValue, setOrgInputValue] = useState("");

  const [locationName, setLocationName] = useState([]);
  const [selectLocationName, setSelectLocationName] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState("");

  const [userName, setUserName] = useState([]);
  const [selectUserName, setSelectUserName] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);

  const [orgId, setOrgId] = useState("");
  const [locaId, setLocaId] = useState("");
  const [usersId, setUsersId] = useState([]);
  const [loading, setLoading] = useState(false);

  const response = modalOpen?.data?.totalData;
  const submitHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = modalOpen.isEdit
        ? await updateAreaHandler(response?.id, formik.values)
        : await createAreaHandler(formik.values);
      if (success) {
        successNotification(message);
        handleClose();
        closeFlag();
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };




  const formik = useFormik({
    initialValues: addAreaIv,
    validationSchema: areaValidation,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (modalOpen.isEdit) {
      getDetails(modalOpen?.data?.totalData);
    }
  }, []);

  const getDetails = (data) => {
    const userId = data.area_user_details && data.area_user_details.map((res) => {
      return res.id
    });

    const userData = data.area_user_details && data.area_user_details.map((res) => {
      const fullName = `${res.first_name} ${res.last_name}`;
      return {
        label: fullName,
        id: res.id
      }
    });
    setSelectUserName(userData);
    getlocationByorgId(data.organization_id, "");
    const orgData = {
      label: data.org_name,
      id: data.organization_id
    }
    setSelectOrgName(orgData);
    userDataHandler(data.organization_id, data.location_id, "", "");
    const locationData = {
      label: data.loc_name,
      id: data.location_id
    };
    setSelectLocationName(locationData);

    formik.setValues({
      ...formik.values,
      org_id: data.organization_id,
      loc_id: data.location_id,
      area_name: data.area_name,
      area_address: data.area_address,
      user_ids: userId
    })
  };

  const orgSelectHandler = () => { };

  const userSelectHandler = () => { };

  const locationSelectHandler = () => { };

  const userDataHandler = async (orgId, locationId, areaId, cameraId) => {
    const { count, data, message, success, totalPage } = await userByIds(
      orgId,
      locationId,
      areaId,
      cameraId
    );
    if (success) {
      const userData =
        data &&
        data.map((res) => {
          const fullName = `${res.first_name} ${res.last_name}`;
          return {
            label: fullName,
            id: res.id,
          };
        });
      setUserName(userData);
    } else {
      setUserName([]);
    }
  };

  useEffect(() => {
    getOrgListHandler("");
  }, []);

  const getlocationByorgId = async (orgId, search) => {
    // locationByOrgId
    try {
      const { count, data, message, success, totalPage } =
        await locationByOrgId(orgId, search);
      if (success) {
        const locationData =
          data &&
          data.map((res) => {
            return {
              label: res.loc_name,
              id: res.id,
            };
          });
        setLocationName(locationData);
      } else {
        setLocationName([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getOrgListHandler = async (search) => {
    const { count, data, message, success, totalPage } =
      await getOrgDataHandler(search);
    if (success) {
      const orgData =
        data &&
        data.map((res) => {
          return {
            label: res.org_name,
            id: res.id,
          };
        });
      setOrgName(orgData);
    } else {
      setOrgName([]);
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? `${t("Edit")}` : `${t("Add")}`} Area
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={selectOrgName}
              options={orgName}
              onChange={(event, value) => {
                setSelectOrgName(value);
                setOrgId(value?.id);
                formik.setFieldValue("org_id", value?.id);
                getlocationByorgId(value?.id, "");
                userDataHandler(value?.id, "", "", "");
                // location Changed
                setSelectLocationName([]);
                formik.setFieldValue("loc_id", "");
                setLocaId("");
                // 
                setSelectUserName([]);
                formik.setFieldValue("user_ids", []);

              }}
              inputValue={orgInputValue}
              onInputChange={(event, newInputValue) => {
                setOrgInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("OrganizationList")}
                  onChange={(event) => orgSelectHandler(event.target.value)}
                />
              )}
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.org_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={selectLocationName}
              options={locationName}
              onChange={(event, value) => {
                setSelectLocationName(value);
                formik.setFieldValue("loc_id", value?.id);
                userDataHandler(orgId, value?.id, "", "");
                setLocaId(value?.id);
              }}
              inputValue={locationInputValue}
              onInputChange={(event, newInputValue) => {
                setLocationInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("LocationList")}
                  onChange={(event) =>
                    locationSelectHandler(event.target.value)
                  }
                />
              )}
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.loc_id}</FormHelperText>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Input placeholder='Organization Name' />
          </Grid>
          <Grid item xs={12}>
            <Input placeholder='Location Name' />
          </Grid> */}
          <Grid item xs={12}>
            <Input
              placeholder={t("AreaName")}
              formik={formik}
              name="area_name"
              value={formik.values.area_name}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("Address")}
              formik={formik}
              name="area_address"
              errorDisplay={errorDisplayFlag}
              value={formik.values.area_address}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={userName}
              value={selectUserName || []}
              getOptionLabel={(option) => (option ? option.label : "")}
              onChange={(event, value) => {
                const selectedUserIds = value.map((value) => value.id);
                setSelectUserName(value);
                formik.setFieldValue("user_ids", selectedUserIds);
                setUsersId(selectedUserIds);
              }}
              inputValue={userInputValue}
              onInputChange={(event, newInputValue) => {
                setUserInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("UserList")}
                  onChange={(event) => userSelectHandler(event.target.value)}
                />
              )}
              multiple
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.user_ids}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={modalOpen.isEdit ? `${t("Save")}` : `${t("Add")}`}
          size="small"
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
        />
        <Button
          buttonName={t("Cancel")}
          color="secondary"
          size="small"
          style={{ color: "#fff", borderColor: "#2c2c2c" }}
          variant="outlined"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default AddArea;
