import { colors } from "@mui/material";
import { event, roi } from "./Constant";

export const LoginIv = {
    email: "",
    password: ""
};

export const addUserIv = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    group: ""
}

export const profileDataInv = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    profile_image: ""
}

export const updatePassIv = {
    old_password: "",
    new_password: "",
    confirm_pass: ""
}

export const changePasswordIv = {
    old_password: "",
    new_password: "",
    new_confirmPassword: ""
}


export const addEventIv = {
    locationValue: "",
    locationId: "",
    areaValue: "",
    areaId: "",
    cameraId: "",
    cameraValue: "",
    camera_event: [],
    organizationValue: "",
    organizationId: "",

};

export const addOrganizationIv = {
    user_ids: [],
    org_name: "",
    org_address: "",
    org_city: "",
    org_state: "",
    org_country: "",
    org_zipcode: "",
    org_phone: "",
    org_email: ""
};


export const addLocationIv = {
    org_id: "",
    loc_name: "",
    loc_address: "",
    longitude: "",
    latitude: "",
    user_ids: []
}

export const addCameraIv = {
    organization_id: "",
    location_id: "",
    area_id: "",
    camera_name: "",
    // fps: "",
    // ip_address: "",
    rtsp_url: "",
    // frame_numbers: "",
    // port: "",
    user_ids: []
}

export const addRoiIv = {
    camera_id: "",
    event_id: [],
    event: [],
    roi_points: [],
    color: "#FF0000",
    roi_name: "",
}

export const roiList = {
    camera_id: "",
    camera_event_id: "",
}




export const addAreaIv = {
    org_id: "",
    loc_id: "",
    area_name: "",
    area_address: "",
    user_ids: []
}