import React, { useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Modal } from "../../Component/Commons";
import { CameraView } from "../../Helper/Constant";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ViewCamera = ({ handleClose, modalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const response = modalOpen?.data?.totalData;
  const [isModalOpen, setIsModalOpens] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("DetailsofCamera")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img src={CameraView} style={{ width: "100px" }} />
          <Typography>
            {response.camera_name ? response.camera_name : "-"}
          </Typography>
        </Stack>
        <Box
          width={{ sm: "400px" }}
          sx={{ border: "1px solid #333", padding: "30px", color: "#fff" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Organization")}
                </Grid>
                <Grid item xs={7}>
                  {response.org_name ? response.org_name : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Location")}
                </Grid>
                <Grid item xs={7}>
                  {response.loc_name ? response.loc_name : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Area")}
                </Grid>
                <Grid item xs={7}>
                  {response.area_name ? response.area_name : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("CameraName")}
                </Grid>
                <Grid item xs={7}>
                  {response.camera_name ? response.camera_name : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("FPS")}
                </Grid>
                <Grid item xs={7}>
                  {response.fps ? response.fps : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("IPAddress")}
                </Grid>
                <Grid item xs={7}>
                  {response.ip_address ? response.ip_address : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("RTSPLink")}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={response.rtsp_url || '-'}>
                    {response.rtsp_url ? response.rtsp_url.slice(0, 20) + "..." : "-"}
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("FrameNumbers")}
                </Grid>
                <Grid item xs={7}>
                  {response.frame_numbers ? response.frame_numbers : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Port")}
                </Grid>
                <Grid item xs={7}>
                  {response.port ? response.port : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Users")}
                </Grid>
                <Grid item xs={7}>
                  {response.camera_user_details
                    ? response.camera_user_details &&
                    response.camera_user_details.map((res) => {
                      return (
                        <>
                          {" "}
                          {res.first_name} {res.last_name},
                        </>
                      );
                    })
                    : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  {t("Date&Time")}
                </Grid>
                <Grid item xs={7}>
                  {response.created_at
                    ? moment(response.created_at).format("DD-MM-YYYY HH:mm:ss")
                    : "-"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={t("Edit")}
          size="small"
          onClick={() =>
            setIsModalOpen({
              open: true,
              currentComponent: "addEditCamera",
              isEdit: true,
              data: {
                totalData: response
              }
            })
          }
        />
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
    </>
  );
};

export default ViewCamera;
