import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import ManageRole from './ManageRole';

const AddRole = (props) => {
    const { openAdd, handleClose, editId, refresh, setRefresh } = props
    const [roleName, setRoleName] = useState(editId?.group_name)
    console.log("editId", editId)

    useEffect(() => {
        if (editId && editId.group_id) {
            setRoleName(editId.group_name)
        }
    }, [editId])

    return (
        <>

            <Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '800px' } }}>
                <DialogTitle>{editId && editId.group_id ? "Edit" : "Add"} Permissions</DialogTitle>
                <DialogContent dividers >
                    {editId && editId.group_id ? <Typography fontSize={20}>Role Name : </Typography> :
                        <Typography fontSize={20}>Role Name : </Typography>}
                    <TextField
                        type="text"
                        sx={{
                            border: '1px solid #333',
                            padding: '10px',
                            borderRadius: '5px',
                            width: '100%'
                        }}
                        name="group_name"
                        placeholder="Enter Role Name"
                        onChange={(e) => setRoleName(e.target.value)}
                        value={roleName}
                    />
                    <ManageRole editId={editId} handleClose={handleClose} roleName={roleName} refresh={refresh} setRefresh={setRefresh} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddRole