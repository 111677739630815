import React from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";

const CommonButton = (props) => {
  const {
    onClick,
    buttonName,
    loading,
    fullWidth,
    variant,
    color,
    size,
    startIcon,
    endIcon,
    disabled,
    style,
  } = props;
  return (
    <>
      <Button
        variant={variant ? variant : "contained"}
        color={color ? color : "primary"}
        size={size ? size : "medium"}
        onClick={onClick}
        fullWidth={fullWidth}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
        sx={style}
      >
        <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            buttonName
          )}
        </Stack>
        {/* {buttonName} */}
      </Button>
    </>
  );
};

export default CommonButton;
