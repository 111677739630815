import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = ({ newVisitors, returningVisitors, currentVisitor }) => {
  const totalVisitors = newVisitors + returningVisitors;

  const options = {
    chart: {
      type: "donut",
    },
    labels: ["New Visitors", "Returning Visitors"],
    colors: ["#4caf50", "#ff9800"],
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              color: '#000',
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 400,
              color: '#FFF',
              offsetY: 10,
              formatter: () => `Current: ${currentVisitor}`,
            },
            total: {
              show: true,
              showAlways: true,
              label: "Total Visitors",
              fontSize: '12px',
              fontWeight: 600,
              color: '#FFF',
              formatter: () => totalVisitors,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const series = [newVisitors, returningVisitors];

  return (
    <div  style={{
      color: "black",
      width: "100%",
      overflow: "hidden",
    }}>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={"250px"}
      />
    </div>
  );
};

export default DonutChart;
