import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { StyledTableCell, StyledTableRow, getAttendnceStatus, useStyles } from '../../../Helper/Theme'
import { reportView } from '../../../Helper/Constant'

const TopEmploye = () => {
  const classes = useStyles();
  var status = getAttendnceStatus(true);
  return (
    <div>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Image
              </StyledTableCell>
              <StyledTableCell>
                Date
              </StyledTableCell>
              <StyledTableCell>
                Name
              </StyledTableCell>
              <StyledTableCell>
                Email
              </StyledTableCell>
              <StyledTableCell>
                Check in
              </StyledTableCell>
              <StyledTableCell>
                Check out
              </StyledTableCell>
              <StyledTableCell>
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 1, 1, 1].map((data, i) => {
              return (
                <StyledTableRow key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell component="th" scope="row">
                    <img src={reportView} width={50} alt='reportView' />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    15-01-2024
                  </TableCell>
                  <TableCell component="th" scope="row">
                    John Wick
                  </TableCell>
                  <TableCell component="th" scope="row">
                    johnwick@gmail.com
                  </TableCell>
                  <TableCell component="th" scope="row">
                    12:05:57
                  </TableCell>
                  <TableCell component="th" scope="row">
                    07:55:38
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Chip label={status?.title} sx={status?.styles} />
                  </TableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TopEmploye