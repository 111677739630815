import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { alertView } from "../../Helper/Constant";
import { GoDotFill } from "react-icons/go";
import { Modal } from "../../Component/Commons";
import { useTranslation } from "react-i18next";
import ViewLpr from "./ViewLpr"
import Dialog from '@mui/material/Dialog';

const AlertCard = ({ resData, modal }) => {
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        data: {},
    });
    const [alertDetailsPopup, setAlertDetailsPopup] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const { t } = useTranslation();

    const closeFlagHandler = () => {
        setIsModalOpen({ open: false, currentComponent: "", isEdit: "" });
    };
    console.log('alertDetailsPopup', alertDetailsPopup);

    const closePopupAlert = () => {
        console.log('879879879878887987');
        setAlertDetailsPopup(false)
    }

    const handleModelOpen = (id) => {
        if (id) {
            setSelectedAlert(id)
            setAlertDetailsPopup(true)
        }
    }

    return (
        <>
            <Box
                sx={{ background: "#151515", p: 2, cursor: "pointer" }}
                // onClick={() =>
                //     setIsModalOpen({
                //         open: true,
                //         currentComponent: "ViewLpr",
                //         id: resData.id,
                //     })
                // }
                onClick={() => handleModelOpen(resData.id)}
            >
                <Stack gap={1}>
                    <img
                        src={resData?.frame ? resData?.frame : alertView}
                        alt="alert_image"
                        width={"100%"}
                    // OnClick={() => handleModelOpen(resData.id)}
                    />
                    <Typography
                        fontSize={"14px"}
                        fontWeight={"400"}
                        lineHeight={"16px"}
                        mt={1}
                    >
                        <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                            {t("Event")} : {resData?.camera_events_name}
                            {/* {/ <GoDotFill / > No /} */}
                        </Stack>
                    </Typography>
                    <Typography fontSize={"14px"} fontWeight={"400"} lineHeight={"16px"}>
                        {t("Location")} : {resData?.location_name ? resData.location_name : "-"}
                    </Typography>
                </Stack>
                {/* <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
                }
                closeFlag={closeFlagHandler}
            /> */}

            </Box>
            <div>
                {
                    alertDetailsPopup && (
                        <Dialog
                            onClose={closePopupAlert}
                            aria-labelledby="Common_modal"
                            open={alertDetailsPopup}
                        >
                            <ViewLpr selectedAlert={selectedAlert} handleClose={closePopupAlert} />
                        </Dialog>
                    )
                }
            </div>
        </>
    );
};

export default AlertCard;
