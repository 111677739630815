import React from "react";
import TextField from "@mui/material/TextField";
import { Box, IconButton, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "../../Helper/Icons";

const CommonInput = (props) => {
  const {
    type,
    placeholder,
    iconInput,
    labelInput,
    value,
    disabled,
    passwordInput,
    style,
    errorDisplay,
    className,
    min
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      {labelInput && (
        <Typography fontSize={"14px"} fontWeight={600} marginBottom={"2px"}>
          {labelInput}
        </Typography>
      )}
      <Box position={"relative"}>
        <TextField
          fullWidth
          hiddenLabel
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          inputProps={{ min: min }}
          helperText={errorDisplay && props?.formik?.errors[props.name]}
          {...props?.formik?.getFieldProps(props.name)}
          {...props}
          type={
            type
              ? type
              : passwordInput
                ? showPassword
                  ? "text"
                  : "password"
                : "text"
          }
          color="primary"
          sx={style}
          className={className}
        />
        {passwordInput || (iconInput && passwordInput) ? (
          <InputAdornment
            position="end"
            sx={{
              height: "100%",
              position: "absolute",
              right: "6px",
              top: "6px",
            }}
          >
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : (
          iconInput && (
            <InputAdornment
              position="end"
              sx={{
                height: "100%",
                position: "absolute",
                right: "6px",
                top: "6px",
              }}
            >
              <IconButton> {iconInput} </IconButton>
            </InputAdornment>
          )
        )}
      </Box>
    </>
  );
};

export default CommonInput;
