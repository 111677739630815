// import React, { useRef, useState, useEffect } from "react";
// import { addRoiHandler, getImageHandler } from "../../service/Roi.service";
// import {
//   errorNotification,
//   successNotification,
// } from "../../Helper/Notification";
// import { cameraListHandler } from "../../service/Camera.service";
// import { useFormik } from "formik";
// import { addRoiValidation } from "../../Helper/validation";
// import { addRoiIv } from "../../Helper/intialValues";
// import CommonSelect from "../../Component/Commons/Select";
// import {
//   Autocomplete,
//   Box,
//   Grid,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import { getEventList } from "../../service/Event.service";
// import CommonButton from "../../Component/Commons/Button";
// import { useNavigate } from "react-router-dom";
// import CommonInput from "../../Component/Commons/Input";

// const BoxDrawer = () => {
//   const initialBoxes = [
//     // { x: 56, y: 190, width: 111, height: 170, color: 'red' },
//     // { x: 200, y: 300, width: 150, height: 100, color: 'blue' },
//     // { x: 241, y: 119, width: 56, height: 37, color: 'green' },
//   ];

//   const canvasRef = useRef(null);
//   const { t } = useTranslation();
//   const [isDrawing, setIsDrawing] = useState(false);
//   const [cameraData, setCameraData] = useState([]);
//   const [imageData, setImageData] = useState("");
//   const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
//   const [currentCoords, setCurrentCoords] = useState({ x: 0, y: 0 });
//   const [boxes, setBoxes] = useState(initialBoxes);
//   const [newBox, setNewBox] = useState(null);
//   const [image, setImage] = useState(null);
//   const [dragging, setDragging] = useState(false);
//   const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
//   const [hoveringOverClose, setHoveringOverClose] = useState(false);
//   const [eventData, setEventData] = useState([]);
//   const [style, setStyle] = useState({});
//   const Navigate = useNavigate();

//   const formik = useFormik({
//     initialValues: addRoiIv,
//     validationSchema: addRoiValidation,
//     onSubmit: async (values) => {
//       console.log("values", values);
//       const payload = {
//         camera_id: values.camera_id,
//         camera_event_ids: values.event.map((item) => item.id),
//         roi_name: values.roi_name,
//         coordinates: values.roi_points,
//         frame_number: 1,
//         color: values.color,
//         priority: 1,
//         specific_analytics: false,
//       };

//       const res = await addRoiHandler(payload);
//       if (res && res.success) {
//         successNotification(res.message);
//         Navigate("/roi");
//       } else {
//         errorNotification(res.message || "Something went wrong !");
//       }
//     },
//   });
//   console.log("eventData", formik.errors);
//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");
//     console.log("Image:Stylle", style);

//     if (image && style.width && style.height) {
//       console.log("Drawing image");
//       // Set the canvas dimensions based on the style object
//       canvas.width = style.width;
//       canvas.height = style.height;

//       // Draw the image to fill the canvas
//       context.drawImage(image, 0, 0, canvas.width, canvas.height);

//       // Draw all boxes on the canvas
//       boxes.forEach((box) => {
//         drawRect(context, box);
//       });

//       if (newBox) {
//         drawRect(context, newBox);
//       }
//     }
//   }, [image, style, boxes, newBox]);

//   const getCameraData = async () => {
//     const response = await cameraListHandler();

//     if (response && response.data) {
//       const data = response.data.map((item) => ({
//         id: item.id,
//         name: item.camera_name,
//       }));
//       setCameraData(data);
//     } else {
//       setCameraData([]);
//     }
//   };

//   useEffect(() => {
//     getCameraData();
//     getEventListData();
//   }, []);

//   const getEventListData = async () => {
//     const response = await getEventList();
//     if (response && response.success) {
//       let data = response.data.map((item) => {
//         return {
//           name: item.camera_event,
//           id: item.id,
//         };
//       });
//       setEventData(data);
//     } else {
//       setEventData([]);
//     }
//   };

//   const canceRoi = () => {
//     Navigate("/roi");
//   };

//   const getRoiImage = async (id) => {
//     const response = await getImageHandler(id);
//     if (response && response.success) {
//       setStyle({ width: response.data.width, height: response.data.height });
//       const img = new Image();
//       img.src = response.data.image;
//       img.onload = () => {
//         setImage(img);
//       };
//     } else {
//       errorNotification(response.message || "Something went wrong.");
//     }
//   };

//   const handleSelectChange = (event) => {
//     formik.setFieldValue("camera_id", event.target.value);
//     if (event.target.value) {
//       getRoiImage(event.target.value);
//     }
//   };

//   const drawRect = (context, rect) => {
//     context.strokeStyle = rect.color;
//     context.lineWidth = 2;
//     context.strokeRect(rect.x, rect.y, rect.width, rect.height);

//     const iconRadius = 10;
//     const iconX = rect.x + rect.width - iconRadius - 5;
//     const iconY = rect.y + iconRadius + 5;

//     context.fillStyle = "black";
//     context.beginPath();
//     context.arc(iconX, iconY, iconRadius, 0, 2 * Math.PI);
//     context.fill();

//     context.fillStyle = "white";
//     context.font = "12px Arial";
//     context.textAlign = "center";
//     context.textBaseline = "middle";
//     context.fillText("X", iconX, iconY);
//   };

//   const isPointInCloseIcon = (x, y, rect) => {
//     const iconRadius = 10;
//     const iconX = rect.x + rect.width - iconRadius - 5;
//     const iconY = rect.y + iconRadius + 5;

//     return (x - iconX) ** 2 + (y - iconY) ** 2 <= iconRadius ** 2;
//   };

//   const handleMouseDown = (e) => {
//     const { offsetX, offsetY } = e.nativeEvent;

//     const clickedBoxIndex = boxes.findIndex((box) =>
//       isPointInCloseIcon(offsetX, offsetY, box)
//     );

//     if (clickedBoxIndex !== -1) {
//       setBoxes((prevBoxes) =>
//         prevBoxes.filter((_, index) => index !== clickedBoxIndex)
//       );
//       console.log("Box removed:", clickedBoxIndex);
//       return;
//     }

//     if (newBox) {
//       if (
//         offsetX >= newBox.x &&
//         offsetY >= newBox.y &&
//         offsetX <= newBox.x + newBox.width &&
//         offsetY <= newBox.y + newBox.height
//       ) {
//         if (isPointInCloseIcon(offsetX, offsetY, newBox)) {
//           setNewBox(null);
//           formik.setFieldValue("roi_points", null);
//           console.log("New box removed");
//         } else {
//           setDragging(true);
//           setDragOffset({ x: offsetX - newBox.x, y: offsetY - newBox.y });
//         }
//       }
//       return;
//     }

//     if (newBox) return;

//     setIsDrawing(true);
//     setStartCoords({ x: offsetX, y: offsetY });
//   };

//   const handleMouseMove = (e) => {
//     const { offsetX, offsetY } = e.nativeEvent;
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");

//     context.clearRect(0, 0, canvas.width, canvas.height);
//     if (image) {
//       context.drawImage(image, 0, 0, canvas.width, canvas.height);
//     }

//     boxes.forEach((box) => {
//       drawRect(context, box);
//     });

//     if (newBox) {
//       drawRect(context, newBox);
//     }

//     if (isDrawing && !newBox) {
//       setCurrentCoords({ x: offsetX, y: offsetY });
//       const rect = {
//         x: startCoords.x,
//         y: startCoords.y,
//         width: offsetX - startCoords.x,
//         height: offsetY - startCoords.y,
//         color: formik.values.color,
//       };
//       drawRect(context, rect);
//     } else if (dragging && newBox) {
//       const newX = offsetX - dragOffset.x;
//       const newY = offsetY - dragOffset.y;
//       const updatedBox = { ...newBox, x: newX, y: newY };
//       setNewBox(updatedBox);
//       formik.setFieldValue("roi_points", [
//         newX,
//         newY,
//         newBox.width,
//         newBox.height,
//       ]);
//     }

//     const hovering =
//       boxes.some((box) => isPointInCloseIcon(offsetX, offsetY, box)) ||
//       (newBox && isPointInCloseIcon(offsetX, offsetY, newBox));
//     setHoveringOverClose(hovering);
//   };

//   const handleMouseUp = () => {
//     if (isDrawing && !newBox) {
//       const createdBox = {
//         x: startCoords.x,
//         y: startCoords.y,
//         width: currentCoords.x - startCoords.x,
//         height: currentCoords.y - startCoords.y,
//         color: formik.values.color,
//       };
//       setNewBox(createdBox);
//       formik.setFieldValue("roi_points", [
//         createdBox.x,
//         createdBox.y,
//         createdBox.width,
//         createdBox.height,
//       ]);
//       setIsDrawing(false);
//     } else if (dragging) {
//       setDragging(false);
//     }
//   };

//   const handleReset = () => {
//     setBoxes([]);
//     setNewBox(null);
//     formik.setFieldValue("roi_points", null);
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");

//     context.clearRect(0, 0, canvas.width, canvas.height);
//     if (image) {
//       context.drawImage(image, 0, 0, canvas.width, canvas.height);
//     }

//     boxes.forEach((box) => {
//       drawRect(context, box);
//     });
//   };

//   console.log("Boxes:", boxes, "New box:", newBox);

//   return (
//     <div>
//       <Grid container spacing={2} mb={3}>
//         <Grid item xs={12} md={6}>
//           <CommonInput
//             labelInput={t("RoiName")}
//             value={formik.values.roi_name}
//             onChange={formik.handleChange}
//             name="roi_name"
//             formik={formik}
//             variant="outlined" // Added variant for consistent styling
//           />
//         </Grid>

//         <Grid item xs={12} md={6}>
//           <CommonSelect
//             labelinput={t("SelectCamera")}
//             value={formik.values.camera_id}
//             onChange={handleSelectChange}
//             name="camera_id"
//             selectHead={t("SelectCamera")}
//             selectList={cameraData}
//             formik={formik}
//             variant="outlined" // Added variant for consistent styling
//           />
//         </Grid>

//         <Grid item xs={12}>
//           <Typography
//             fontSize="14px"
//             fontWeight={600}
//             marginBottom="8px"
//             color="white"
//           >
//             {t("Event")}
//           </Typography>

//           <Autocomplete
//             options={eventData?.filter(
//               (item) => !formik.values.event.includes(item)
//             )}
//             value={formik.values.event}
//             getOptionLabel={(option) => (option && option.name) || ""}
//             onChange={(event, value) => {
//               formik.setFieldValue("event", value);
//               setEventData(eventData.filter((item) => !value.includes(item)));
//             }}
//             style={{ backgroundColor: "#060606" }}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 // label={t("SelectEvent")}
//                 placeholder={t("Chooseanevent")}
//                 variant="outlined"
//               />
//             )}
//             multiple
//             disableCloseOnSelect
//           />
//           {formik.errors.event && formik.touched.event && (
//             <Typography color="error" variant="caption" mt={1}>
//               {formik.errors.event}
//             </Typography>
//           )}
//         </Grid>
//       </Grid>

//       <canvas
//         ref={canvasRef}
//         onMouseDown={handleMouseDown}
//         onMouseMove={handleMouseMove}
//         onMouseUp={handleMouseUp}
//         style={{
//           border: "1px solid black",
//           cursor: hoveringOverClose ? "pointer" : "crosshair",
//           // width:"100%",
//           // objectFit:'contain'
//         }}
//       />
//       <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
//         <button
//           onClick={handleReset}
//           style={{
//             padding: "8px 16px",
//             marginRight: "10px",
//             backgroundColor: "#f0f0f0",
//             border: "1px solid #ccc",
//             cursor: "pointer",
//           }}
//         >
//           {t("Reset")}
//         </button>
//         <input
//           type="color"
//           value={formik.values.color}
//           onChange={(e) => formik.setFieldValue("color", e.target.value)}
//           style={{ cursor: "pointer" }}
//         />
//       </div>
//       <Box sx={{display:"flex",justifyContent:"flex-end",gap:'10px',marginTop:"20px"}}>
//         <Grid item xs={6}>
//           <CommonButton onClick={formik.handleSubmit} buttonName={t("Save")} />
//         </Grid>
//         <Grid item xs={6}>
//           <CommonButton
//             onClick={() => {
//               canceRoi();
//             }}
//             style={{
//               backgroundColor: "#fff",
//               "&:hover": {
//                 backgroundColor: "#f0f0f0"
//               }
//             }}
//             buttonName={t("Cancel")}
//           />
//         </Grid>
//       </Box>
//     </div>
//   );
// };

// export default BoxDrawer;


import React, { useRef, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography, Autocomplete, TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CommonInput from "../../Component/Commons/Input";
import CommonSelect from "../../Component/Commons/Select";
import CommonButton from "../../Component/Commons/Button";
import { addRoiHandler, getImageHandler, roiDetailHandler, roiUpdateHandler } from "../../service/Roi.service";
import { cameraListHandler } from "../../service/Camera.service";
import { getEventList } from "../../service/Event.service";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { addRoiValidation } from "../../Helper/validation";
import { addRoiIv } from "../../Helper/intialValues";

const BoxDrawer = () => {
  let initialBoxes = [];

  const canvasRef = useRef(null);
  const { t } = useTranslation();
  const [isDrawing, setIsDrawing] = useState(false);
  const [cameraData, setCameraData] = useState([]);
  const [imageData, setImageData] = useState("");
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [currentCoords, setCurrentCoords] = useState({ x: 0, y: 0 });
  const [boxes, setBoxes] = useState(initialBoxes);
  const [activeBox, setActiveBox] = useState(null); // Track the currently active box
  const [image, setImage] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [resizing, setResizing] = useState(false);
  const [resizingCorner, setResizingCorner] = useState(null);
  const [hoveringOverClose, setHoveringOverClose] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [style, setStyle] = useState({});
  const Navigate = useNavigate();
  const { state } = useLocation();



  const formik = useFormik({
    initialValues: addRoiIv,
    validationSchema: addRoiValidation,
    onSubmit: async (values) => {
      console.log("valuesdsdsdsdsds", values.roi_points);
      if (values.roi_points == null || values?.roi_points?.length === 0) {
        errorNotification("Please draw a ROI");
        return;
      }
      const payload = {
        camera_id: values.camera_id,
        camera_event_ids: values.event.map((item) => item.id),
        roi_name: values.roi_name,
        coordinates: values.roi_points,
        frame_number: 1,
        color: values.color,
        priority: 1,
        specific_analytics: false,
      };

      const res = state && state.id ? await roiUpdateHandler(state.id, payload) : await addRoiHandler(payload);
      if (res && res.success) {
        successNotification(res.message);
        Navigate("/roi");
      } else {
        errorNotification(res.message || "Something went wrong !");
      }
    },
  });

  console.log("formikccxcxcxcx", formik.errors);

  useEffect(() => {
    if (state && state.id) {
      getRoiDetail(state.id);
    }
  }, [state]);

  const getRoiDetail = async (id) => {
    const response = await roiDetailHandler(id);
    if (response && response.success) {
      console.log("response", response);
      formik.setFieldValue("roi_name", response?.data?.roi_name);
      formik.setFieldValue("camera_id", response?.data.camera);
      formik.setFieldValue("event", response?.data?.camera_events_data ? response?.data?.camera_events_data.map((item) => ({ name: item.camera_event, id: item.id })) : []);
      const updatedBox = {
        x: response?.data?.coordinates?.coordiantes ? response?.data?.coordinates?.coordiantes[0].x : 0,
        y: response?.data?.coordinates?.coordiantes ? response?.data?.coordinates?.coordiantes[0].y : 0,
        width: response?.data?.coordinates?.coordiantes ? response?.data?.coordinates?.coordiantes[0].width : 0,
        height: response?.data?.coordinates?.coordiantes ? response?.data?.coordinates?.coordiantes[0].height : 0,
        color: response?.data?.color,
      };
      setBoxes([updatedBox]);
      formik.setFieldValue("roi_points", response?.data?.coordinates?.coordiantes);
      formik.setFieldValue("color", response?.data?.color);
      getRoiImage(response?.data?.camera);
    } else {
      errorNotification(response.message || "Something went wrong.");
    }
  };



  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (image && style.width && style.height) {
      canvas.width = style.width;
      canvas.height = style.height;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      boxes.forEach((box) => {
        drawRect(context, box);
      });

      if (activeBox && !isDrawing) {
        drawRect(context, activeBox);
      }
    }
  }, [image, style, boxes, activeBox, isDrawing]);

  const getCameraData = async () => {
    const response = await cameraListHandler();
    if (response && response.data) {
      const data = response.data.map((item) => ({
        id: item.id,
        name: item.camera_name,
      }));
      setCameraData(data);
    } else {
      setCameraData([]);
    }
  };

  useEffect(() => {
    getCameraData();
    getEventListData();
  }, []);

  const getEventListData = async () => {
    const response = await getEventList();
    if (response && response.success) {
      let data = response.data.map((item) => ({
        name: item.camera_event,
        id: item.id,
      }));
      setEventData(data);
    } else {
      setEventData([]);
    }
  };

  const canceRoi = () => {
    Navigate("/roi");
  };

  const getRoiImage = async (id) => {
    const response = await getImageHandler(id);
    if (response && response.success) {
      setStyle({ width: response.data.width, height: response.data.height });
      const img = new Image();
      img.src = response.data.image;
      img.onload = () => {
        setImage(img);
      };
    } else {
      errorNotification(response.message || "Something went wrong.");
    }
  };

  const handleSelectChange = (event) => {
    formik.setFieldValue("camera_id", event.target.value);
    if (event.target.value) {
      getRoiImage(event.target.value);
    }
  };

  const drawRect = (context, rect) => {
    context.strokeStyle = rect.color;
    context.lineWidth = 2;
    context.strokeRect(rect.x, rect.y, rect.width, rect.height);

    const iconRadius = 10;
    const iconX = rect.x + rect.width - iconRadius - 5;
    const iconY = rect.y + iconRadius + 5;

    context.fillStyle = "black";
    context.beginPath();
    context.arc(iconX, iconY, iconRadius, 0, 2 * Math.PI);
    context.fill();

    context.fillStyle = "white";
    context.font = "12px Arial";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText("X", iconX, iconY);

    // Draw corner points
    context.fillStyle = "red";
    context.fillRect(rect.x - 5, rect.y - 5, 10, 10); // Top-left
    context.fillRect(rect.x + rect.width - 5, rect.y - 5, 10, 10); // Top-right
    context.fillRect(rect.x - 5, rect.y + rect.height - 5, 10, 10); // Bottom-left
    context.fillRect(rect.x + rect.width - 5, rect.y + rect.height - 5, 10, 10); // Bottom-right
  };

  const isPointInCloseIcon = (x, y, rect) => {
    const iconRadius = 10;
    const iconX = rect.x + rect.width - iconRadius - 5;
    const iconY = rect.y + iconRadius + 5;

    return (x - iconX) ** 2 + (y - iconY) ** 2 <= iconRadius ** 2;
  };

  const isPointInCorner = (x, y, rect) => {
    const corners = [
      { x: rect.x, y: rect.y }, // Top-left
      { x: rect.x + rect.width, y: rect.y }, // Top-right
      { x: rect.x, y: rect.y + rect.height }, // Bottom-left
      { x: rect.x + rect.width, y: rect.y + rect.height }, // Bottom-right
    ];

    const tolerance = 10;
    return corners.findIndex(
      (corner) =>
        Math.abs(x - corner.x) <= tolerance &&
        Math.abs(y - corner.y) <= tolerance
    );
  };

  const handleMouseDown = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;

    if (boxes.length > 0) {
      const clickedBoxIndex = boxes.findIndex((box) =>
        isPointInCloseIcon(offsetX, offsetY, box)
      );

      if (clickedBoxIndex !== -1) {
        setBoxes((prevBoxes) =>
          prevBoxes.filter((_, index) => index !== clickedBoxIndex)
        );
        formik.setFieldValue("roi_points", null);
        setActiveBox(null);
        formik.setFieldValue("roi_points", null);
        return;
      }

      const cornerIndex = isPointInCorner(offsetX, offsetY, boxes[0]);
      if (cornerIndex !== -1) {
        setResizing(true);
        setResizingCorner(cornerIndex);
        setActiveBox(boxes[0]);
        return;
      }

      if (
        offsetX >= boxes[0].x &&
        offsetY >= boxes[0].y &&
        offsetX <= boxes[0].x + boxes[0].width &&
        offsetY <= boxes[0].y + boxes[0].height
      ) {
        setDragging(true);
        setDragOffset({ x: offsetX - boxes[0].x, y: offsetY - boxes[0].y });
        setActiveBox(boxes[0]);
        return;
      }
    }

    if (boxes.length > 0) return; // Prevent drawing more than one box

    setIsDrawing(true);
    setStartCoords({ x: offsetX, y: offsetY });
    setActiveBox(null); // Clear active box when starting a new drawing
  };

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);
    if (image) {
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    }

    boxes.forEach((box) => {
      drawRect(context, box);
    });

    if (activeBox && !isDrawing) {
      if (resizing) {
        let updatedBox = { ...activeBox };

        switch (resizingCorner) {
          case 0: // Top-left
            updatedBox.width += updatedBox.x - offsetX;
            updatedBox.height += updatedBox.y - offsetY;
            updatedBox.x = offsetX;
            updatedBox.y = offsetY;
            break;
          case 1: // Top-right
            updatedBox.width = offsetX - updatedBox.x;
            updatedBox.height += updatedBox.y - offsetY;
            updatedBox.y = offsetY;
            break;
          case 2: // Bottom-left
            updatedBox.width += updatedBox.x - offsetX;
            updatedBox.x = offsetX;
            updatedBox.height = offsetY - updatedBox.y;
            break;
          case 3: // Bottom-right
            updatedBox.width = offsetX - updatedBox.x;
            updatedBox.height = offsetY - updatedBox.y;
            break;
          default:
            break;
        }

        setActiveBox(updatedBox);
        setBoxes([updatedBox]); // Update the box in the state
        formik.setFieldValue("roi_points", [
          {
            x: updatedBox.x,
            y: updatedBox.y,
            width: updatedBox.width,
            height: updatedBox.height
          }
        ]);
      } else if (dragging) {
        const newX = offsetX - dragOffset.x;
        const newY = offsetY - dragOffset.y;
        const updatedBox = { ...activeBox, x: newX, y: newY };
        setActiveBox(updatedBox);
        setBoxes([updatedBox]); // Update the box in the state
        formik.setFieldValue("roi_points", [
          {
            x: updatedBox.x,
            y: updatedBox.y,
            width: updatedBox.width,
            height: updatedBox.height
          }
        ]);
      }

      drawRect(context, activeBox);
    } else if (isDrawing) {
      setCurrentCoords({ x: offsetX, y: offsetY });
      const rect = {
        x: startCoords.x,
        y: startCoords.y,
        width: offsetX - startCoords.x,
        height: offsetY - startCoords.y,
        color: formik.values.color,
      };
      drawRect(context, rect);
    }

    if (!resizing) {
      setHoveringOverClose(
        boxes.some((box) => isPointInCloseIcon(offsetX, offsetY, box)) ||
        (activeBox && isPointInCloseIcon(offsetX, offsetY, activeBox))
      );
    }
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      const createdBox = {
        x: startCoords.x,
        y: startCoords.y,
        width: currentCoords.x - startCoords.x,
        height: currentCoords.y - startCoords.y,
        color: formik.values.color,
      };
      setActiveBox(createdBox);
      setBoxes([createdBox]); // Save the box automatically and prevent drawing more
      formik.setFieldValue("roi_points", [{
        x: startCoords.x,
        y: startCoords.y,
        width: currentCoords.x - startCoords.x,
        height: currentCoords.y - startCoords.y,
      }]);
      setIsDrawing(false);
    } else if (dragging) {
      setDragging(false);
    } else if (resizing) {
      setResizing(false);
      setResizingCorner(null);
    }
  };

  const handleReset = () => {
    setBoxes([]);
    setActiveBox(null);
    formik.setFieldValue("roi_points", []);
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);
    if (image) {
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    }
  };

  return (
    <div>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={6}>
          <CommonInput
            labelInput={t("RoiName")}
            value={formik.values.roi_name}
            onChange={formik.handleChange}
            name="roi_name"
            formik={formik}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CommonSelect
            labelinput={t("SelectCamera")}
            value={formik.values.camera_id}
            onChange={handleSelectChange}
            name="camera_id"
            selectHead={t("SelectCamera")}
            selectList={cameraData}
            formik={formik}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            fontSize="14px"
            fontWeight={600}
            marginBottom="8px"
            color="white"
          >
            {t("Event")}
          </Typography>

          <Autocomplete
            options={eventData?.filter(
              (item) => !formik.values.event.includes(item)
            )}
            value={formik.values.event}
            getOptionLabel={(option) => (option && option.name) || ""}
            onChange={(event, value) => {
              formik.setFieldValue("event", value);
              setEventData(eventData.filter((item) => !value.includes(item)));
            }}
            style={{ backgroundColor: "#060606" }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("Chooseanevent")}
                variant="outlined"
              />
            )}
            multiple
            disableCloseOnSelect
          />
          {formik.errors.event && formik.touched.event && (
            <Typography color="error" variant="caption" mt={1}>
              {formik.errors.event}
            </Typography>
          )}
        </Grid>
      </Grid>
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{
          border: "1px solid black",
          cursor: hoveringOverClose || resizing ? "pointer" : "crosshair",
          //image not foundthen hide this 
          display: image ? "block" : "none",
        }}
      />
      {
        //image not foundthen hide thisa 
        image && (
          <>

            <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
              <button
                onClick={handleReset}
                style={{
                  padding: "8px 16px",
                  marginRight: "10px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #ccc",
                  cursor: "pointer",
                }}
              >
                {t("Reset")}
              </button>
              <input
                type="color"
                value={formik.values.color}
                onChange={(e) => formik.setFieldValue("color", e.target.value)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        )
      }

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <Grid item xs={6}>
          <CommonButton onClick={formik.handleSubmit} buttonName={t("Save")} />
        </Grid>
        <Grid item xs={6}>
          <CommonButton
            onClick={() => {
              canceRoi();
            }}
            style={{
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            buttonName={t("Cancel")}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default BoxDrawer;
