import { post } from "../web.request";

export const fireDetectionHandler = (data) => {
  return post(
    `${process.env.REACT_APP_API_URL}/fire_detection_upload_video`,
    data
  );
};

export const fallDetectionHandler = (data) => {
  return post(
    `${process.env.REACT_APP_API_URL}/fall_detection_upload_video`,
    data
  );
};

export const dangerDetectionHandler = (data) => {
  return post(
    `${process.env.REACT_APP_API_URL}/danger_zone_upload_video`,
    data
  );
};

export const intrusionDetectionHandler = (data) => {
  return post(
    `${process.env.REACT_APP_API_URL}/intrusion_detection_upload_video`,
    data
  );
};
