import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CloseIcon } from '../../Helper/Icons'
import { Button } from '../Commons'
import { deleteUserById } from '../../service/User.service'
import { errorNotification, successNotification } from '../../Helper/Notification'
import { deleteOrgHandler } from '../../service/Organization.service'
import { deleteLocation } from '../../service/location.service'
import { deleteAreaHandler } from '../../service/area.service'
import { deleteCameraHandler } from '../../service/Camera.service'
import { deleteEventById } from '../../service/Event.service'
import { useTranslation } from "react-i18next";
import { deleteRoiHandler } from '../../service/Roi.service'

const Delete = ({ handleClose, modalOpen, closeFlag }) => {
    const response = modalOpen.data;
    const { t } = useTranslation();

    const agreeHandler = async () => {
        if (response.name === "USER") {
            const { data, message, success } = await deleteUserById(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }
        if (response.name === "ORGANIZATION") {
            const { data, message, success } = await deleteOrgHandler(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }
        // LOCATIONS
        if (response.name === "LOCATIONS") {
            const { data, message, success } = await deleteLocation(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }

        if (response.name === "AREA") {
            const { data, message, success } = await deleteAreaHandler(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }
        // CAMERA
        if (response.name === "CAMERA") {
            const { data, message, success } = await deleteCameraHandler(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }
        if (response.name === "EVENT") {
            const { data, message, success } = await deleteEventById(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }
        if (response.name === "ROI") {
            const { data, message, success } = await deleteRoiHandler(response.id);
            if (success) {
                successNotification(message);
                closeFlag();
                handleClose();
            } else {
                errorNotification(message);
            }
        }

    };


    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {response.head ? response.head : modalOpen.deleteHead}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Box width={{ sm: '400px' }}>
                    <Typography>{response.message ? response.message : modalOpen.deletePara}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName={t("No")} onClick={handleClose} />
                <Button buttonName={t("Yes")} onClick={() => agreeHandler()} />
            </DialogActions>
        </div>
    )
}

export default Delete