import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { handelVideoUpload } from "../../service/User.service";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { videoUploadHandler } from "../../service/Alert.service";
import {
  dangerDetectionHandler,
  fallDetectionHandler,
  fireDetectionHandler,
  intrusionDetection,
  intrusionDetectionHandler,
} from "../../service/Detections.service";
import { useTranslation } from "react-i18next";

const UploadVideo = ({ handleClose, modalOpen, closeFlag }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    // Validate the file type before setting the files
    const isVideo = acceptedFiles.every((file) =>
      file.type.startsWith("video/")
    );
    if (isVideo) {
      setFiles(acceptedFiles);
    } else {
      errorNotification(`${t("Invalidfiletype.Pleaseselectavideofile.")}`);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
  });

 
  const handleVideoUploadFile = async () => {
    try {
      setErrorMessage("");
      if (files.length > 0) {
        setLoading(true);

        const formData = new FormData();
        formData.append("video_file", files[0]);

        if (modalOpen.name === "INTRUSIONDETECTION") {
          formData.append("start_time", startTime);
          formData.append("end_time", endTime);
        }


        if (modalOpen.name === "INTRUSIONDETECTION") {
          if (!startTime || !endTime) {
            setErrorMessage(`${t("please_add_Valid_startTime_and_endTime")}`);
          } else {
            setErrorMessage("");
            const { data, message, success } = await intrusionDetectionHandler(
              formData
            );
            if (success) {
              successNotification(message);
              closeFlag();
              handleClose();
            } else {
              errorNotification(message);
              closeFlag();
              handleClose();
            }
          }
        }


        if (modalOpen.name === "DANGERDETECTION") {
          const { data, message, success } = await dangerDetectionHandler(
            formData
          );
          if (success) {
            successNotification(message);
            closeFlag();
            handleClose();
          } else {
            errorNotification(message);
            closeFlag();
            handleClose();
          }
        }


        if (modalOpen.name === "FALLDETECTION") {
          const { data, message, success } = await fallDetectionHandler(
            formData
          );
          if (success) {
            successNotification(message);
            closeFlag();
            handleClose();
          } else {
            errorNotification(message);
            closeFlag();
            handleClose();
          }
        }

        if (modalOpen.name === "FIREDETECTION") {
          const { data, message, success } = await fireDetectionHandler(
            formData
          );
          if (success) {
            successNotification(message);
            closeFlag();
            handleClose();
          } else {
            errorNotification(message);
            closeFlag();
            handleClose();
          }
        }

        if (modalOpen.name === "ALERT") {
          const { data, message, success } = await videoUploadHandler(
            formData
          );
          if (success) {
            successNotification(message);
            closeFlag();
            handleClose();
          } else {
            errorNotification(message);
            closeFlag();
            handleClose();
          }
        }
      } else {
        throw new Error(`${t("Please_select_a_video_file.")}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      errorNotification(`${t("An_error_occurred_during_video_upload.")}`);
      closeFlag();
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("UploadVideo")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ width: { sm: "500px" } }}>
        <div>
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p style={{ color: "#fff" }}>
              {files && files.length > 0
                ? files[0].name
                : `${t("Drag_'n'_drop_a_video_file_here,_or_click_to_select_one")}`}
            </p>
          </div>
        </div>
        {modalOpen.name === "INTRUSIONDETECTION" && (
          <>
            <Box sx={{ mt: 1 }}>
              <Input
                type="time"
                labelInput={t("StartTime")}
                name="start_time"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                  // Ensure start time is greater than end time
                  // if (e.target.value < endTime) {
                  //   setStartTime(e.target.value);
                  //   setErrorMessage(""); // Clear error message if valid
                  // } else {
                  //   setErrorMessage("Start time must be less than end time");
                  // }
                }}
                // onChange={
                //   (e) => setStartTime(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Input
                type="time"
                name="end_time"
                labelInput={t("EndTime")}
                value={endTime}
                // onChange={(e) => setEndTime(e.target.value)}
                onChange={(e) => {
                  // Ensure end time is less than start time
                  setEndTime(e.target.value);
                  // if (e.target.value > startTime) {
                  //   setErrorMessage(""); // Clear error message if valid
                  // } else {
                  //   setErrorMessage("End time must be greater than start time");
                  // }
                }}
              />
            </Box>
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button buttonName={t("Cancel")} buttonColor="gray" onClick={handleClose} />
        <Button
          buttonName={t("Upload")}
          loading={loading}
          // disabled={loading}
          onClick={handleVideoUploadFile}
        />
      </DialogActions>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default UploadVideo;
