import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  getAttendnceStatus,
  useStyles,
} from "../../../Helper/Theme";
import { Button, Input, Modal } from "../../../Component/Commons";
import { VscSettings, RiSearchLine } from "../../../Helper/Icons";
import { reportView } from "../../../Helper/Constant";

const classdata = [
  {
    name: "John Doe",
    email: "john@example.com",
    date: "2023-05-01",
    time: "09:00 AM",
    status: "Present",
  },
  {
    name: "Jane Smith",
    email: "jane@example.com",
    date: "2023-05-02",
    time: "10:30 AM",
    status: "Absent",
  },
  // Add more data as needed
];

const Class = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  var status = getAttendnceStatus(true);
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search for class"
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Sta.Name:
            </Typography>
            <Input placeholder="Enter Name" type="text" />
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Time:
            </Typography>
            <Input type="time" />
          </Grid>

          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Sta.Name</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classdata.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.date}</StyledTableCell>
                <StyledTableCell align="center">{row.time}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Class;
