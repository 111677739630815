// App.js
import React from 'react';
import { Chart } from 'react-google-charts';

const data = [
  ['Time', ' Customers'],
  ['8 AM',  6],
  ['9 AM',  13],
  ['10 AM',  15],
  ['11 AM',  9],
  ['12 PM',  5],
  ['1 PM',  8],
  ['2 PM',  3],
  ['3 PM',  6],
  ['4 PM',  13],
  ['5 PM',  19],
  ['6 PM',  29],
  ['7 PM',  20],
  ['8 PM',  10],
  ['9 PM',  13],
  ['10 PM',  5],
];

const options = {
  hAxis: { title: 'Time' },
  vAxis: { title: 'Number of Customers' },
  legend: 'none',
  width: '100%',
  height: '400px',
  backgroundColor: {
    fill: '#000000',  
  },
  hAxis: {
    textStyle: { color: '#FFFFFF' }  
  },
  vAxis: {
    textStyle: { color: '#FFFFFF' }  
  },
  legend: {
    textStyle: { color: '#FFFFFF' }  
  }
};

function ShoppingAnalysisCHart() {
  return (
    <div className="App">
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </div>
  );
}

export default ShoppingAnalysisCHart;
