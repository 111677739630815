import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Menu,
  MenuItem,
  Select,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import {
  VscSettings,
  RiSearchLine,
  GoDotFill,
  BsFiletypeCsv,
  BsFiletypeExe,
} from "../../../Helper/Icons";
import { Button, Input, Modal } from "../../../Component/Commons";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../Helper/Theme";
const Safetycompliance = () => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [exportFile, setExportFile] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const handleClickProfile = (event) => {
    setExportFile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setExportFile(null);
  };
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const data = [
    {
      safetymessage: "s",
    },
  ];
  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search "
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button buttonName="Export" onClick={handleClickProfile} />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
        <Menu
          anchorEl={exportFile}
          open={Boolean(exportFile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeCsv style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>csv</div>
            </Stack>
          </MenuItem>
          <MenuItem
            value="excel"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeExe style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>excel</div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
       {/* filter */}
       <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Safety Message:
            </Typography>
            <Input placeholder="Enter Safety Message" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Location:
            </Typography>
            <Input placeholder="Enter Location" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Event:
            </Typography>
            <Input placeholder="Enter Event" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Organization Name
            </Typography>
            <Input placeholder="Enter Organization Name Number" type="text"/>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Start Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              End Date:
            </Typography>
            <Input type="date" />
          </Grid>
         
         
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Safety Message</StyledTableCell>
              <StyledTableCell>Location Name</StyledTableCell>
              <StyledTableCell>Event Type</StyledTableCell>
              <StyledTableCell>Organization Name</StyledTableCell>
              <StyledTableCell>Date & Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 1, 1, 1].map((row, i) => (
              <StyledTableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell component="th" scope="row">
                  Shoes:No
                </TableCell>
                <TableCell component="th" scope="row">
                  Location {i + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  Helmet, Glasses, Gloves, Shoes
                </TableCell>
                <TableCell component="th" scope="row">
                  Visnx
                </TableCell>
                <TableCell component="th" scope="row">
                  18-06-2004 12:16:55
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Safetycompliance;
