import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { getOrgDataHandler } from "../../service/Organization.service";
import { useFormik } from "formik";
import { addCameraIv } from "../../Helper/intialValues";
import { cameraValidation } from "../../Helper/validation";
import { locationByOrgId } from "../../service/location.service";
import { userByIds } from "../../service/User.service";
import { getAreaById } from "../../service/area.service";
import { addCameraHandler, updateCameraHandler } from "../../service/Camera.service";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { useTranslation } from "react-i18next";

const AddCamera = ({ handleClose, modalOpen, closeFlag }) => {
  const { t } = useTranslation();
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);

  const [orgName, setOrgName] = useState([]);
  const [selectOrgName, setSelectOrgName] = useState([]);
  const [orgInputValue, setOrgInputValue] = useState("");
  const [orgId, setOrgId] = useState("");

  const [locationName, setLocationName] = useState([]);
  const [selectLocationName, setSelectLocationName] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState("");
  const [locationId, setLocationId] = useState("");

  const [areaName, setAreaName] = useState([]);
  const [selectAreaName, setSelectAreaName] = useState([]);
  const [AreaInputValue, setAreaInputValue] = useState("");
  const [areaId, setAreaId] = useState("");

  const [userName, setUserName] = useState([]);
  const [selectUserName, setSelectUserName] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");
  const [userId, setUserId] = useState([]);
  const [loading, setLoading] = useState(false);
  const response = modalOpen?.data?.totalData;

  const getOrgListHandler = async (search) => {
    const { count, data, message, success, totalPage } =
      await getOrgDataHandler(search);
    if (success) {
      const orgData =
        data &&
        data?.map((res) => {
          return {
            label: res.org_name,
            id: res.id,
          };
        });
      setOrgName(orgData);
    } else {
      setOrgName([]);
    }
  };

  const getlocationByorgId = async (orgId, search) => {
    try {
      const { count, data, message, success, totalPage } =
        await locationByOrgId(orgId, search);
      if (success) {
        const locationData =
          data &&
          data.map((res) => {
            return {
              label: res.loc_name,
              id: res.id,
            };
          });
        setLocationName(locationData);
      } else {
        setLocationName([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const userDataHandler = async (orgId, locationId, areaId, cameraId) => {
    const { count, data, message, success, totalPage } = await userByIds(
      orgId,
      locationId,
      areaId,
      cameraId
    );
    if (success) {
      const userData =
        data &&
        data.map((res) => {
          const fullName = `${res.first_name} ${res.last_name}`;
          return {
            label: fullName,
            id: res.id,
          };
        });
      setUserName(userData);
    } else {
      setUserName([]);
    }
  };

  const areaByHandler = async (organizationId, locationId, search) => {
    const { count, data, message, success, totalPage } = await getAreaById(
      organizationId,
      locationId,
      search
    );
    if (success) {
      const areaData =
        data &&
        data.map((res) => {
          return {
            label: res.area_name,
            id: res.id,
          };
        });
      setAreaName(areaData);
    } else {
      setAreaName([]);
    }
  };

  useEffect(() => {
    getOrgListHandler("");
  }, []);

  const submitHandler = async () => {
    try {
      setLoading(true);
      const { data, message, success } = modalOpen.isEdit ? await updateCameraHandler(formik.values, response.id) : await addCameraHandler(formik.values);
      if (success) {
        successNotification(message);
        closeFlag();
        handleClose()
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      handleClose();
      closeFlag();
    }
  };

  useEffect(() => {
    if (modalOpen.isEdit) {
      getCameraDetails(modalOpen?.data?.totalData);
    }
  }, []);

  const getCameraDetails = (data) => {
    const orgData = {
      label: data.org_name,
      id: data.organization_id
    };
    setSelectOrgName(orgData);
    const locationData = {
      label: data.loc_name,
      id: data.location_id
    }
    setSelectLocationName(locationData);
    const areaData = {
      label: data.area_name,
      id: data.area_id
    };
    setSelectAreaName(areaData);
    const userData = data.camera_user_details && data.camera_user_details.map((res) => {
      return {
        label: `${res.first_name} ${res.last_name}`,
        id: res.id
      }
    });
    setSelectUserName(userData);
    formik.setValues({
      ...formik.values,
      organization_id: data.organization_id,
      location_id: data.location_id,
      area_id: data.area_id,
      camera_name: data.camera_name,
      fps: data.fps,
      ip_address: data.ip_address,
      rtsp_url: data.rtsp_url,
      frame_numbers: data.frame_numbers,
      port: data.port,
      user_ids: data.camera_user_details && data.camera_user_details.map((res) => res.id),
    });
  };

  const formik = useFormik({
    initialValues: addCameraIv,
    validationSchema: cameraValidation,
    onSubmit: submitHandler,
  });

  const orgSelectHandler = (data) => {
  };

  const locationSelectHandler = (data) => {
  };

  const userSelectHandler = (data) => {
  };

  const areaSelectHandler = (data) => {
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? `${t("Edit")}` : `${t("Add")}`} {t("Camera")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={selectOrgName}
              options={orgName}
              onChange={(event, value) => {
                setSelectOrgName(value);
                setOrgId(value?.id);
                formik.setFieldValue("organization_id", value?.id);
                getlocationByorgId(value?.id, "");
                areaByHandler(value?.id, locationId, "");
                userDataHandler(value?.id, locationId, areaId, "");

                // when Data Changed
                formik.setFieldValue("location_id", "");
                setSelectLocationName([]);
                setLocationId("");
                formik.setFieldValue("area_id", "");
                setSelectAreaName([]);
                setAreaId("");
                formik.setFieldValue("user_ids", []);
                setSelectUserName([]);
                setUserId([]);

                // userDataHandler(value?.id, "", "", "");
                // location Changed
                // setSelectLocationName([]);
                // formik.setFieldValue("loc_id", "");
                // setLocaId("");
                //
                // setSelectUserName([]);
                // formik.setFieldValue("user_ids", []);
              }}
              inputValue={orgInputValue}
              onInputChange={(event, newInputValue) => {
                setOrgInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("OrganizationList")}
                  onChange={(event) => orgSelectHandler(event.target.value)}
                />
              )}
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.organization_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={selectLocationName}
              options={locationName}
              onChange={(event, value) => {
                setSelectLocationName(value);
                setLocationId(value?.id);
                areaByHandler(orgId, value?.id, "");
                userDataHandler(orgId, value?.id, areaId, "");
                formik.setFieldValue("location_id", value?.id || "");
                // when data Changed
                formik.setFieldValue("area_id", "");
                setSelectAreaName([]);
                setAreaId("");
                formik.setFieldValue("user_ids", []);
                setSelectUserName([]);
                setUserId([]);
              }}
              inputValue={locationInputValue}
              onInputChange={(event, newInputValue) => {
                setLocationInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("LocationList")}
                  onChange={(event) =>
                    locationSelectHandler(event.target.value)
                  }
                />
              )}
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.location_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={selectAreaName}
              options={areaName}
              onChange={(event, value) => {
                setAreaId(value?.id);
                setSelectAreaName(value);
                userDataHandler(orgId, locationId, value?.id, "");
                formik.setFieldValue("area_id", value?.id || "");
                formik.setFieldValue("user_ids", []);
                setSelectUserName([]);
                setUserId([]);
              }}
              inputValue={AreaInputValue}
              onInputChange={(event, newInputValue) => {
                setAreaInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("AreaList")}
                  onChange={(event) => areaSelectHandler(event.target.value)}
                />
              )}
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.area_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("CameraName")}
              name="camera_name"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Input
              placeholder={t("FPS")}
              name="fps"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Input
              placeholder={t("IPAddress")}
              name="ip_address"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Autocomplete
              options={userName}
              value={selectUserName || []}
              getOptionLabel={(option) => (option ? option.label : "")}
              onChange={(event, value) => {
                const selectedUserIds = value.map((value) => value?.id);
                setSelectUserName(value);
                formik.setFieldValue("user_ids", selectedUserIds);
                setUserId(selectedUserIds);
              }}
              inputValue={userInputValue}
              onInputChange={(event, newInputValue) => {
                setUserInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("UserList")}
                  onChange={(event) => userSelectHandler(event.target.value)}
                />
              )}
              multiple
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
            />
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.user_ids}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("RTSPlink")}
              name="rtsp_url"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Input
              placeholder={t("FrameNumbers")}
              name="frame_numbers"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Input
              placeholder={t("Port")}
              name="port"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid> */}

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={modalOpen.isEdit ? `${t("Save")}` : `${t("Add")}`}
          size="small"
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
        />
        <Button
          buttonName={t("Cancel")}
          color="secondary"
          style={{ color: "#fff", borderColor: "#2c2c2c" }}
          size="small"
          variant="outlined"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default AddCamera;
