import React from "react";
import Chart from "react-google-charts";

export const data = [
  [
    "Entry",
    "Exit",
  ],
  ["10:00-AM", 0,],
  ["10:15-AM", 3,],
  ["10:30-AM", 2,],
  ["10:45-AM", 1,],
  ["11:00-AM", 1,],
  ["11:15-AM", 8],
  ["11:30-AM", 7,],
  ["11:45-AM", 12,],
  ["12:00-PM", 16,],
  ["12:15-PM", 12,],
  ["12:30-PM", 5,],
  ["12:45-PM", 6,],
  ["13:00-PM", 4,],
  ["13:15-PM", 4,],
  ["13:30-PM", 4,],
  ["13:45-PM", 4,],
  ["14:00-PM", 14],
  ["14:15-PM", 4,],
  ["14:30-PM", 4,],
  ["14:45-PM", 4,],
  ["15:00-PM", 4,],
  ["15:15-PM", 4,],
];

export const options = {
  chart: {
    title: "Customer Entry/Exit Chart",
  },
  backgroundColor: {
    fill: '#000000',
  },
  hAxis: {
    textStyle: { color: '#FFFFFF' }
  },
  vAxis: {
    textStyle: { color: '#FFFFFF' }
  },
  legend: {
    textStyle: { color: '#FFFFFF' }
  }
};

const Enter_ExitChart = ({ listTrafficDatas }) => {

  const chartData = listTrafficDatas.map(item => [
    item.duration,
    item.entry_count,
  ])
  chartData.unshift(["Entry", "Exit"]);
  return (
    <div>
      <Chart
        chartType="LineChart"
        height="400px"
        width="100%"
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default Enter_ExitChart;
