import { DELETE, get, post, put } from "../web.request";

export const HlsConvertHandler = () => {
  return get(`${process.env.REACT_APP_API_URL}/stream_rtsp`);
}


//roi list
export const roiListHandler = (query) => {
  return get(`${process.env.REACT_APP_API_URL}/roi_create?${query}`);
};

export const addRoiHandler = (payload) => {
  return post(`${process.env.REACT_APP_API_URL}/roi_create`, payload);
};

//image get 
export const getImageHandler = (id) => {
  return get(`${process.env.REACT_APP_API_URL}/camera_id_for_roi/${id}`);
};

//roi detail api 
export const roiDetailHandler = (id) => {
  return get(`${process.env.REACT_APP_API_URL}/roi_list/${id}`);
};

//roi update 
export const roiUpdateHandler = (id, payload) => {
  return put(`${process.env.REACT_APP_API_URL}/update_roi/${id}`, payload);
};

//delete_roi
export const deleteRoiHandler = (id) => {
  return DELETE(`${process.env.REACT_APP_API_URL}/delete_roi/${id}`);
};