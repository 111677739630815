import React, { useState } from "react";
import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Popover,
  Chip,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
  getAttendnceStatus,
  useStyles,
} from "../../../Helper/Theme";
import { Button, Input, Modal } from "../../../Component/Commons";
import { VscSettings, RiSearchLine } from "../../../Helper/Icons";
import { reportView } from "../../../Helper/Constant";
import worker from "../../../assets/image/worker.jpg";

const User_Manage = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false,
  });
  const status = getAttendnceStatus(true);
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const rows = [
    {
      user: "John Doe",
      image: worker,
      category: "Child",
      room: "Room 1",
      master: [
        { name: "LeoLarsd", image: worker },
        { name: "JohnDoe", image: reportView },
      ],
      linked: [
        { name: "LeoLarsd", image: worker },
        { name: "JohnDoe", image: reportView },
      ],
    },
    {
      user: "Alex",
      image: reportView,
      category: "Child",
      room: "Room 2",
      master: [{ name: "LeoLarsd", image: reportView }],
      linked: [{ name: "LeoLarsd", image: worker }],
    },
    {
      user: "Sofia",
      image: reportView,
      category: "Child",
      room: "Room 3",
      master: [
        { name: "Alex", image: worker },
        { name: "LeoLarsd", image: worker },
        { name: "JohnDoe", image: reportView },
      ],
      linked: [
        { name: "LeoLarsd", image: worker },
        { name: "JohnDoe", image: reportView },
        { name: "Alex", image: worker },
      ],
    },
  ];

  const [anchorElMaster, setAnchorElMaster] = useState(null);
  const [anchorElLinked, setAnchorElLinked] = useState(null);

  const handlePopoverOpenMaster = (event, masterIndex) => {
    setAnchorElMaster({ element: event.currentTarget, index: masterIndex });
  };

  const handlePopoverCloseMaster = () => {
    setAnchorElMaster(null);
  };

  const handlePopoverOpenLinked = (event, linkedIndex) => {
    setAnchorElLinked({ element: event.currentTarget, index: linkedIndex });
  };

  const handlePopoverCloseLinked = () => {
    setAnchorElLinked(null);
  };

  const openMaster = Boolean(anchorElMaster);
  const openLinked = Boolean(anchorElLinked);

  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search for User"
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              User:
            </Typography>
            <Input placeholder="Enter User" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Category:
            </Typography>
            <Input placeholder="Enter Category" type="text" />
          </Grid>

          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Room:
            </Typography>
            <Input placeholder="Enter Room" type="text" />
          </Grid>

          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow
            
            >
              <StyledTableCell>User</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Room</StyledTableCell>
              <StyledTableCell>Master</StyledTableCell>
              <StyledTableCell>Linked</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsModalOpen({
                    open: true,
                    currentComponent: "viewUsermanage",
                    isEdit: false,
                  })
                }
              >
                <StyledTableCell>{row.user}</StyledTableCell>
                <StyledTableCell>
                  <img src={row.image} width={50} alt="User" />
                </StyledTableCell>
                <StyledTableCell>{row.category}</StyledTableCell>
                <StyledTableCell>{row.room}</StyledTableCell>
                <StyledTableCell
                  aria-owns={
                    openMaster ? "mouse-over-popover-master" : undefined
                  }
                  aria-haspopup="true"
                  onMouseLeave={handlePopoverCloseMaster}
                  className="tabletext"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "180px",
                    }}
                  >
                    {row.master instanceof Array &&
                      row.master.map((master, index) => (
                        <div
                          key={index}
                          onMouseEnter={(event) =>
                            handlePopoverOpenMaster(event, index)
                          }
                        >
                          <Chip label={master.name} />

                          <Popover
                            id="mouse-over-popover-master"
                            sx={{ pointerEvents: "none" }}
                            open={openMaster && anchorElMaster.index === index}
                            anchorEl={anchorElMaster?.element}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverCloseMaster}
                            disableRestoreFocus
                          >
                            <Box sx={{ p: 1 }}>
                              <img
                                src={master.image}
                                width={250}
                                alt={`Master Detail for ${master.name}`}
                              />
                            </Box>
                          </Popover>
                        </div>
                      ))}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  aria-owns={
                    openLinked ? "mouse-over-popover-linked" : undefined
                  }
                  aria-haspopup="true"
                  onMouseLeave={handlePopoverCloseLinked}
                  className="tabletext"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "180px",
                    }}
                  >
                    {row.linked instanceof Array &&
                      row.linked.map((linked, index) => (
                        <div
                          key={index}
                          onMouseEnter={(event) =>
                            handlePopoverOpenLinked(event, index)
                          }
                        >
                          <Chip label={linked.name} />
                          <Popover
                            id="mouse-over-popover-linked"
                            sx={{ pointerEvents: "none" }}
                            anchorEl={anchorElLinked?.element}
                            open={openLinked && anchorElLinked.index === index}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverCloseLinked}
                            disableRestoreFocus
                          >
                            <Box sx={{ p: 1 }}>
                              <img
                                src={linked.image}
                                width={250}
                                alt={`Linked Detail for ${linked.name}`}
                              />
                            </Box>
                          </Popover>
                        </div>
                      ))}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
    </>
  );
};

export default User_Manage;
