import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export const post = async (url, data) => {
  try {
    const TOKEN = cookies.get("USERTOKEN");

    const headers = {
      Authorization: `Bearer ${TOKEN}`,
    };
    const response = await axios.post(url, data,{
      headers
    });
    if (response.data.status) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        data: response.data.data,
        message: response.data.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      data: [],
      message: error.message || "something_went_wrong",
    };
  }
};

export const get = async (url) => {
  try {
    const TOKEN = cookies.get("USERTOKEN");

    const headers = {
      Authorization: `Bearer ${TOKEN}`,
    };

    const response = await axios.get(url,{
      headers
    });
    if (response.data.status) {
      return {
        success: true,
        data: response.data.data,
        count:response.data.count,
        totalPage:response.data.total_page,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        count:response.data.count || 0,
        totalPage:response.data.total_page ||0,
        data: response.data.data,
        message: response.data.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      count:0,
      totalPage:0,
      data: [],
      message: error.message,
    };
  }
};

export const POST = async (url, data) =>{
  try {
    const response = await axios.post(url, data);
    if (response.data.status) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        data: response.data.data,
        message: response.data.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      data: [],
      message: error.message || "something_went_wrong",
    };
  }
}


export const DELETE = async (id) =>{
  try{
    const TOKEN = cookies.get("USERTOKEN");

    const headers = {
      Authorization: `Bearer ${TOKEN}`,
    };
    const response = await axios.delete(id, {
      headers,
    });
    if (response.data.status) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        data: response.data.data,
        message: response.data.message,
      };
    }
  }
  catch (error) {
    return {
      success: false,
      data: null,
      message: error.response.data.message || "something_went_wrong",
    };
  }
}


export const put = async (url,data) =>{
  try{
    const TOKEN = cookies.get("USERTOKEN");

    const headers = {
      Authorization: `Bearer ${TOKEN}`,
    };
    const response = await axios.put(url,data,{
      headers,
    });
    if (response.data.status) {
      return {
        success: true,
        data: response.data.data,
        message: response.data.message,
      };
    } else {
      return {
        success: false,
        data: response.data.data,
        message: response.data.message,
      };
    }
  }
  catch (error) {
    return {
      success: false,
      data: null,
      message: error.response.data.message || "something_went_wrong",
    };
  }
}