import { get, post } from "../web.request";

export const alertDataHandler = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate, selectedEvent) => {
    return get(`${process.env.REACT_APP_API_URL}/alert_get_list?search=${searchText}&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}&camera_event_id=${selectedEvent}`);
};

export const FallDataHandler = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate) => {
    return get(`${process.env.REACT_APP_API_URL}/alert_get_list?search=${searchText}&use_case_detection=FallDetected&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}`);
};

export const FireDataHandler = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate) => {
    return get(`${process.env.REACT_APP_API_URL}/alert_get_list?search=${searchText}&use_case_detection=FireDetection&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}`);
};

export const intrusionDataHandler = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate) => {
    return get(`${process.env.REACT_APP_API_URL}/alert_get_list?search=${searchText}&use_case_detection=IntrusionDetected&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}`);
};

export const DangerDataHandler = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate) => {
    return get(`${process.env.REACT_APP_API_URL}/alert_get_list?search=${searchText}&use_case_detection=DanzerDetected&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}`);
};


export const videoUploadHandler = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/upload_video_on_detection`, payload);
};

export const exportCSV = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate) => {
    return get(`${process.env.REACT_APP_API_URL}/export_csv_report?search=${searchText}&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}`);
};

export const getAlertByID = (id) => {
    return get(`${process.env.REACT_APP_API_URL}/id_based_alert/${id}`);
};