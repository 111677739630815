import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Sidebar from './Sidebar';
import Header from './Header';
import { Grid } from '@mui/material';

export default function Layout({ children }) {
    const { window } = Window;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [drawerWidth, setDrawerWidth] = React.useState(220);
    const [drawerStatus, setDrawerStatus] = React.useState(true);

    const handleDrawerToggleWeb = () => {
        if (drawerWidth === 220) {
            setDrawerWidth(60);
            setDrawerStatus(false)
        } else {
            setDrawerWidth(220);
            setDrawerStatus(true)
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{
            display: 'flex', height: '100vh',
            width: '100vw'
        }}>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, height: '100vh', flexShrink: { md: 0 }, transition: 'width 0.3s ease-in-out' }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box',transition: 'width 0.3s ease-in-out', width: drawerWidth, backgroundColor: '#151515' },
                    }}
                >
                    <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} drawerStatus={drawerStatus} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box',transition: 'width 0.3s ease-in-out', width: drawerWidth, backgroundColor: '#151515' },
                    }}
                >
                    <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} drawerStatus={drawerStatus} />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, backgroundColor: 'transparent', height: '100vh', overflow: 'auto' }}
            >
                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                    <Header handleDrawerToggle={handleDrawerToggle} handleDrawerToggleWeb={handleDrawerToggleWeb} />
                </Grid>

                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { md: `calc(100%)` }, p: 2 }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
