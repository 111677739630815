import React from "react";
import CounterCard from "./CounterCard";
import { Box, Grid, Stack, Typography } from "@mui/material";
import VisitorsHeatmap from "./VisitorsHeatmap";
import DemographicPIEchart from "../Demographic-Analysis/DemographicPIEChart";
import DashboardPiechart from "./DashboardPiechart";
import DonutChart from "./VisitorsDonutChart";
import VisitorsLineChart from "./VisitorsLIneChart";

const RetailDashboard = () => {
  const newVisitors = 300;
  const returningVisitors = 200;
  return (
    <Box>
      <Stack gap={2}>
        <CounterCard />
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
              <Typography mb={1}>Visitors</Typography>
              <VisitorsLineChart
                chartType="LineChart"
                height="calc(100% - 20px)"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ background: "#151515", p: 2 }}>
                <DashboardPiechart />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ background: "#151515", p: 2 }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={2}
              >
                <DonutChart
                  newVisitors={newVisitors}
                  returningVisitors={returningVisitors}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ background: "#151515", p: 2 }}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={2}
              >
                <Typography>Heatmap</Typography>
              </Stack>
              <VisitorsHeatmap
                chartType="LineChart"
                height="calc(100% - 40px)"
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default RetailDashboard;
