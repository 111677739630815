import { DELETE, get, post, put } from "../web.request";

export const addCameraHandler = (payload) => {
    return post(`${process.env.REACT_APP_API_URL}/create_camera`, payload);
};

export const updateCameraHandler = (payload, id) => {
    return put(`${process.env.REACT_APP_API_URL}/update_camera/${id}`, payload);
};

export const deleteCameraHandler = (id) => {
    return DELETE(`${process.env.REACT_APP_API_URL}/delete_camera/${id}`);
};

export const cameraListHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/camera_list_without_pagination`);
};