import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useScrollTrigger,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../Commons";
import { useFormik } from "formik";
import { changePasswordValidation } from "../../Helper/validation";
import { changePasswordIv } from "../../Helper/intialValues";
import { changePasswordHandler } from "../../service/ChangePassword";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const ChangePassword = ({ handleClose }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [errorDisplay, setErrorDisplay] = useState(false);
  const { t } = useTranslation();

  const submitHandler = async () => {
    try {
      if (formik.values.new_password !== formik.values.new_confirmPassword) {
        formik.setFieldError(
          "new_confirmPassword",
          "New and confirm passwords must be the same"
        );
        return;
      }
      const payload = {
        old_password: formik.values.old_password,
        new_password: formik.values.new_password,
      };

      const { data, message, success } = await changePasswordHandler(payload);
      if (success) {
        successNotification(message);
        handleClose();
        navigate(`/`);
        cookies.remove("USERTOKEN");
      } else {
        errorNotification(message);
      }
    } catch (error) {
      errorNotification("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: changePasswordIv,
    validationSchema: changePasswordValidation,
    onSubmit: submitHandler,
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("ChangePassword")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              placeholder={t("CurrentPassword")}
              passwordInput
              formik={formik}
              name="old_password"
              errorDisplay={errorDisplay}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("NewPassword")}
              passwordInput
              formik={formik}
              name="new_password"
              errorDisplay={errorDisplay}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("ConfirmPassword")}
              passwordInput
              formik={formik}
              name="new_confirmPassword"
              errorDisplay={errorDisplay}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t("Cancle")} buttonColor="gray" onClick={handleClose} />
        <Button
          buttonName={t("Save")}
          loading={loading}
          disabled={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplay(true)]}
        />
      </DialogActions>
    </>
  );
};

export default ChangePassword;
