import React from "react";
import { Chart } from "react-google-charts";

const DemographicChart = () => {
  const data = [
    ["Age Group", "Number of Visitors"],
    ["0-18", 105],
    ["18-24", 205],
    ["24-34", 305],
    ["34-44", 199],
    ["44-54", 203],
    ["60+", 50],
  ];

  const options = {
    hAxis: { title: "Age Group", titleTextStyle: { color: "#333" } },
    vAxis: { title: "Number of Visitors", minValue: 0 },
    chartArea: { width: "50%", height: "70%" },
    backgroundColor: {
      fill: "#000000",
    },
    hAxis: {
      textStyle: { color: "#FFFFFF" },
    },
    vAxis: {
      textStyle: { color: "#FFFFFF" },
    },
    legend: {
      textStyle: { color: "#FFFFFF" },
    },
    // legend: "none",
  };

  return (
    <div style={{ width: '100%' }} className="democolumnchart">
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </div>
  );
};

export default DemographicChart;
