import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Box,
  Grid,
  Pagination,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  FormLabel,
  Typography,
  Menu,
  MenuItem,
  Collapse,
  Select,
  Card,
  CardContent,
} from "@mui/material";
import { Button, Input, Modal } from "../../../Component/Commons";
import { StyledTableCell, useStyles } from "../../../Helper/Theme";
import moment from "moment";
import Enter_ExitChart from "./Enter_ExitChart";
import { useLocation } from "react-router-dom";
import { VscSettings } from "../../../Helper/Icons";
import ShoppingAnalysisCHart from "./Shopping-AnalysisChart";
import { getTrafficData } from "../../../service/TrafficAnalysis.service";
import { getAllOrgFilter, getLocationFilter } from "../../../service/Filterdata.service"

function createData(time, enter, exit, date) {
  return { time, enter, exit, date };
}

const TrafficAnalysis = () => {
  const classes = useStyles();
  const [alertData, setAlertData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [gridStyle, setGridStyle] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  const handleChangePage = (value, newPage) => {
    setCurrentPage(newPage);
  };

  const [exportFile, setExportFile] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectEvent, setSelectEvent] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [startDate, setStartDate] = useState("");
  console.log('startDate ====================', startDate);
  const [listTrafficDatas, setlistTrafficDatas] = useState([]);
  const [filterOrg, setfilterOrg] = useState([]);
  const [filterLocation, setfilterLocation] = useState([]);

  const handleTrafficData = async (
    locationId,
    orgId,
    startDate
  ) => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } = await getTrafficData(locationId, orgId, startDate);
      if (success) {
        setlistTrafficDatas(data);
      } else {
        setlistTrafficDatas(data);
      }
    } catch (error) {
      setlistTrafficDatas([]);
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const handleOrgFiltersDatas = async () => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } = await getAllOrgFilter();
      if (success) {
        setfilterOrg(data);
      } else {
        setfilterOrg(data);
      }
    } catch (error) {
      setfilterOrg([]);
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const handleLocationFiltersDatas = async () => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } = await getLocationFilter();
      if (success) {
        setfilterLocation(data);
      } else {
        setfilterLocation(data);
      }
    } catch (error) {
      setfilterLocation([]);
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const todaydata = [
    {
      title: "Peak time",
      arrivingtime: "4PM - 7PM",
      growth: "+4%",
    },
    {
      title: "Busines Hours",
      arrivingtime: "7PM - 8PM",
      growth: "+4%",
    },
    {
      title: "Avg. Customers",
      arrivingtime: "15",
      growth: "+6",
    },
  ];
  const yesterdaydata = [
    {
      title: "Peak time",
      arrivingtime: "2PM - 4PM",
      growth: "+4%",
    },
    {
      title: "Busines Hours",
      arrivingtime: "6PM - 7PM",
      growth: "+4%",
    },
    {
      title: "Avg. Customers",
      arrivingtime: "11",
      growth: "+3",
    },
  ];
  const weeklydata = [
    {
      title: "Peak time",
      arrivingtime: "2PM - 3PM",
      growth: "+6%",
    },
    {
      title: "Busines Hours",
      arrivingtime: "6PM - 8PM",
      growth: "+8%",
    },
    {
      title: "Avg. Customers",
      arrivingtime: "25",
      growth: "+6",
    },
  ];

  const submitFilter = () => {
    handleTrafficData(locationId, orgId, startDate);
  }

  const clearFilter = () => {
    setLocationId("");
    setOrgId("");
    setStartDate();
  }

  useEffect(() => {
    handleTrafficData(locationId, orgId, startDate);
    handleOrgFiltersDatas();
    handleLocationFiltersDatas();
  }, [])
  return (
    <>
      <Stack container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
            <div style={{ alignItems: "center" }}>
              <Typography mb={1}>Entry Exit Monitoring</Typography>
              <Stack
                direction="row"
                className="table_search_head"
                justifyContent="flex-end"
                width="100%"
                sx={{
                  marginBottom: { xs: "12px" },
                  marginTop: { xs: "-38px" },
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  gap: "8px",
                  alignItems: { xs: "end", sm: "center" },
                }}
              >
                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                  {/* <Button buttonName="Export" onClick={handleClickProfile} /> */}
                  <Button
                    buttonName={
                      <VscSettings
                        style={{ fontSize: "19px", margin: "4px 0" }}
                      />
                    }
                    style={{ minWidth: "25px", padding: "6px 13px" }}
                    onClick={handleFilterClick}
                  />
                </Stack>
              </Stack>
              {/* filter */}
              <Collapse in={showFilter}>
                <Stack
                  mb={"12px"}
                  sx={{ background: "#151515" }}
                  flexDirection={"row "}
                  gap={2}
                  flexWrap={"wrap"}
                  justifyContent={"flex-end"}
                  p={1}
                  alignItems={"center"}
                >

                  <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Organazations:
                    </Typography>
                    <Select
                      name={"org"}
                      value={orgId}
                      onChange={(e) => setOrgId(e.target.value)}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" hidden disabled>Select Org.</MenuItem>
                      {
                        filterOrg?.map((data, i) => {
                          return (
                            <MenuItem
                              value={data.id}
                              key={i}>
                              {data?.org_name}
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </Grid>
                  <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Locations:
                    </Typography>
                    <Select
                      name={"locationId"}
                      value={locationId}
                      onChange={(e) => setLocationId(e.target.value)}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" hidden disabled>Select Location.</MenuItem>
                      {
                        filterLocation?.map((data, i) => {
                          return (
                            <MenuItem
                              value={data.id}
                              key={i}>
                              {data?.loc_name}
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </Grid>
                  {/* <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Total Enter:
                    </Typography>
                    <Input placeholder="Enter Total Enter" type="text" />
                  </Grid>
                  <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Total Exit:
                    </Typography>
                    <Input placeholder="Search  Total Exit" type="text" />
                  </Grid> */}
                  <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Start Date:
                    </Typography>
                    <Input type="date" onChange={(e) => setStartDate(e.target.value)} />
                  </Grid>
                  {/* <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      End Date:
                    </Typography>
                    <Input type="date" />
                  </Grid> */}
                  {/* <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Check in:
                    </Typography>
                    <Input type="time" />
                  </Grid>
                  <Grid className="filterinput">
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      Check out:
                    </Typography>
                    <Input type="time" />
                  </Grid> */}
                  <Box sx={{ mx: 1 }}>
                    <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} onClick={() => submitFilter()} />
                    <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} onClick={() => clearFilter()} />
                  </Box>
                </Stack>
              </Collapse>
            </div>
            <Enter_ExitChart listTrafficDatas={listTrafficDatas} />
          </Box>
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableCell>Number</TableCell>
            <StyledTableCell>Time</StyledTableCell>
            <StyledTableCell>Enter</StyledTableCell>
            <StyledTableCell>Exit</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
          </TableHead>
          <TableBody>
            {listTrafficDatas && listTrafficDatas.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell component="th" scope="row">
                  {row?.duration}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.entry_count}
                </TableCell>
                <TableCell align="left">{row?.exit_count}</TableCell>
                <TableCell align="left">{row?.date || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Pagination
          count={Math.ceil(dataCount / pageLimit)}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      <Stack container spacing={2} sx={{ marginTop: "30px" }}>
        <Grid item xs={12} md={12}>
          <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
            <div style={{ alignItems: "center" }}>
              <Typography mb={1}>Peak Shopping Times Analysis</Typography>
              <Stack
                direction="row"
                className="table_search_head"
                justifyContent="flex-end"
                width="100%"
                sx={{
                  marginBottom: { xs: "12px" },
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  gap: "8px",
                  alignItems: { xs: "end", sm: "center" },
                }}
              >
                {/* <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                  <Button buttonName="Export" onClick={handleClickProfile} />
                  <Button
                    buttonName={
                      <VscSettings
                        style={{ fontSize: "19px", margin: "4px 0" }}
                      />
                    }
                    style={{ minWidth: "25px", padding: "6px 13px" }}
                    onClick={handleFilterClick}
                  />
                </Stack> */}
              </Stack>
              {/* <Collapse in={showFilter}>
                <Stack
                  mb={"12px"}
                  sx={{ background: "#151515" }}
                  flexDirection={"row "}
                  gap={1}
                  justifyContent={"flex-end"}
                  p={1}
                  alignItems={"center"}
                >
                  <Select
                    value={selectEvent ? selectEvent : "none"}
                    onChange={eventHandler}
                    displayEmpty
                    multiple
                    sx={{ width: "150px" }}
                    placeholder="asdasd"
                  >
                    <MenuItem value="none" hidden disabled>
                      select
                    </MenuItem>
                    {eventsList &&
                      eventsList.map((res) => {
                        return (
                          <MenuItem value={res.value}>{res.name}</MenuItem>
                        );
                      })}
                  </Select>
                  <Select
                    value={selectEvent ? selectEvent : "none"}
                    onChange={eventHandler}
                    displayEmpty
                    multiple
                    sx={{ width: "150px" }}
                  >
                    <MenuItem value="none" hidden disabled>
                      select
                    </MenuItem>

                    {time &&
                      time.map((res) => {
                        return (
                          <MenuItem value={res.value}>{res.name}</MenuItem>
                        );
                      })}
                  </Select>
                  <Box sx={{ mx: 1 }}>
                    <VscSettings style={{ fontSize: "25px" }} />
                  </Box>
                </Stack>
              </Collapse> */}
            </div>
            <ShoppingAnalysisCHart />
            {/* <div style={{ marginTop: "20px" }}>
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
                Today
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {todaydata.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      minWidth: 280,
                      width: "345px",
                      backgroundColor: "black",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "400px",
                            color: "gray",
                          }}
                        >
                          {item.title}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "25px",
                                fontWeight: "300",
                                color: "white",
                              }}
                            >
                              {item.arrivingtime}
                            </span>
                            <span
                              style={{
                                color: "green",
                                fontWeight: "900",
                                fontSize: "10px",
                              }}
                            >
                              {item.growth}
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
                Yesterday
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {yesterdaydata.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      minWidth: 280,
                      width: "345px",
                      backgroundColor: "black",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "400px",
                            color: "gray",
                          }}
                        >
                          {item.title}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "25px",
                                fontWeight: "300",
                                color: "white",
                              }}
                            >
                              {item.arrivingtime}
                            </span>
                            <span
                              style={{
                                color: "green",
                                fontWeight: "900",
                                fontSize: "10px",
                              }}
                            >
                              {item.growth}
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
                This Week
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {weeklydata.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      minWidth: 280,
                      width: "345px",
                      backgroundColor: "black",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "400px",
                            color: "gray",
                          }}
                        >
                          {item.title}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "25px",
                                fontWeight: "300",
                                color: "white",
                              }}
                            >
                              {item.arrivingtime}
                            </span>
                            <span
                              style={{
                                color: "green",
                                fontWeight: "900",
                                fontSize: "10px",
                              }}
                            >
                              {item.growth}
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div> */}
          </Box>
        </Grid>
      </Stack>
    </>
  );
};

export default TrafficAnalysis;
