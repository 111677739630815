import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  tabClasses,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { useFormik } from "formik";
import { roleList } from "../../Helper/Helper";
import { addUserIv } from "../../Helper/intialValues";
import { addUserValidation, updateUserValidation } from "../../Helper/validation";
import { registerHandler, updateUserByAdmin } from "../../service/User.service";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { useTranslation } from "react-i18next";
import { getGroups } from "../../service/role.service";

function AddUser({ handleClose, modalOpen, closeFlag }) {
  const { t } = useTranslation();

  const [selectRole, setSelectRole] = useState("");
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = React.useState([]);


  const response = modalOpen?.data?.totalData
  useEffect(() => {
    if (modalOpen.isEdit) {
      getUserDetails();
    }
  }, []);

  const getGroupData = async () => {
    const res = await getGroups();
    if (res.success) {
      setGroupData(res.data);
    } else {
      setGroupData([]);
    }
  }
  useEffect(() => {
    getGroupData();
  }, [])

  const getUserDetails = () => {
    formik.setValues({
      ...formik.values,
      first_name: response.first_name,
      last_name: response.last_name,
      email: response.email,
      group: response.groups
    })
    setSelectRole(response.groups);
  };

  const roleHandler = (event) => {
    setSelectRole(event.target.value);
    formik.setFieldValue("group", event.target.value);
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        group_name: formik.values.group,
      }
      const { data, message, success } = modalOpen.isEdit ? await updateUserByAdmin(response.id, payload) : await registerHandler(formik.values);
      if (success) {
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleClose();
      closeFlag()
    }
  };

  const formik = useFormik({
    initialValues: addUserIv,
    validationSchema: modalOpen.isEdit ? updateUserValidation : addUserValidation,
    onSubmit: submitHandler,
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? `${t("Edit")}` : `${t("Add")}`} {t("User")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              placeholder={t("FirstName")}
              name="first_name"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("LastName")}
              name="last_name"
              formik={formik}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder={t("Email")}
              name="email"
              formik={formik}
              disabled={modalOpen.isEdit}
              errorDisplay={errorDisplayFlag}
            />
          </Grid>
          {
            !modalOpen.isEdit && (
              <Grid item xs={12}>
                <Input
                  placeholder={t("Password")}
                  name="password"
                  formik={formik}
                  //auto complete off
                  autoComplete="new-password"
                  passwordInput
                  errorDisplay={errorDisplayFlag}
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Typography fontSize={"14px"} fontWeight={600} marginBottom={"6px"}>
              {t("Role")}
            </Typography>
            <Select
              value={formik.values.group}
              onChange={roleHandler}
              displayEmpty
              fullWidth
            >
              {groupData &&
                groupData.map((res, i) => {
                  return (
                    <MenuItem value={res.group_id} key={i}>
                      {res.group_name}
                    </MenuItem>
                  );
                })}
            </Select>
            {errorDisplayFlag && (
              <FormHelperText>{formik.errors.group}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={modalOpen.isEdit ? `${t("Save")}` : `${t("Add")}`}
          loading={loading}
          onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
          size="small"
        />
        <Button
          buttonName={t("Cancel")}
          color="secondary"
          style={{ color: "#fff", borderColor: "#2c2c2c" }}
          size="small"
          variant="outlined"
          onClick={() => [handleClose(), closeFlag()]}
        />
      </DialogActions>
    </>
  );
}

export default AddUser;
