import React from "react";
import { Box, DialogContent, Grid, Typography } from "@mui/material";
import { FaCheck } from "../../Helper/Icons";
import { Button } from "../../Component/Commons";

const ExportSuccess = ({ handleClose }) => {
  return (
    <DialogContent>
      <Grid container gap={2} width={{ xs: "250px", sm: "400px" }} paddingY={1}>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Box
            sx={{
              background: "#060606",
              borderRadius: "50%",
              padding: "15px",
              width: "100px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaCheck style={{ color: "#05EE3B", fontSize: "40px" }} />
          </Box>
        </Grid>
        <Grid item xs={12} marginBottom={"20px"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"500"}>Export Successfully</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button buttonName="Done" fullWidth onClick={handleClose} />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default ExportSuccess;
