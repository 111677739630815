import { DELETE, get, post, put } from "../web.request";

export const getAllOrgFilter = () => {
    return get(`${process.env.REACT_APP_API_URL}/create_organization`)
};

export const getAllCameraFilter = () => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera`)
};

export const getLocationFilter = () => {
    return get(`${process.env.REACT_APP_API_URL}/create_location`)
};

//https://vademoapi.mobiginie.com/create_location