import { DELETE, get, post, put } from "../web.request";

export const getLocationList = (locationId,orgId,startDate,endDate,search) =>{
return get(`${process.env.REACT_APP_API_URL}/create_location?location_id=${locationId}&organization_id=${orgId}&start_date=${startDate}&end_date=${endDate}&search=${search}`)
};


export const createLocation = (payload) =>{
    return post(`${process.env.REACT_APP_API_URL}/create_location`,payload);
};

export const updateLocation = (id,payload) =>{
    return put(`${process.env.REACT_APP_API_URL}/update_location/${id}`,payload);
};


export const deleteLocation = (id) =>{
    return DELETE(`${process.env.REACT_APP_API_URL}/delete_location/${id}`)
};

export const locationByOrgId = (orgId,search) =>{
    return get(`${process.env.REACT_APP_API_URL}/location_list?organization_id=${orgId}&search=${search}`)
};