import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "../../Component/Commons";
import {
  GrFormAdd,
  VscSettings,
  RiSearchLine,
  GoDotFill,
} from "../../Helper/Icons";
import {
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { getOrgList } from "../../service/Organization.service";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Organization() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [organizationList, setOrganizationList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });

  const searchHandler = (event) => {
    const inputText = event.target.value;
    getOrganizationList(inputText, 1);
  };

  const closeFlagHandler = () => {
    getOrganizationList(searchText, currentPage);
  };

  const deleteHandler = (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "delete",
      isEdit: false,
      data: {
        head: `${t("RemoveOrganization")}`,
        message: `${t("DoyouwanttoRemovethisOrganization?")}`,
        id: id,
        name: "ORGANIZATION",
      },
    });
  };

  useEffect(() => {
    getOrganizationList(searchText, currentPage);
  }, []);

  const getOrganizationList = async (searchText, currentPage) => {
    const { count, data, message, success, totalPage } = await getOrgList(
      searchText,
      currentPage
    );
    if (success) {
      setDataCount(count);
      setOrganizationList(data);
    } else {
      setOrganizationList(data);
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const title = "Organization -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("SearchforUser")}
            onChange={(event) => searchHandler(event)}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          <Button
            buttonName={t("AddOrganization")}
            startIcon={<GrFormAdd />}
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "addOrganization",
                isEdit: false,
              })
            }
          />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("OrganizationName")}:
            </Typography>
            <Input placeholder={t("EnterOrganizationName")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("UserName")}:
            </Typography>
            <Input placeholder={t("EnterUserName")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Address")} :
            </Typography>
            <Input placeholder={t("EnterAddress")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Email")} :
            </Typography>
            <Input placeholder={t("EnterEmail")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Phone")} :
            </Typography>
            <Input placeholder={t("EnterPhone")} type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("StartDate")}:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("EndDate")}:
            </Typography>
            <Input type="date" />
          </Grid>
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("OrganizationName")}</StyledTableCell>
              <StyledTableCell> {t("UserName")}</StyledTableCell>
              <StyledTableCell> {t("Address")} </StyledTableCell>
              <StyledTableCell> {t("Email")} </StyledTableCell>
              <StyledTableCell>{t("Phone")}</StyledTableCell>
              <StyledTableCell>{t("Action")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationList && organizationList.length > 0 ? (
              organizationList.map((res) => {
                return (
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewOrganization",
                        data: {
                          totalData: res,
                        },
                      })
                    }
                  >
                    <TableCell component="th" scope="row">
                      {res.org_name ? res.org_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.organization_user &&
                        res.organization_user.length > 0 &&
                        res.organization_user.map((response) => {
                          return (
                            <>
                              {" "}
                              {response.first_name} {response.last_name}
                            </>
                          );
                        })}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.org_address ? res.org_address : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.org_email ? res.org_email : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {res.org_phone ? res.org_phone : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(res.id);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("Norecordstodisplay")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </HelmetProvider>
  );
}

export default Organization;
