import { get, post, patch } from "../web.request";

export const rolePermissionList = async (query) => {
    return await get(`${process.env.REACT_APP_API_URL}/permission_list${query}`);
}

export const rolePermissionAssign = async (data) => {
    return await post(`${process.env.REACT_APP_API_URL}/group_permission`, data);
}

export const getGroups = async () => {
    return await get(`${process.env.REACT_APP_API_URL}/group_list`);
}

//create_group
export const createGroup = async (data) => {
    return await post(`${process.env.REACT_APP_API_URL}/create_group`, data);
}