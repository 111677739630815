import { Box, Collapse, Grid, Menu, MenuItem, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Select, Dialog } from '@mui/material';
import React, { useEffect, useState } from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Button, Input } from '../../Component/Commons';
import { RiSearchLine } from 'react-icons/ri';
import { BsFiletypeCsv } from 'react-icons/bs';
import { t } from 'i18next';
import { VscSettings } from 'react-icons/vsc';
import { useTranslation } from "react-i18next";
import { useStyles } from '../../Helper/Theme';
import worker from '../../assets/image/worker.jpg'
import Model_PPE_KIT from './Model_PPE_KIT';

const ppekitdata = [
    {
        id: '0001',
        img: worker,
        location: 'First floor',
        eventtype: 'Shoes',
        orgnation: "VN Constructions	",
        datetime: '23-08-2024 11:29:58',
        redcategory: ["helmet", "jacket"],
        greencategory: ["shoes", 'Gloves']
    },
    {
        id: '0002',
        img: worker,
        location: 'First floor',
        eventtype: 'Helmet',
        orgnation: "VN Constructions	",
        datetime: '23-08-2024 11:29:58',
        redcategory: ["helmet",  'Gloves'],
        greencategory: ["shoes","jacket",]
    },
    {
        id: '0003',
        img: worker,
        location: 'First floor',
        eventtype: 'Gate',
        orgnation: "VN Constructions	",
        datetime: '23-08-2024 11:29:58',
        redcategory: ["helmet", "jacket", "shoes", 'Gloves'],
        greencategory: []
    },
    {
        id: '0004',
        img: worker,
        location: 'First floor',
        eventtype: 'Gloves',
        orgnation: "VN Constructions	",
        datetime: '23-08-2024 11:29:58',
        redcategory: [],
        greencategory: ["shoes", 'Gloves', "helmet", "jacket"]
    }
]

const Ppe_kit_detection = () => {
    const title = "PPE Kit Detection -  Video Analytics Software Solutions - VISNX";
    const description =
        "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";
    const classes = useStyles();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        isEdit: "",
    });
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [searchText, setSearchText] = useState("");
    const [exportFile, setExportFile] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [alrtMsg, setAlrtMsg] = useState("");
    const [selectedEvent, setSelectedEvent] = useState("");
    const [ppekitDetailsPopup, setppekitDetailsPopup] = useState(false);
    const [selectedPPE_KIT, setselectedPPE_KIT] = useState(null);

    const handleClickProfile = (event) => {
        setExportFile(event.currentTarget);
    };

    const searchHandler = (event) => {
        setSearchText(event.target.value);
    };
    const handleCloseProfile = () => {
        setExportFile(null);
    };
    const handleFilterClick = () => {
        setShowFilter(!showFilter);
    };
    const handleSubmitFilter = () => {
        setCurrentPage(1);
    }
    const clearFilter = () => {
        setStartDate("");
        setEndDate("");
        setShowFilter(!showFilter);
        setSelectedEvent("")
    }
    const closePopup = () => {
        setppekitDetailsPopup(false)
    }

    const handleModelOpen = () => {
            setppekitDetailsPopup(true)
      setselectedPPE_KIT()

    }
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Stack
                direction="row"
                className="table_search_head"
                justifyContent="space-between"
                width="100%"
                sx={{
                    marginBottom: { xs: "12px" },
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    gap: "8px",
                    alignItems: { xs: "end", sm: "center" },
                }}
            >
                <Box className="search_field">
                    <Input
                        hiddenLabel
                        placeholder={t("SearchforPPE Kit")}
                        value={searchText}
                        onChange={(event) => searchHandler(event)}
                        iconInput={<RiSearchLine style={{ color: "#292929" }} />}
                        style={{
                            width: { xs: "100%", sm: "300px" },
                            "& .MuiInputBase-root": {
                                border: "1px solid #151515 !important",
                                input: {
                                    background: "#151515 !important",
                                    padding: "8px 15px",
                                },
                            },
                        }}
                    />
                </Box>

                <Menu
                    //   anchorEl={exportFile}
                    //   open={Boolean(exportFile)}
                    onClose={handleCloseProfile}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        value="csv"
                        // onClick={() =>
                        //   setIsModalOpen({
                        //     open: true,
                        //     currentComponent: "exportSuccess",
                        //   })
                        // }
                        // onClick={(e) => handleDownloadFile(e)}
                        onMouseUp={handleCloseProfile}
                    >
                        <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
                            <BsFiletypeCsv style={{ fontSize: "18px" }} />
                            <div style={{ display: "flex" }}>{t("csv")}</div>
                        </Stack>
                    </MenuItem>
                </Menu>

                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <Button buttonName={t("Export")} onClick={handleClickProfile} />
                    <Button
                        buttonName={
                            <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
                        }
                        style={{ minWidth: "25px", padding: "6px 13px" }}
                        onClick={handleFilterClick}
                    />
                </Stack>
            </Stack >
            {/* filter */}
            <Collapse Collapse in={showFilter}>
                <Stack
                    mb={"12px"}
                    sx={{ background: "#151515" }}
                    flexDirection={"row "}
                    gap={2}
                    flexWrap={"wrap"}
                    justifyContent={"flex-end"}
                    p={1}
                    alignItems={"center"}
                >
                    <Grid className="filterinput">
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {t("ID")}:
                        </Typography>
                        <Input placeholder={t("Search ID")} type="text" value={alrtMsg} onChange={(e) => setAlrtMsg(e.target.value)} />
                    </Grid>
                    <Grid className="filterinput">
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {t("Location")}:
                        </Typography>
                        <Input placeholder={t("Search Location ")} type="text" value={alrtMsg} onChange={(e) => setAlrtMsg(e.target.value)} />
                    </Grid>
                    <Grid className="filterinput">
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {t("Event")}
                        </Typography>
                        <Select
                            name={"org"}
                            // value={selectedEvent}
                            onChange={(e) => setSelectedEvent(e.target.value)}
                            displayEmpty
                            fullWidth
                        >
                            <MenuItem value="" hidden disabled>{t("SelectEvent")}</MenuItem>
                            <MenuItem>Helmet</MenuItem>
                            <MenuItem>Jacket</MenuItem>
                            <MenuItem>Shoes</MenuItem>
                            <MenuItem>Gloves</MenuItem>

                        </Select>
                    </Grid>
                    <Grid className="filterinput">
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {t("Organization")}:
                        </Typography>
                        <Input placeholder={t("Search Organization Name ")} type="text" value={alrtMsg} onChange={(e) => setAlrtMsg(e.target.value)} />
                    </Grid>
                    <Grid className="filterinput">
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {t("StartDate")}:
                        </Typography>
                        <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Grid>
                    <Grid className="filterinput">
                        <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                            {t("EndDate")}:
                        </Typography>
                        <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}
                            min={startDate}
                        />
                    </Grid>
                    <Box sx={{ mx: 1 }}>
                        <Button buttonName={t("Filter")} onClick={() => handleSubmitFilter()} />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                        <Button buttonName={t("Clear")} onClick={() => clearFilter()} />
                    </Box>
                </Stack>
            </Collapse >
            <Stack>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>
                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0, flexWrap: "wrap", gap: "2px" }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="red"></div>Gloves
                                            </span>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="red"></div>Jacket
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="green"></div>Helmet
                                            </span>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="green"></div>Shoes
                                            </span>
                                        </div>
                                    </div> */}
                                    Categories
                                </TableCell>
                                <TableCell>LocationName</TableCell>
                                <TableCell>EventType</TableCell>
                                <TableCell>OrganizationName</TableCell>
                                <TableCell>Date&Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ppekitdata.map((data, index) => (
                                <TableRow key={index}
                                    onClick={() => handleModelOpen()}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        cursor: "pointer",
                                    }}
                                >
                                    <TableCell>{data.id}</TableCell>
                                    <TableCell>
                                        <img src={data.img} alt="worker" width={40} height={40} style={{ objectFit: 'cover' }} />
                                    </TableCell>
                                    <TableCell>
                                        <div style={{display:'flex',flexDirection:"column"}}>
                                            <Grid item xs={6} container direction="column">
                                                {data.greencategory.map((item, idx) => (
                                                    <Grid item key={idx}>
                                                        <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                            <div className="green"></div>{item}
                                                        </span>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Grid item xs={6} container direction="column">
                                                {data.redcategory.map((item, idx) => (
                                                    <Grid item key={idx}>
                                                        <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                            <div className="red"></div>{item}
                                                        </span>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    </TableCell>
                                    <TableCell>{data.location}</TableCell>
                                    <TableCell>{data.eventtype}</TableCell>
                                    <TableCell>{data.orgnation}</TableCell>
                                    <TableCell>{data.datetime}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Stack>
            <Stack
                sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
                flexDirection="row"
                justifyContent="space-between"
            >
                {/* <div>
                    {`${t("Showing")} ${alertData ? alertData.length : 0}
          ${t("of")} ${dataCount ?? 0} ${t("Results")}`}
                </div> */}
                <Pagination
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>
            <Dialog
                onClose={closePopup}
                aria-labelledby="Common_modal"
                open={ppekitDetailsPopup}
            >
                <Model_PPE_KIT handleClose={closePopup} selectedPPE_KIT={selectedPPE_KIT}/>
            </Dialog>
        </HelmetProvider >
    )
}

export default Ppe_kit_detection