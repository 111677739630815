import React from "react";
import Chart from "react-google-charts";

export const data = [
  [
    "Entry",
    "Visitor",
  ],
  ["10:00-AM", 50,], 
  ["10:15-AM", 30,],
  ["10:30-AM", 22,],
  ["10:45-AM", 11,],
  ["11:00-AM", 11,],
  ["11:15-AM", 80],
  ["11:30-AM", 77,],
  ["11:45-AM", 62,],
  ["12:00-PM", 46,],
  ["12:15-PM", 32,],
  ["12:30-PM", 53,],
  ["12:45-PM", 69,],
  ["13:00-PM", 41,],
  ["13:15-PM", 34,],
  ["13:30-PM", 24,],
  ["13:45-PM", 84,],
  ["14:00-PM", 14],
  ["14:15-PM", 64,],
  ["14:30-PM", 34,],
  ["14:45-PM", 94,],
  ["15:00-PM", 54,],
  ["15:15-PM", 74,],
];

export const options = {
  chart: {
    title: "Customer Entry/Exit Chart",
  },
  backgroundColor: {
    fill: '#151515',  
  },
  hAxis: {
    textStyle: { color: '#FFFFFF' }  
  },
  vAxis: {
    textStyle: { color: '#FFFFFF' }  
  },
  legend: {
    textStyle: { color: '#FFFFFF' }  
  },
  // curveType: "function",
  legend: 'none',
  chartArea: { width: '90%'},
};

const VisitorsLineChart = () => {
  return (
    <div>
      <Chart
        chartType="LineChart"
        height={'300px'}
        width="100%"
        data={data}
        options={options}
      />
    </div>
  );
};

export default VisitorsLineChart;
