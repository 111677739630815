import { DELETE, get, post, put } from "../web.request"

export const getOrgList = (searchText,page) =>{
    return get(`${process.env.REACT_APP_API_URL}/create_organization?search=${searchText}&page=${page}`);
}

export const createOrg = (payload) =>{
    return post(`${process.env.REACT_APP_API_URL}/create_organization`,payload);
};

export const editOrgHandler = (id,payload) =>{
    return put(`${process.env.REACT_APP_API_URL}/update_organization/${id}`,payload);
};

export const deleteOrgHandler = (id) =>{
    return DELETE(`${process.env.REACT_APP_API_URL}/delete_organization/${id}`)
};


export const getOrgDataHandler = (search) =>{
    return get(`${process.env.REACT_APP_API_URL}/organization_list?search=${search}`);
}