import React, { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Menu,
  MenuItem,
  Select,
  Collapse,
  Typography,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import {
  VscSettings,
  RiSearchLine,
  GoDotFill,
  BsFiletypeCsv,
  BsFiletypeExe,
} from "../../Helper/Icons";
import { reportView } from "../../Helper/Constant";





const Report = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [exportFile, setExportFile] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectEvent, setSelectEvent] = useState([]);
  const eventHandler = (event) => {
    setSelectEvent(event.target.value);
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const eventsList = [
    { name: "Helmet ", value: "Helmet" },
    { name: "Glasses", value: "Glasses" },
    { name: "Gloves", value: "Gloves" },
    { name: "Jackets", value: "Jackets" },
    { name: "Shoes", value: "Shoes" },
  ];
  const handleClickProfile = (event) => {
    setExportFile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setExportFile(null);
  };

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  // const reportView = "https://unsplash.com/photos/man-standing-beside-wall-pAtA8xe_iVM"; // Replace with your image path

  return (
    <>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder="Search for Report"
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Button buttonName="Export" onClick={handleClickProfile} />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
        <Menu
          anchorEl={exportFile}
          open={Boolean(exportFile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeCsv style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>csv</div>
            </Stack>
          </MenuItem>
          <MenuItem
            value="excel"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeExe style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>excel</div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Events:
            </Typography>
            <Input placeholder="Enter Events" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Location:
            </Typography>
            <Input placeholder="Enter Location" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Area:
            </Typography>
            <Input placeholder="Enter Area" type="text" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Start Date:
            </Typography>
            <Input type="date" />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              End Date:
            </Typography>
            <Input type="date" />
          </Grid>
         
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Camera
            </Typography>
            <Input placeholder="Enter Camera Number" type="text"/>
          </Grid>
          {/* <Select
            value={selectEvent ? selectEvent : 'none'}
            onChange={eventHandler}
            multiple
            sx={{ width: '150px' }}
            placeholder='Select'
          >
            <MenuItem value="none" selected  >select</MenuItem>
            {eventsList && eventsList.map((res) => {
              return (
                <MenuItem value={res.value}>{res.name}</MenuItem>
              )
            })}
          </Select>
          <Select
            value={selectEvent ? selectEvent : 'none'}
            onChange={eventHandler}
            displayEmpty
            multiple
            sx={{ width: '150px' }}
          >
            <MenuItem value="none" hidden disabled>select</MenuItem>

            {eventsList && eventsList.map((res) => {
              return (
                <MenuItem value={res.value}>{res.name}</MenuItem>
              )
            })}
          </Select> */}
          <Box sx={{ mx: 1 }}>
            <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Image</StyledTableCell>
            <StyledTableCell>Events</StyledTableCell>
            <StyledTableCell>Location</StyledTableCell>
            <StyledTableCell>Area</StyledTableCell>
            <StyledTableCell>Time & Date</StyledTableCell>
            <StyledTableCell>Cameras</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[1,1,1,1].map((data, i) => (
            <StyledTableRow
              key={i}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                className={classes.imageContainer}
                onClick={() => handleImageClick(reportView)}
              >
                <img
                  src={reportView}
                  width={40}
                  height={40}
                  style={{ objectFit: "cover" }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                  Helmet <GoDotFill /> No
                </Stack>
              </TableCell>
              <TableCell component="th" scope="row">
                Ahmadabad, India
              </TableCell>
              <TableCell component="th" scope="row">
                Science City Road
              </TableCell>
              <TableCell component="th" scope="row">
                28th Aug at 12:30 PM
              </TableCell>
              <TableCell component="th" scope="row">
                Camera 1
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img src={selectedImage} alt="Selected" style={{ height:"100%" }} />
        </DialogContent>
      </Dialog>
    </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {/* <div>
          Showing{" "}
          1{" "}
          of 1 Results
        </div> */}
        {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
    </>
  );
};

export default Report;
