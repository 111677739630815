import { get } from "../web.request";

export const DashboardHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/alert_event_camera_location_count`);
};

export const reportChartHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/undetected_datewise_event_count`)
};

export const analyticsDataHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/undetected_event_percentage`);
};

export const cameraDataHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera`);
};

export const eventDataHandler = (search, page, dashboard) => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera_event?search=${search}&page=${page}&dashboard=${dashboard}`);
};
export const eventDataHandlerSelectBox = (dashboard) => {
    return get(`${process.env.REACT_APP_API_URL}/create_camera_event?&dashboard=${dashboard}`);
};

export const topFourDataHandler = () => {
    return get(`${process.env.REACT_APP_API_URL}/top_four_alert_data`);
};