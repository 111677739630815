// import * as React from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import { Typography } from '@mui/material';

// export default function CommonSelect(props) {
//     const { selectHead, selectList, name, labelSelect, style, className, formik } = props;

//     return (
//         <>
//             {labelSelect ?
//                 <Typography fontSize={'14px'} fontWeight={600} marginBottom={'6px'}>{labelSelect}</Typography>
//                 : ''
//             }
//             <Select
//                 name={name} // Set the 'name' attribute for formik
//                 value={formik.values[name]} // Use formik's field value
//                 onChange={formik.handleChange} // Use formik's handleChange
//                 displayEmpty
//                 fullWidth
//                 sx={style}
//                 className={className}
//             >
//                 <MenuItem value="" hidden disabled>{selectHead}</MenuItem>
//                 {
//                     selectList?.map((data, i) => {
//                         return (
//                             <MenuItem
//                                 value={data.value || data.id}
//                                 key={i}>
//                                 {data.name || data?.org_name || data?.area_name}
//                             </MenuItem>
//                         )
//                     })
//                 }
//             </Select>
//             {(formik?.touched[props.name1] && formik?.errors[props.name]) &&
//                 <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{formik?.errors[props.name]}</p>
//             }
//         </>
//     );
// }

import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';

export default function CommonSelect(props) {
    const { selectHead, selectList, labelinput, style, value, className, onChange, formik, disabled, variant, placeholder } = props;


    return (
        <FormControl variant={variant} sx={{ width: '100%' }}>
            {labelinput && <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
            <Select
                value={value}
                onChange={onChange}
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                fullWidth
                sx={style}
                placeholder={placeholder}
                className={className}
                error={formik && formik.touched[props.name] && formik.errors[props.name]}
                disabled={disabled}
            >
                {selectHead && <MenuItem value="" >{selectHead}</MenuItem>}
                {selectList.map((data, i) => <MenuItem value={data.id} key={i}>{data.name || data?.roleName
                }</MenuItem>)}
            </Select>
            {formik && formik.touched[props.name] && formik.errors[props.name] &&
                <Typography fontSize={'12px'} color={'#D32F2F'}>{formik.errors[props.name]}</Typography>
            }
        </FormControl>
    );
}