// App.js
import React from 'react';
import { Chart } from 'react-google-charts';

const data = [
  ['Gender', 'Count'],
  ['Male', 60],
  ['Female', 40],
];

const options = {
  title: 'Gender Distribution',
  pieHole: 0.4,
  backgroundColor: {
    fill: "#000000",
  },
  hAxis: {
    textStyle: { color: "#FFFFFF" },
  },
  vAxis: {
    textStyle: { color: "#FFFFFF" },
  },
  legend: {
    textStyle: { color: "#FFFFFF" },
  },
//   legend: "none",
};

function DemographicPIEchart() {
  return (
    <div style={{ width: '100%'}}>
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width="100%"
        height={"400px"}
      />
    </div>
  );
}

export default DemographicPIEchart;
