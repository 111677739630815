import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { LoginImage, Logo } from "../../Helper/Constant";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "../../Component/Commons";
import { FcGoogle } from "../../Helper/Icons";
import { useFormik } from "formik";
import { LoginIv } from "../../Helper/intialValues";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { loginValidation } from "../../Helper/validation";
import { loginHandler } from "../../service/User.service";
import Cookies from "universal-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const USERTOKEN = localStorage.getItem("userToken");
  const cookies = new Cookies();


  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  useEffect(() => {
    const userToken = cookies.get('USERTOKEN');
    if (userToken) {
      navigate(`/dashboard`);
    }
  }, []);

  useEffect(() => {
    const userEmail = cookies.get("userEmail");
    const userPassword = cookies.get("userPassword");
    if (userEmail && userPassword) {
      setRememberMe(true);
      formik.setValues({
        ...formik.values,
        email: userEmail,
        password: userPassword
      });
    }
  }, []);

  const submitHandler = async () => {
    try {
      const { data, message, success } = await loginHandler(formik.values);
      if (success) {
        cookies.set("USERTOKEN", data.access);
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("token", data.access);

        if (rememberMe) {
          cookies.set("userEmail", formik.values.email);
          cookies.set("userPassword", formik.values.password);
        } else {
          cookies.remove("userEmail");
          cookies.remove("userPassword");
        }
        navigate(`/dashboard`);
        successNotification(message);
      } else {
        errorNotification(message);
      }

    } catch (error) {
      errorNotification("Error saving token");
    }
  };





  const formik = useFormik({
    initialValues: LoginIv,
    validationSchema: loginValidation,
    onSubmit: submitHandler,
  });
  return (
    <Box height={"100vh"}>
      <Grid container height={"100%"} sx={{ overflow: "hidden" }}>
        <Grid
          item
          xs={12}
          md={6}
          margin={"auto"}
          sx={{ overflow: "auto", height: "100%" }}
        >
          <Box
            padding={3}
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Box>
              <img src={Logo} alt="logo" />
            </Box>
            <Box sx={{ width: "100%", margin: "auto", maxWidth: "350px" }}>
              <Stack gap={3}>
                <Box>
                  <Typography fontSize={"28px"} fontWeight={"400"}>
                    Log In
                  </Typography>
                  <Typography fontSize={"18px"} fontWeight={"400"}>
                    Start your Journey with us!
                  </Typography>
                </Box>
                <Stack gap={2}>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    errorDisplay={errorDisplay}
                    formik={formik}
                  />
                  <Input
                    placeholder="Password"
                    passwordInput
                    name="password"
                    errorDisplay={errorDisplay}
                    formik={formik}
                  />
                </Stack>
                <Box>
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Grid item>
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item>
                          <Checkbox
                            defaultChecked
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                            sx={{
                              padding: "0",
                              marginRight: "5px",
                              "& .MuiSvgIcon-root": { fontSize: 22 },
                            }}
                          />
                        </Grid>
                        <Grid item>Remember me</Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item>
                      <Link to="/forgot-password">Forget Password?</Link>
                    </Grid> */}
                  </Grid>
                </Box>
                <Box>
                  <Button
                    buttonName="Log In"
                    fullWidth
                    onClick={() => [formik.handleSubmit(), setErrorDisplay(true)]}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ position: "relative", height: "100%" }}>
          <img src={LoginImage} alt="LoginImage" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
