import { get, post } from "../web.request";

export const LprDataHandler = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate, selectedEvent) => {
  return get(`${process.env.REACT_APP_API_URL}/anpr_alert_get_list?search=${searchText}&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&start_date=${startDate}&end_date=${endDate}`);
};


export const exportCSV = (searchText, page, selectedLocation, selectedOrg, camEventId, alrtMsg, startDate, endDate) => {
  return get(`${process.env.REACT_APP_API_URL}/export_anpr_csv_report?search=${searchText}&page=${page}&organization_id=${selectedOrg}&location_id=${selectedLocation}&camera_id=${camEventId}&alert_message=${alrtMsg}&start_date=${startDate}&end_date=${endDate}`);
};

//anpr_alert_get
export const getanprAlertByID = (id) => {
  return get(`${process.env.REACT_APP_API_URL}/anpr_alert_get/${id}`);
};
