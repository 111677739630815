import React, { useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button, Modal } from '../../Component/Commons'
import { useTranslation } from "react-i18next";

const ViewUser = ({ handleClose, modalOpen, closeFlag }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: ""
  });
  const response = modalOpen.data.totalData;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("DetailsOfuser")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Email")}</Grid>
                <Grid item xs={7}>{response?.email ? response?.email : "-"}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("FirstName")}</Grid>
                <Grid item xs={7}>{response?.first_name ? response?.first_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("lastName")}</Grid>
                <Grid item xs={7}>{response?.last_name ? response?.last_name : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Role")}</Grid>
                <Grid item xs={7}>{response?.groups ? response?.groups : '-'}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{t("Status")}</Grid>
                <Grid item xs={7}>{response?.is_active ? "Active" : "inActive"}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t("Edit")} size='small' onClick={() =>
          setIsModalOpen({
            open: true,
            currentComponent: "addUser",
            data: {
              totalData: response,
            },
            isEdit: true
          })
        } />
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={() => [closeFlag(), handleClose()]}
      />
    </>
  )
}

export default ViewUser;
