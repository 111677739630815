import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  alertBlack,
  cameraBlack,
  entry,
  eventBlack,
  exit,
  locationBlack,
} from "../../../Helper/Constant";
const counterData = [
  {
    img: entry,
    imgbg: "#FF6C4B",
    name: "Total Alert",
    count: "145 +",
    per: "+ 10.6",
  },
  {
    img: exit,
    imgbg: "#FFE870",
    name: "Total Event",
    count: "145 +",
    per: "+ 10.6",
  },
  {
    img: cameraBlack,
    imgbg: "#8FB9FF",
    name: "Total Camera",
    count: "145 +",
    per: "+ 10.6",
  },
  {
    img: locationBlack,
    imgbg: "#41FF8D",
    name: "Total Location",
    count: "145 +",
    per: "+ 10.6",
  },
];
const CounterCard = () => {
  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                background: "#151515",
                p: { xs: "20px 10px", sm: "30px 15px" },
                position: "relative",
              }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
              >
                <Box
                  sx={{
                    background: "#FF6C4B",
                    width: { xs: "40px", sm: "50px" },
                    height: { xs: "40px", sm: "50px" },
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={entry} alt="" width={28} />
                </Box>
                <Stack>
                  <Typography
                    fontSize={{ xs: "20px", sm: "25px" }}
                    fontWeight={"600"}
                    lineHeight={{ xs: "27px", sm: "32px" }}
                  >
                    104
                  </Typography>
                  <Typography
                    fontSize={{ xs: "12px", sm: "15px" }}
                    lineHeight={{ xs: "18px", sm: "22px" }}
                  >
                    {"Total Entry"}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                background: "#151515",
                p: { xs: "20px 10px", sm: "30px 15px" },
                position: "relative",
              }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
              >
                <Box
                  sx={{
                    background: "#FFE870",
                    width: { xs: "40px", sm: "50px" },
                    height: { xs: "40px", sm: "50px" },
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={exit} alt="" width={28} />
                </Box>
                <Stack>
                  <Typography
                    fontSize={{ xs: "20px", sm: "25px" }}
                    fontWeight={"600"}
                    lineHeight={{ xs: "27px", sm: "32px" }}
                  >
                    50
                  </Typography>
                  <Typography
                    fontSize={{ xs: "12px", sm: "15px" }}
                    lineHeight={{ xs: "18px", sm: "22px" }}
                  >
                    {"Total Exit"}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                background: "#151515",
                p: { xs: "20px 10px", sm: "30px 15px" },
                position: "relative",
              }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
              >
                <Box
                  sx={{
                    background: "#FF6C4B",
                    width: { xs: "40px", sm: "50px" },
                    height: { xs: "40px", sm: "50px" },
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={cameraBlack} alt="" width={28} />
                </Box>
                <Stack>
                  <Typography
                    fontSize={{ xs: "20px", sm: "25px" }}
                    fontWeight={"600"}
                    lineHeight={{ xs: "27px", sm: "32px" }}
                  >
                    3
                  </Typography>
                  <Typography
                    fontSize={{ xs: "12px", sm: "15px" }}
                    lineHeight={{ xs: "18px", sm: "22px" }}
                  >
                    {"Total Camera"}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                background: "#151515",
                p: { xs: "20px 10px", sm: "30px 15px" },
                position: "relative",
              }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
              >
                <Box
                  sx={{
                    background: "#FF6C4B",
                    width: { xs: "40px", sm: "50px" },
                    height: { xs: "40px", sm: "50px" },
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={locationBlack} alt="" width={28} />
                </Box>
                <Stack>
                  <Typography
                    fontSize={{ xs: "20px", sm: "25px" }}
                    fontWeight={"600"}
                    lineHeight={{ xs: "27px", sm: "32px" }}
                  >
                    1
                  </Typography>
                  <Typography
                    fontSize={{ xs: "12px", sm: "15px" }}
                    lineHeight={{ xs: "18px", sm: "22px" }}
                  >
                    {"Total Location"}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CounterCard;
