import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Collapse,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { Button, Input, Modal } from "../../Component/Commons";
import { useNavigate } from "react-router-dom";
import { GrFormAdd, VscSettings, RiSearchLine } from "../../Helper/Icons";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { roiListHandler } from "../../service/Roi.service";
import { useFormik } from "formik";
import { addRoiIv, roiList } from "../../Helper/intialValues";
import { addRoiValidation } from "../../Helper/validation";
import CommonSelect from "../../Component/Commons/Select";
import { cameraListHandler } from "../../service/Camera.service";
import { getEventList } from "../../service/Event.service";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Roi = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [roiData, setRoiData] = useState([]);
  const [search, setSearch] = useState("");
  const [cameraData, setCameraData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [eventData, setEventData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const editHandler = (id) => {
    navigate(`/edit-roi`, { state: { id: id } });
  }

  const formik = useFormik({
    initialValues: roiList,
    onSubmit: async (values) => {
      getRoiData();
    },
  });

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const title = "ROI -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  const getRoiData = async () => {
    let selectQry = `search=${search}&camera_id=${formik.values.camera_id}&camera_event_id=${formik.values.camera_event_id}`;
    const response = await roiListHandler(selectQry);
    if (response && response.data) {
      setRoiData(response.data);
      setDataCount(response?.count);
    } else {
      setRoiData([]);
      setDataCount(0);
    }
  }

  const deleteHandler = (id) => {
    console.log("id", id);
    setIsModalOpen({
      open: true,
      currentComponent: "delete",
      isEdit: false,
      data: {
        head: `${t("RemoveRoi")}`,
        message: `${t("Do_you_want_to_Remove_this_Roi?")}`,
        id: id,
        name: "ROI",
      },
    });
  };

  useEffect(() => {
    getRoiData();
  }, [search, page]);

  const handleSelectChange = event => {
    formik.setFieldValue('camera_id', event.target.value);
  };

  const getCameraData = async () => {
    const response = await cameraListHandler();

    if (response && response.data) {
      const data = response.data.map(item => ({
        id: item.id,
        name: item.camera_name,
      }));
      setCameraData(data);
    } else {
      setCameraData([]);
    }
  };

  const getEventListData = async () => {
    const response = await getEventList();
    if (response && response.success) {
      let data = response.data.map((item) => {
        return {
          name: item.camera_event,
          id: item.id
        }
      }
      );
      setEventData(data);
    } else {
      setEventData([]);
    }
  };


  useEffect(() => {
    getCameraData();
    getEventListData();
  }, []);

  const handleChangePage = (value, newPage) => {
    setPage(newPage);
  };

  const clearFilter = () => {
    formik.resetForm();
    formik.handleSubmit();
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("Search_for_egion_of_interest_pooling_(ROI)")}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Box>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          <Button
            buttonName={t("AddROI")}
            startIcon={<GrFormAdd />}
            onClick={() =>
              navigate("/add-roi")
            }
          />
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("CameraID")}:
            </Typography>
            <CommonSelect
              // labelinput={t('SelectCamera')}
              value={formik.values.camera_id}
              onChange={handleSelectChange}
              name='camera_id'
              selectHead={t('SelectCamera')}
              selectList={cameraData}
              formik={formik}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Event")}:
            </Typography>
            <CommonSelect
              value={formik.values.camera_event_id || ""}
              onChange={(event) => {
                formik.setFieldValue('camera_event_id', event.target.value);
              }}
              style={{ width: "100%" }}
              name='camera_event_id'
              selectHead={t('SelectEvent')}
              selectList={eventData}
              formik={formik}
            />
          </Grid>

          <Box sx={{ mx: 1 }}>
            {/* <VscSettings style={{ fontSize: "25px", cursor: "pointer" }} onClick={() => handleSubmitFilter()} /> */}
            <Button buttonName={t("Filter")} onClick={() => formik.handleSubmit()} />
          </Box>
          <Box sx={{ mx: 1 }}>
            <Button buttonName={t("Clear")} onClick={() => clearFilter()} />
          </Box>
        </Stack>
      </Collapse>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Camera")}</StyledTableCell>
              <StyledTableCell>{t("RoiName")}</StyledTableCell>
              <StyledTableCell>{t("CameraEvent")}</StyledTableCell>
              <StyledTableCell>{t("ColorCode")}</StyledTableCell>
              <StyledTableCell>{t("FrameNumber")}</StyledTableCell>
              <StyledTableCell>{t("Action")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {roiData && roiData.length > 0 ? (
              roiData?.map((data, i) => {
                return (
                  <StyledTableRow
                    key={i}
                    // onClick={() =>
                    //   setIsModalOpen({
                    //     open: true,
                    //     currentComponent: "viewRoi",
                    //   })
                    // }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {data?.camera_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data?.roi_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data?.camera_events_data?.map((item) => item.camera_event).join(", ")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Box
                        sx={{
                          color: `${data?.color || "#fff"}`,
                          border: "1px solid #353535",
                          padding: "6px 10px 5px",
                          borderRadius: "5px",
                          width: "fit-content",
                        }}
                      >
                        {data?.color || "-"}
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data?.frame_number}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <EditIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          editHandler(data.id);
                        }}
                      />
                      <DeleteIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHandler(data.id);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("Norecordstodisplay")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {`${t("Showing")} ${roiData ? roiData.length : 0}
          ${t("of")} ${dataCount ?? 0} ${t("Results")}`}
        </div>
        <Pagination
          count={Math.ceil(dataCount / pageLimit)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={() => {
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
          getRoiData();

        }}
      />
    </HelmetProvider>
  );
};

export default Roi;
