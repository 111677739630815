import { Box, Grid, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import CommonInput from '../../Component/Commons/Input'
import { Button, Input } from "../../Component/Commons";
import { FaRegUser } from 'react-icons/fa'
import { MdOutlineModeEditOutline } from "react-icons/md";
import CommonButton from '../../Component/Commons/Button';
import CommonSelect from '../../Component/Commons/Select';
import { getProfileDetails, updateProfile, updatePassword } from '../../service/Settings.service'
import { useFormik } from "formik";
import { profileDataInv, updatePassIv } from "../../Helper/intialValues";
import { updateUserProfileValidation, updateAdminPass } from "../../Helper/validation";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const handleClick = () => setEdit((show) => !show);
  const [profileImage, setProfileImage] = useState("");
  const [file, setFile] = useState("");
  const [errorDisplayFlag, setErrorDisplayFlag] = useState(false);
  const [errorDisplayPassFlag, setErrorDisplayPassFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // errorNotification(
        //   "File size exceeds 2MB. Please select a smaller file."
        // );
      } else {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.indexOf(file.type) === -1) {
          // errorNotification(
          //   "Invalid file type. Please select a valid image file."
          // );
        } else {
          // formik.setValues({
          //   ...formik.values,
          //   profile_image: file,
          // });
          setFile(URL.createObjectURL(e.target.files[0]));
          setProfileImage(e.target.files[0]);
        }
      }
    }
    setFile(URL.createObjectURL(e.target.files[0]));
    setProfileImage(e.target.files[0]);
  }

  const handleGetProfileDetails = async () => {
    const { count, data, message, success, totalPage } = await getProfileDetails();
    if (success) {
      formik.setValues({
        ...formik.values,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_no: data.phone_no
      })
      setFile(data.profile_image)
    } else {
      errorNotification(`${t("Somethinggoeswrong")}`);
    }
  };

  const submitHandler = async () => {
    try {
      console.log('Is coming herererere');
      setLoading(true);
      const formData = new FormData();
      formData.append("first_name", formik.values.first_name);
      formData.append("last_name", formik.values.last_name);
      formData.append("phone_no", formik.values.phone_no);
      formData.append("email", formik.values.email);
      formData.append("profile_image", profileImage);

      const { data, message, success } = await updateProfile(formData);
      if (success) {
        successNotification(message);
        handleGetProfileDetails();
        setLoading(false);
        setEdit((show) => !show)
      } else {
        errorNotification(message);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };




  const submitHandlerPass = async () => {
    try {
      console.log('formikChangePass', formikChangePass);
      if (formikChangePass.values.new_password == formikChangePass.values.confirm_pass) {
        const payload = {
          old_password: formikChangePass.values.old_password,
          new_password: formikChangePass.values.new_password
        }
        const { data, message, success } = await updatePassword(payload);
        if (success) {
          successNotification(message);
          formikChangePass.resetForm()
        } else {
          errorNotification(message);
        }
      } else {
        errorNotification(`${t("New_password_and_confirm_password_should_be_same.")}`);
      }

    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const formikChangePass = useFormik({
    initialValues: updatePassIv,
    validationSchema: updateAdminPass,
    onSubmit: submitHandlerPass,
  });

  const formik = useFormik({
    initialValues: profileDataInv,
    validationSchema: updateUserProfileValidation,
    onSubmit: submitHandler,
  });






  useEffect(() => {
    handleGetProfileDetails()
  }, [])

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography mb={1}>{t("ProfileSettings")}</Typography>
          <Box sx={{ background: '#151515', p: { xs: 2, sm: 5 } }}>
            <Box sx={{ border: '1px solid #454545', p: 3, mb: 3, maxWidth: '180px', marginX: 'auto' }}>
              {!edit ? (
                <Box
                  sx={{
                    width: "110px",
                    height: "110px",
                    background: "#f1f1f1",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 'auto'
                  }}
                >
                  {file ? (
                    <img
                      src={file}
                      width={110}
                      height={110}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <FaRegUser
                      style={{ fontSize: "50px", color: "#00000091" }}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "110px",
                    height: "110px",
                    background: "#f1f1f1",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    margin: 'auto'
                  }}
                >
                  {file ? (
                    <img
                      src={file}
                      width={110}
                      height={110}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <FaRegUser
                      style={{ fontSize: "50px", color: "#00000091" }}
                    />
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                      bottom: "14px",
                      right: "0px",
                      borderRadius: "50%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "22px",
                        height: "22px",
                      }}
                    >
                      <MdOutlineModeEditOutline
                        style={{
                          position: "relative",
                          background: "#05EE3B",
                          color: "#000",
                          fontSize: "22px",
                          padding: "4px",
                          borderRadius: "50%",
                        }}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                        style={{
                          position: "absolute",
                          opacity: "0",
                          width: "100%",
                          height: "100%",
                          right: "0",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Stack gap={'10px'} sx={{ border: '1px solid #454545', p: { xs: 2, sm: 3 }, mb: { xs: 2, sm: 3 }, maxWidth: '400px', marginX: 'auto' }}>
              <Box>
                <Input
                  placeholder={t("FirstName")}
                  name="first_name"
                  formik={formik}
                  errorDisplay={errorDisplayFlag}
                  disabled={!edit}
                />
              </Box>
              <Box>
                <Input
                  placeholder={t("LastName")}
                  name="last_name"
                  formik={formik}
                  errorDisplay={errorDisplayFlag}
                  disabled={!edit}
                />
              </Box>
              <Box>
                <Input
                  placeholder={t("Email")}
                  name="email"
                  formik={formik}
                  disabled={!edit}
                  errorDisplay={errorDisplayFlag}
                />
              </Box>
              <Box>
                <Input
                  placeholder={t("PhoneNumber")}
                  name="phone_no"
                  formik={formik}
                  disabled={!edit}
                  errorDisplay={errorDisplayFlag}
                />
              </Box>
            </Stack>
            <Stack sx={{ maxWidth: '400px', marginX: 'auto' }}>
              {edit ? (
                <Stack gap={2} flexDirection={"row"} justifyContent={"flex-end"}>
                  <Button
                    buttonName={t("Save")}
                    onClick={() => [formik.handleSubmit(), setErrorDisplayFlag(true)]}
                    loading={loading}
                  />
                  <Button buttonName={t("Cancel")} onClick={handleClick} style={{ color: '#fff', borderColor: '#2c2c2c' }} size='small' variant='outlined' />
                </Stack>
              ) : (
                <Stack gap={2} flexDirection={"row"} justifyContent={"flex-end"}>
                  <Button buttonName={t("Edit")} onClick={handleClick} />
                </Stack>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography mb={1}>{t("UpdatePassword")}</Typography>
          <Box sx={{ background: '#151515', p: { xs: 2, sm: 3 } }}>
            <Stack gap={2}>
              <Input
                placeholder={t("CurrentPassword")}
                passwordInput
                name="old_password"
                formik={formikChangePass}
                errorDisplay={errorDisplayPassFlag}
                autoComplete="new-password"
              />
              <Input
                placeholder={t("NewPassword")}
                passwordInput
                name="new_password"
                formik={formikChangePass}
                errorDisplay={errorDisplayPassFlag}
                autoComplete="new-password"

              />
              <Input
                placeholder={t("ConfirmPassword")}
                passwordInput
                name="confirm_pass"
                formik={formikChangePass}
                errorDisplay={errorDisplayPassFlag}
              />
            </Stack>
            <Stack mt={2}>
              <Stack gap={2} flexDirection={"row"} justifyContent={"flex-end"}>
                <CommonButton buttonName={t("Update")} onClick={() => [formikChangePass.handleSubmit(), setErrorDisplayPassFlag(true)]} />
                {/* <CommonButton buttonName="Cancel" color="secondary" style={{ color: '#fff', borderColor: '#2c2c2c' }} size='small' variant='outlined' /> */}
              </Stack>
            </Stack>
          </Box>
          <Typography my={1}>{t("NotificationPreference")}</Typography>
          <Box sx={{ background: '#151515', p: { xs: 2, sm: 3 } }}>
            <Stack gap={2}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ border: '1px solid #454545', p: '10px' }}>
                <Typography>{t("EmailNotification")}</Typography>
                <Switch defaultChecked size="small" />
              </Stack>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ border: '1px solid #454545', p: '10px' }}>
                <Typography>{t("SMSNotification")}</Typography>
                <Switch defaultChecked size="small" />
              </Stack>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ border: '1px solid #454545', p: '10px' }}>
                <Typography>{t("AppNotification")}</Typography>
                <Switch defaultChecked size="small" />
              </Stack>
            </Stack>
          </Box>
          {/* need to keep this comment */}
          {/* <Typography my={1}>Language Preferences</Typography> */}
          {/* <Box sx={{ background: '#151515', p: { xs: 2, sm: 3 } }}> */}
          {/* <CommonSelect selectHead='Select Language' selectList={lanList} /> */}
          {/* </Box> */}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Settings