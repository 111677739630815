import React, { useState } from "react";
import Chart from "react-apexcharts";

const HeatmapChart = () => {
  const data = [
    {
      name: "Entrance",
      data: [20, 30, 40, 50, 60, 70, 80, 90, 40, 50, 60, 25, 40],
    },
    {
      name: "Checkout",
      data: [80, 70, 60, 50, 40, 30, 20, 10, 40, 50, 60, 25, 40],
    },
    {
      name: "Men's Clothing",
      data: [10, 20, 30, 40, 50, 60, 70, 80, 40, 50, 60, 25, 40],
    },
    {
      name: "Women's Clothing",
      data: [80, 70, 60, 50, 40, 30, 20, 10, 40, 50, 60, 25, 40],
    },
    {
      name: "Kids' Section",
      data: [15, 25, 35, 45, 55, 65, 75, 85, 40, 50, 60, 25, 40],
    },
    {
      name: "Electronics",
      data: [85, 75, 65, 55, 45, 35, 25, 15, 40, 50, 60, 25, 40],
    },
  ];

  const colors = [
    "#7393B3",
    "#B2BEB5",
    "#36454F",
    "#6082B6",
    "#A9A9A9",
    "#2F4F4F",
  ];

  const [options, setOptions] = useState({
    
    chart: {
      height: 450,
      type: "heatmap",
    },
    tooltip: {
      enabled: true,
    },
    colors: colors,
    xaxis: {
      type: "category",
      categories: [
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
      ],
      labels: {
        show: true, 
        style: {
          colors: "#FFF", // Set label color to white
        },
      },
      axisBorder: {
        show: false, // Disable x-axis border
      },
      axisTicks: {
        show: false, // Disable x-axis ticks
      },
    },
    yaxis: {
        labels: {
            show: false, 
          },
          axisBorder: {
            show: false, // Disable x-axis border
          },
          axisTicks: {
            show: false, // Disable x-axis ticks
          },
    },
    grid: {
      padding: {
        right: 2,
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val;
      },
      style: {
        colors: ['#FFF']
      }
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.2,
        },
      },
    },
    legend: {
      enabled: true,
      labels: {
        colors: '#FFF',
      },
    },
  });

  const [series, setSeries] = useState(data);

  return (
    <div 
      className="heatmap-chart" 
      style={{
        color: "black",
        width: "100%",
        height:"100%",
        overflow: "hidden",
      }}
    >
      <Chart options={options} series={series} type="heatmap" height={450} />
    </div>
  );
};

export default HeatmapChart;
