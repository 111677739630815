import { get, post, put } from "../web.request";

export const getProfileDetails = () => {
    return get(`${process.env.REACT_APP_API_URL}/user_profile_view`);
};

export const updateProfile = (payload) => {
    return put(`${process.env.REACT_APP_API_URL}/user_profile_view`, payload);
};

export const updatePassword = (payload) => {
    return put(`${process.env.REACT_APP_API_URL}/change_password`, payload);
};

