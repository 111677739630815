import {
    Box,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { analyticsDataHandler, reportChartHandler, topFourDataHandler } from "../../../service/Dashboard.service";
import CounterCard from "./CounterCard";
import TopEmploye from "./TopEmploye";
import CommonChart from "../../../Component/Commons/CommonChart";
import Chart from "react-google-charts";

const Dashboard = () => {
    const [reportChartData, setReportChartData] = useState([]);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [topFourEventData, setTopFourEventData] = useState([]);

    useEffect(() => {
        reportApiHandler();
        analyticsDataApiHandler();
        topFourDataApiHandler();
    }, []);

    const topFourDataApiHandler = async () => {
        const { count, data, message, success, totalPage } = await topFourDataHandler();
        console.log("-------*****", data);
        if (success) {
            setTopFourEventData(data);
        } else {
            setTopFourEventData([]);
        }
    };

    const reportApiHandler = async () => {
        const { count, data, message, success, totalPage } =
            await reportChartHandler();
        console.log("DAtatstatts", data);
        if (success) {
            console.log("data---------------88", data);
            const chartData = data.date_wise_counts.map(item => [
                item.date,
                item.total,
            ])
            chartData.unshift(["Category", "Peak Chat Times"]);
            console.log("chartDatachartDatachartDatachartData", chartData);
            setReportChartData(chartData);
        } else {
            setReportChartData(data.date_wise_counts);
        }
    };

    const analyticsDataApiHandler = async () => {
        const { count, data, message, success, totalPage } = await analyticsDataHandler();
        console.log("data-analyticsDataHandler", data);
        if (success) {
            setAnalyticsData(data)
        } else {
            setAnalyticsData([]);
        }
    }

    const data = [
        ['Category', 'Employee Attendance'],
        ['Jan', 20],
        ['Feb', 30],
        ['Mar', 40],
        ['Apr', 50],
        ['May', 40],
        ['June', 30],
        ['July', 20],
        ['Aug', 30],
        ['Sept', 50],
        ['Oct', 70],
        ['Nov', 55],
        ['Dec', 40],
    ];

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack gap={2}>
                        <CounterCard />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
                        <Typography mb={1}>Attendance Trends</Typography>
                        <CommonChart
                            chartType="LineChart"
                            data={reportChartData ? reportChartData : []}
                        />
                    </Box>

                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ background: "#151515", height: "100%", p: 2 }}>
                        <Typography mb={1}>Attendance By Employee</Typography>
                        <CommonChart
                            chartType="ColumnChart"
                            data={data}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography mb={1}>Top Employee Attendance</Typography>
                    <TopEmploye />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
