import React from "react";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import AlertCard from "./AlertCard";
import { alertView } from "../../Helper/Constant";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AlertDetails = ({ handleClose, modalOpen, closeFlag }) => {
  const resData = modalOpen?.data;
  const { t } = useTranslation();

  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 1 }} id="Common_modal"></DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box sx={{ background: "#151515", p: 2, cursor: "pointer" }}>
          <Stack gap={1}>
            <img
              src={resData?.frame ? resData?.frame : alertView}
              alt="alert_image"
            />
            <Typography
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
              mt={1}
            >
              <Stack flexDirection={"row"} alignItems={"center"} gap={"5px"}>
                {t("Event")} : {resData?.camera_events_name}
                {/* <GoDotFill /> No */}
              </Stack>
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
            >
              {t("Location")} : {resData?.location_name ? resData.location_name : "-"}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
            >
              {t("Area")} : {resData?.location_name ? resData.location_name : "-"}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
            >
              {t("Time")} :{" "}
              {resData?.created_at
                ? moment(resData.created_at).format("HH:mm:ss")
                : "-"}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
            >
              {t("Date")} :{" "}
              {resData?.created_at
                ? moment(resData.created_at).format("YYYY-MM-DD")
                : "-"}
            </Typography>
            <Typography
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"16px"}
            >
              {t("Cameras")} : {resData?.camera_name ? resData.camera_name : "-"}
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
    </div>
  );
};

export default AlertDetails;
