import { Box, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../../Helper/Icons";
import { CameraView } from "../../../Helper/Constant";
import { useTranslation } from "react-i18next";
import { Mode } from "@mui/icons-material";
import { reportView } from "../../../Helper/Constant";
import worker from "../../../assets/image/worker.jpg";

function UserModel({ handleClose, modalOpen, closeFlag }) {

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
      {modalOpen.isEdit ? 'Edit' : 'Add'} User
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon onClick={() => {
          handleClose();
          console.log("handleClose", handleClose);
        }} />
      </IconButton >
      <DialogContent dividers>
        <Stack alignItems={"center"} margin={"15px 0 30px"} gap={1}>
          <img src={worker} style={{ width: "350px", objectFit: 'contain', height: 'auto' }} />
          <Typography>John Doe</Typography>
        </Stack>
        <Box width={{ sm: '400px' }} sx={{ border: '1px solid #333', padding: '30px', color: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{"Category"}</Grid>
                <Grid item xs={7}>Child</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{"Room"}</Grid>
                <Grid item xs={7}>Room 1</Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Grid container >
                <Grid item xs={5}>{"Master"}</Grid>
                    <img src={reportView} style={{ width: "150px", objectFit: 'contain', height: 'auto' }} />

              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{"Link"}</Grid>
              </Grid>
            </Grid> */}
         
            
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
}

export default UserModel;