import React, { useState } from "react";
import { getGroups } from "../../service/role.service";
import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
} from "@mui/material";
import AddRole from "./AddRole";
import {
  errorNotification,
  successNotification,
} from "../../Helper/Notification";
import CommonButton from "../../Component/Commons/Button";
import { Helmet, HelmetProvider } from "react-helmet-async";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1987540a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Roles = () => {
  const [groupData, setGroupData] = React.useState([]);
  const [page, setPage] = useState(1);
  const [editId, setEditId] = React.useState({});
  const [refresh, setRefresh] = useState(false);

  const getGroupData = async () => {
    const res = await getGroups();
    if (res.success) {
      setGroupData(res.data);
    } else {
      setGroupData([]);
    }
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpen = () => {
    setOpenAdd(true);
  };
  const handleClose = () => {
    setOpenAdd(false);
  };

  useEffect(() => {
    getGroupData();
  }, [refresh]);

  const handleChangePage = (event, value) => {
    setPage(value);
    getGroupData(value);
  };

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };
  const title = "Roles -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Grid container justifyContent="flex-end" sx={{ marginY: 1 }}>
        <Grid item>
          <CommonButton
            buttonName="Add Role"
            onClick={() => {
              setEditId("");
              handleOpen();
            }}
          />
        </Grid>

        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: "700", color: "#ffff" }}>
                  Role Name
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: "700", color: "#ffff" }}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupData?.map((row, index) => {
                var { group_id, group_name } = row;
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      {group_name.charAt(0).toUpperCase() + group_name.slice(1)}
                    </TableCell>
                    <TableCell
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditId(row);
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        flexWrap={"nowrap"}
                      >
                        <Grid item>
                          <EditIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpen();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              {groupData === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : groupData.length === 0 ? (
                <TableRowsLoader rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <AddRole
        openAdd={openAdd}
        handleClose={handleClose}
        editId={editId}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </HelmetProvider>
  );
};

export default Roles;
